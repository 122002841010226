import { formatStandardDate, formatStandardDateLocaleString } from '../../utils';
import { Address, Drug, Patient, Prescriber, Pharmacy, Insurance } from '../../api';
import { SidebarRow, SidebarRowData, SidebarRowLabel, SidebarSection } from './Sidebar';
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { standardTransitionStyles } from 'design-system/src/sharedStyles';
import PharmaRelationshipNotice from '../PharmaRelationshipNotice/PharmaRelationshipNotice';

const StyledLink = styled(NavLink)`
  color: var(--black);
  ${standardTransitionStyles}

  // not using the standard focusVisibleStyles because it looks weird on a wrapping link
  &:focus-visible {
    outline-color: var(--purple);
  }
`;

export const PatientPanel: React.FC<{ patient: Patient }> = ({ patient }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Patient</SidebarRowLabel>
        <SidebarRowData>
          {patient.first_name} {patient.last_name}
        </SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>DOB</SidebarRowLabel>
        <SidebarRowData>{formatStandardDate(patient.date_of_birth)}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Phone</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(patient.phone_number)}</SidebarRowData>
      </SidebarRow>
      <AddressRow address={patient.address} />
    </SidebarSection>
  );
};

export const PrescriberPanel: React.FC<{ prescriber: Prescriber }> = ({ prescriber: prescriber }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Prescriber</SidebarRowLabel>
        <SidebarRowData>{prescriber.name}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>NPI</SidebarRowLabel>
        <SidebarRowData>{prescriber.npi}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Phone</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(prescriber.phone_number)}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Fax</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(prescriber.fax_number)}</SidebarRowData>
      </SidebarRow>
      <AddressRow address={prescriber.address} />
    </SidebarSection>
  );
};

export const DrugPanel: React.FC<{
  drug: Drug;
  linkToRxPage?: boolean;
  showPharmaRelationshipNotice?: boolean;
}> = ({ drug, linkToRxPage = true, showPharmaRelationshipNotice = false }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Drug</SidebarRowLabel>
        <SidebarRowData>
          {linkToRxPage ? <StyledLink to={`/rxs/${drug.id}`}>{drug.name}</StyledLink> : drug.name}
        </SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Date Prescribed</SidebarRowLabel>
        <SidebarRowData>{formatStandardDateLocaleString(drug.prescription_recieved_at)}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>NDC</SidebarRowLabel>
        <SidebarRowData>{drug.ndc}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Quantity</SidebarRowLabel>
        <SidebarRowData>{drug.quantity}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Sig</SidebarRowLabel>
        <SidebarRowData>{drug.sig}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Refills</SidebarRowLabel>
        <SidebarRowData>{drug.refills}</SidebarRowData>
      </SidebarRow>
      {showPharmaRelationshipNotice && <PharmaRelationshipNotice />}
    </SidebarSection>
  );
};

export const PharmacyPanel: React.FC<{ pharmacy?: Pharmacy }> = ({ pharmacy }) => {
  if (!pharmacy) {
    return;
  }

  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Pharmacy</SidebarRowLabel>
        <SidebarRowData>{pharmacy.name}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>NPI</SidebarRowLabel>
        <SidebarRowData>{pharmacy.npi}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Phone</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(pharmacy.phone)}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Fax</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(pharmacy.fax)}</SidebarRowData>
      </SidebarRow>
      <AddressRow address={pharmacy.address} />
    </SidebarSection>
  );
};

export const InsurancePanel: React.FC<{ insurance?: Insurance }> = ({ insurance }) => {
  if (!insurance) {
    return;
  }

  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Insurance</SidebarRowLabel>
        <SidebarRowData>{insurance.name}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Phone</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(insurance.phone)}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Fax</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(insurance.fax)}</SidebarRowData>
      </SidebarRow>
    </SidebarSection>
  );
};

const AddressRow: React.FC<{ address: Address }> = ({ address }) => {
  let sidebarRowData: string | React.ReactNode = '-';

  if (address.line1 || address.line2 || address.city || address.state || address.postal_code) {
    const cityAndState = [address.city, address.state].filter(Boolean).join(', ');
    const addressLine3 = [cityAndState, address.postal_code].filter(Boolean).join(' ');

    sidebarRowData = [address.line1, address.line2, addressLine3].filter(Boolean).map((s, i) => (
      <React.Fragment key={i}>
        {s}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <SidebarRow>
      <SidebarRowLabel>Address</SidebarRowLabel>
      <SidebarRowData>{sidebarRowData}</SidebarRowData>
    </SidebarRow>
  );
};

export const ICDCodePanel: React.FC<{ icdCode: string }> = ({ icdCode }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>ICD</SidebarRowLabel>
        <SidebarRowData>{icdCode}</SidebarRowData>
      </SidebarRow>
    </SidebarSection>
  );
};

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '-';
  }

  // Use a regular expression to capture the different parts of the phone number
  const regex = /^(\d{3})(\d{3})(\d{4})$/;

  // Replace the matched pattern with the desired format
  const formattedNumber = phoneNumber.replace(regex, '($1) $2-$3');

  return formattedNumber;
};
