import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppealReviewContext } from '../components/appealReviews/contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AppealReviewResponse, client } from '../api';
import { appealReviewQueryKey, appealReviewsQueryKey } from '../queries';
import AppealReviewView from '../components/appealReviews/AppealReviewView';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import PageContainer from '../components/PageContainer';

const AppealReviewPage: React.FC = () => {
  const params = useParams();
  const appealReviewId = params.appealReviewId!;

  const queryClient = useQueryClient();
  const appealReviewQuery = useQuery<AppealReviewResponse>({
    queryKey: appealReviewQueryKey(appealReviewId),
    queryFn: async () => {
      const res = await client.get(`appeal-reviews/${appealReviewId}`);
      return res.data;
    },
  });

  // TODO: use appealReviewQuery.isPending instead
  const [updating, setUpdating] = useState(false);

  let body;
  if (appealReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (appealReviewQuery.error) {
    body = <PageLoadError />;
  } else if (appealReviewQuery.data) {
    body = (
      <AppealReviewContext.Provider
        value={{
          appealReviewId,
          appealReviewQuery,
          updating,
          setUpdating,
          refetchAppealReview: async () =>
            queryClient.invalidateQueries({
              queryKey: appealReviewQueryKey(appealReviewId),
              exact: true,
            }),
          onAppealReviewUpdate: async () => {
            queryClient.invalidateQueries({
              queryKey: appealReviewsQueryKey(),
            });
            queryClient.invalidateQueries({
              queryKey: appealReviewQueryKey(appealReviewId),
            });
            setUpdating(false);
          },
        }}
      >
        <AppealReviewView />
      </AppealReviewContext.Provider>
    );
  }

  return <PageContainer noPadding={true}>{body}</PageContainer>;
};

export default AppealReviewPage;
