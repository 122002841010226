import { client, getRxFilePreviewUrl, Task } from '../../api';
import styled from 'styled-components';
import { StyledContainer } from '../tableItemDetailViewComponents/Containers';
import toast from 'react-hot-toast';
import { StandardErrorToast, Toast } from 'design-system/src/components/Toast/Toast';
import { tasksQueryKey } from '../../queries';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import { RadioLabel, RadioOptionWrapper, RadioBlockGroup } from 'design-system/src/components/Radio/Radio';
import { useState } from 'react';

const StyledIframe = styled.iframe`
  margin: 1rem 0;
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-medium);
  min-height: 1000px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

const FormActionContainer = styled(StyledContainer)`
  box-sizing: border-box;
  padding: 16px 16px 0px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
`;

const StickyButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  background: var(--white);
  padding: 16px;
  border-top: 1px solid var(--border-gray);
  // bottom border radius
  border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
  // so that the container stretches the full width of the outer form action container
  margin: 0 -16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ActionContainer = styled(StyledContainer)`
  display: flex;
  padding: 16px 0px;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  padding: 16px;
  flex-wrap: wrap;
`;

type TaskActionsProps = {
  setShowDeclineModal: (show: boolean) => void;
  task: Task;
  setTaskResponseLoading: (loading: boolean) => void;
  // TODO: make this a union type to handle specific props for each task type
  setShowCommentInput?: (show: boolean) => void;
  setShowInitiateModal: (show: boolean) => void;
  setShowSubmitAppealModal: (show: boolean) => void;
  setShowNewFollowupTaskModal: (show: boolean) => void;
  setShowSwitchTherapyModal: (show: boolean) => void;
  rxId: string;
  allowRedraftRequest: boolean;
};

type Step = {
  label: string;
  value: string;
  checked: boolean;
};

const TaskActions = ({
  setShowDeclineModal,
  setShowInitiateModal,
  setShowSubmitAppealModal,
  setShowNewFollowupTaskModal,
  setShowSwitchTherapyModal,
  task,
  setTaskResponseLoading,
  setShowCommentInput,
  rxId,
  allowRedraftRequest,
}: TaskActionsProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleTaskResponse = async (response: string) => {
    try {
      const formData = new FormData();
      formData.append('task_response', response);
      setTaskResponseLoading(true);
      await client.post(`tasks/${task.id}/respond`, formData);
      toast.custom(() => <Toast variant="success">Task response received.</Toast>);
      navigate('/tasks');
      queryClient.invalidateQueries({
        queryKey: tasksQueryKey(),
      });
    } catch (error) {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setTaskResponseLoading(false);
    }
  };

  switch (task.type) {
    case 'Form signature request':
      if (!task.embedded_document_url || !setShowCommentInput) {
        return null;
      }
      return (
        <FormActionContainer>
          <ButtonRow>
            <Button variant="secondary" size="sm" onClick={() => setShowCommentInput(true)}>
              Add comment
            </Button>
            <Button variant="secondary" size="sm" onClick={() => setShowDeclineModal(true)}>
              Decline enrollment
            </Button>
          </ButtonRow>
          <StyledIframe src={task.embedded_document_url} />
        </FormActionContainer>
      );
    case 'PA expiring':
      return (
        <ActionContainer>
          <Button variant="primary" size="sm" onClick={() => handleTaskResponse('will_renew')}>
            I will renew
          </Button>
          <Button variant="secondary" size="sm" onClick={() => handleTaskResponse('already_renewed')}>
            I have already renewed
          </Button>
          <Button variant="secondary" size="sm" onClick={() => setShowDeclineModal(true)}>
            Decline renewal
          </Button>
        </ActionContainer>
      );
    case 'PA denied - Select next step':
      return (
        <PADenialNextStepsActions
          setShowInitiateModal={setShowInitiateModal}
          setShowDeclineModal={setShowDeclineModal}
          setShowNewFollowupTaskModal={setShowNewFollowupTaskModal}
          setShowSwitchTherapyModal={setShowSwitchTherapyModal}
        />
      );
    case 'Appeal drafted - Ready for review':
      if (!task.document_id) {
        return null;
      }
      if (allowRedraftRequest) {
        return (
          <AppealReviewActions
            setShowInitiateModal={setShowInitiateModal}
            setShowDeclineModal={setShowDeclineModal}
            setShowSubmitAppealModal={setShowSubmitAppealModal}
            rxId={rxId}
            documentId={task.document_id}
          />
        );
      } else {
        return (
          <FormActionContainer>
            <StyledIframe src={getRxFilePreviewUrl(rxId, task.document_id)} />
            <StickyButtonContainer>
              <ButtonRow align={Alignment.SpaceBetween}>
                <ButtonRow>
                  <Button variant="secondary" size="sm" onClick={() => setShowDeclineModal(true)}>
                    Cancel prescription
                  </Button>
                  <Button variant="primary" size="sm" onClick={() => handleTaskResponse('approve_and_send')}>
                    Approve appeal letter
                  </Button>
                </ButtonRow>
              </ButtonRow>
            </StickyButtonContainer>
          </FormActionContainer>
        );
      }
    case 'PA denied - Ready to appeal':
      if (!task.document_id) {
        return null;
      }
      return (
        <PADenialNextStepsAndAppealReviewActions
          setShowInitiateModal={setShowInitiateModal}
          setShowDeclineModal={setShowDeclineModal}
          setShowSubmitAppealModal={setShowSubmitAppealModal}
          setShowNewFollowupTaskModal={setShowNewFollowupTaskModal}
          rxId={rxId}
          documentId={task.document_id}
        />
      );
  }
  return null;
};

export default TaskActions;

const updatedStepsOnSelect = (nextSteps: Step[], step: Step) => {
  let newNextSteps = nextSteps.map((s) => ({ ...s, checked: false }));
  newNextSteps.filter((s) => s.value === step.value)[0].checked = true;
  return newNextSteps;
};

const isOptionChecked = (nextSteps: Step[], option: string) => {
  return nextSteps.filter((s) => s.value === option)[0].checked;
};

const AppealReviewActions = ({
  setShowInitiateModal,
  setShowDeclineModal,
  setShowSubmitAppealModal,
  rxId,
  documentId,
}: {
  setShowInitiateModal: (show: boolean) => void;
  setShowDeclineModal: (show: boolean) => void;
  setShowSubmitAppealModal: (show: boolean) => void;
  rxId: string;
  documentId: string;
}) => {
  const [nextSteps, setNextSteps] = useState<Step[]>([
    {
      label: 'Approve appeal letter',
      value: 'approve',
      checked: true,
    },
    {
      label: 'Request changes or provide additional information to include in the appeal',
      value: 'request_changes',
      checked: false,
    },
  ]);

  const showModalForSelection = () => {
    if (isOptionChecked(nextSteps, 'approve')) {
      setShowSubmitAppealModal(true);
    } else if (isOptionChecked(nextSteps, 'request_changes')) {
      setShowInitiateModal(true);
    } else {
      setShowDeclineModal(true);
    }
  };

  return (
    <FormActionContainer>
      <StyledIframe src={getRxFilePreviewUrl(rxId, documentId)} />
      <StickyButtonContainer>
        <RadioBlockGroup>
          {nextSteps.map((step) => (
            <RadioOptionWrapper
              key={step.value}
              id={step.value}
              name="next-steps"
              value={step.value}
              checked={step.checked}
              onChange={() => setNextSteps(updatedStepsOnSelect(nextSteps, step))}
            >
              <RadioLabel>{step.label}</RadioLabel>
            </RadioOptionWrapper>
          ))}
        </RadioBlockGroup>
        <ButtonRow align={Alignment.Right}>
          <Button variant="primary" size="sm" onClick={() => showModalForSelection()}>
            Continue
          </Button>
        </ButtonRow>
      </StickyButtonContainer>
    </FormActionContainer>
  );
};

const PADenialNextStepsAndAppealReviewActions = ({
  setShowInitiateModal,
  setShowDeclineModal,
  setShowSubmitAppealModal,
  setShowNewFollowupTaskModal,
  rxId,
  documentId,
}: {
  setShowInitiateModal: (show: boolean) => void;
  setShowDeclineModal: (show: boolean) => void;
  setShowSubmitAppealModal: (show: boolean) => void;
  setShowNewFollowupTaskModal: (show: boolean) => void;
  rxId: string;
  documentId: string;
}) => {
  const [nextSteps, setNextSteps] = useState<Step[]>([
    {
      label: 'Approve appeal letter and enroll patient in Bridge or PAP, if available',
      value: 'approve',
      checked: true,
    },
    {
      label: 'Request appeal letter edits and enroll patient in Bridge or PAP, if available',
      value: 'request_changes',
      checked: false,
    },
  ]);

  const showModalForSelection = () => {
    if (isOptionChecked(nextSteps, 'approve')) {
      setShowSubmitAppealModal(true);
    } else if (isOptionChecked(nextSteps, 'request_changes')) {
      setShowInitiateModal(true);
    } else {
      setShowDeclineModal(true);
    }
  };

  return (
    <FormActionContainer>
      <StyledIframe src={getRxFilePreviewUrl(rxId, documentId)} />
      <StickyButtonContainer>
        <RadioBlockGroup>
          {nextSteps.map((step) => (
            <RadioOptionWrapper
              id={step.value}
              name="next-steps"
              value={step.value}
              checked={step.checked}
              onChange={() => setNextSteps(updatedStepsOnSelect(nextSteps, step))}
            >
              <RadioLabel>{step.label}</RadioLabel>
            </RadioOptionWrapper>
          ))}
        </RadioBlockGroup>
        <ButtonRow align={Alignment.SpaceBetween}>
          <ButtonRow>
            <Button variant="secondary" size="sm" onClick={() => setShowDeclineModal(true)}>
              Cancel prescription
            </Button>
            <OtherRequestButton setShowNewFollowupTaskModal={setShowNewFollowupTaskModal} />
          </ButtonRow>
          <Button variant="primary" size="sm" onClick={() => showModalForSelection()}>
            Continue
          </Button>
        </ButtonRow>
      </StickyButtonContainer>
    </FormActionContainer>
  );
};

const PADenialNextStepsActions = ({
  setShowInitiateModal,
  setShowDeclineModal,
  setShowNewFollowupTaskModal,
  setShowSwitchTherapyModal,
}: {
  setShowInitiateModal: (show: boolean) => void;
  setShowDeclineModal: (show: boolean) => void;
  setShowNewFollowupTaskModal: (show: boolean) => void;
  setShowSwitchTherapyModal: (show: boolean) => void;
}) => {
  const [nextSteps, setNextSteps] = useState<Step[]>([
    {
      label: 'Switch therapy and send a new prescription to Tandem',
      value: 'switch_therapy',
      checked: true,
    },
    {
      label: 'Initiate appeal and enroll patient in Bridge or PAP, if available',
      value: 'initiate',
      checked: false,
    },
    {
      label: 'Cancel this prescription and any connected doses',
      value: 'cancel',
      checked: false,
    },
  ]);
  const showModalForSelection = () => {
    if (isOptionChecked(nextSteps, 'initiate')) {
      setShowInitiateModal(true);
    } else if (isOptionChecked(nextSteps, 'switch_therapy')) {
      setShowSwitchTherapyModal(true);
    } else {
      setShowDeclineModal(true);
    }
  };
  return (
    <ActionContainer>
      <RadioBlockGroup>
        {nextSteps.map((step) => (
          <RadioOptionWrapper
            id={step.value}
            name="next-steps"
            value={step.value}
            checked={step.checked}
            onChange={() => setNextSteps(updatedStepsOnSelect(nextSteps, step))}
          >
            <RadioLabel>{step.label}</RadioLabel>
          </RadioOptionWrapper>
        ))}
      </RadioBlockGroup>
      <ButtonRow align={Alignment.SpaceBetween} fullWidth>
        <OtherRequestButton setShowNewFollowupTaskModal={setShowNewFollowupTaskModal} />
        <Button variant="primary" size="sm" onClick={() => showModalForSelection()}>
          Continue
        </Button>
      </ButtonRow>
    </ActionContainer>
  );
};

const OtherRequestButton = ({
  setShowNewFollowupTaskModal,
}: {
  setShowNewFollowupTaskModal: (show: boolean) => void;
}) => {
  return (
    <Button variant="link" size="sm" onClick={() => setShowNewFollowupTaskModal(true)}>
      Something else?
    </Button>
  );
};
