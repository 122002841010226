import { FileUploader, UploadedFile, UploadedFileRow } from './Files';
import { Form, FormOverlay, ResponseInput } from '../tableItemDetailViewComponents/FormComponents';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import styled from 'styled-components';
import { FileType, NewCommentFile } from '../../api';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';

const FormContainer = styled.div<{ $inActionBar?: boolean }>`
  ${({ $inActionBar }) => ($inActionBar ? `margin-top: 4px;` : `padding-bottom: 32px;`)}
  ${({ $inActionBar }) => $inActionBar && `width: 100%;`}
`;

type CommentAndFileSectionProps = {
  onSubmit?: () => void;
  isSubmitting?: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  placeholder?: string;
  submitButtonText?: string;
  hideSubmitButton?: boolean;
  files: NewCommentFile[];
  onFilesChange: (files: NewCommentFile[]) => void;
  errors?: string[];
  inActionBar?: boolean;
  inputValue: string;
  setInputValue: (value: string) => void;
  autoFocus?: boolean;
  fileTypesOptions?: FileType[];
};

// NOTE: this logic is duplicated in NewRequestForm.tsx - please keep in sync
const CommentAndFileSection = ({
  onSubmit,
  isSubmitting = false,
  inputRef,
  placeholder = 'Add a response...',
  submitButtonText = 'Submit',
  hideSubmitButton = false,
  files,
  onFilesChange,
  errors,
  inActionBar = false,
  inputValue,
  setInputValue,
  autoFocus = false,
  fileTypesOptions,
}: CommentAndFileSectionProps) => {
  const [duplicateFileNameUploaded, setDuplicateFileNameUploaded] = useState<string | null>(null);

  const onFilesUpload = (filesToUpload: NewCommentFile[]) => {
    setDuplicateFileNameUploaded(null);
    let foundDuplicateFileName = false;

    // Error out if the user happened to choose a file with the same name (path) as one they've
    // already added. Normally it wouldn't matter, but we're using the file names as keys to track
    // the document types when sending them to the server for saving. If they upload another file
    // with the same name, they will both be uploaded with the same document type, which will
    // cause problems downstream.
    filesToUpload.map((fileToUpload) => {
      const fileNameAlreadyUploaded = files.find((uploadedFile: NewCommentFile) => {
        return fileToUpload.file.name === uploadedFile.file.name;
      });
      if (fileNameAlreadyUploaded) {
        setDuplicateFileNameUploaded(fileToUpload.file.name);
        // We're using a local variable because the state won't update fast enough for us to use
        // that value
        foundDuplicateFileName = true;
        return;
      }
    });

    if (!foundDuplicateFileName) {
      onFilesChange([...files, ...filesToUpload]);
    }
  };

  const onFileDelete = (filename: string, document_type: FileType) => {
    const filesCopy = [...files];

    const fileIndex = filesCopy.findIndex((fileInfo) => {
      return fileInfo.file.name === filename && fileInfo.document_type === document_type;
    });

    if (fileIndex === -1) {
      toast.custom(() => <StandardErrorToast />);
      console.error("Couldn't delete file because we couldn't find it: ", filename);
    } else {
      filesCopy.splice(fileIndex, 1);
      onFilesChange(filesCopy);
    }
  };

  // auto-focus on the input field when it becomes visible
  // used in places where the input field is hidden to begin with and then becomes visible
  // on user action (e.g. clicking an "Add a response" button)
  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <FormContainer $inActionBar={inActionBar}>
      <Form>
        {isSubmitting && (
          <FormOverlay>
            <LoadingSpinner />
          </FormOverlay>
        )}
        <ResponseInput
          ref={inputRef}
          placeholder={placeholder}
          value={inputValue}
          // this is necessary for enabling the submit button disabled state to react to the input value
          onChange={(e) => setInputValue(e.target.value)}
        />
        {files.length > 0 && (
          <UploadedFileRow>
            {files.map((fileInfo) => (
              <UploadedFile
                key={`${fileInfo.file.name}`}
                onFileDelete={onFileDelete}
                filename={fileInfo.file.name}
                fileType={fileInfo.document_type}
              />
            ))}
          </UploadedFileRow>
        )}
        <ButtonRow>
          <FileUploader onFilesUpload={onFilesUpload} fileTypesOptions={fileTypesOptions} />
          {!hideSubmitButton && (
            <Button onClick={onSubmit} disabled={isSubmitting || (!inputValue && !files.length)}>
              {submitButtonText}
            </Button>
          )}
        </ButtonRow>
      </Form>
      {errors && errors.map((error) => <ErrorMessage visible={files.length > 0}>{error}</ErrorMessage>)}
      <ErrorMessage visible={!!duplicateFileNameUploaded}>
        {`A file with the name ${duplicateFileNameUploaded || ''} has already been added. Please change the file name and try again.`}
      </ErrorMessage>
    </FormContainer>
  );
};

export default CommentAndFileSection;
