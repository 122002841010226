import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage.tsx';
import Button, {
  baseButtonStyles,
  primaryButtonStyles,
  smallButtonStyles,
} from 'design-system/src/components/Button/Button';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { InfoBox } from 'design-system/src/components/InfoBox/InfoBox.tsx';
import type { AllFormData, SignupFormData } from '../../../types.ts';
import { FormLabel } from '../../FormLabel';
import { Input } from '../../Input';
import { Dropdown, DropdownContainer } from '../../Dropdown.tsx';
import { FormContainer } from '../FormContainer/FormContainer';
import {
  requiredErrorString,
  requiredErrorMessage,
  getRoleOptions,
  getCredentialsOptions,
  getHereAsOptions,
  getHeardAboutFromOptions,
} from '../utils.tsx';
import { ControllerWrapper } from '../ControllerWrapper.tsx';
import PatientContactModal from '../PatientContactModal/PatientContactModal.tsx';

const StyledSignupForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const StyledInfoBox = styled(InfoBox)`
  padding: 12px;
  margin-bottom: 0;
`;

const InfoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

// TODO(design-system): use Link component when it's available
const SeeHowItWorksButton = styled.a`
  ${baseButtonStyles}
  ${primaryButtonStyles}
  ${smallButtonStyles}
  ${standardTransitionStyles}
  ${focusVisibleStyles}

  > span {
    color: var(--white);
  }
`;

type SignupFormProps = {
  onContinue: (formData: SignupFormData) => void;
  onBeforeUnload: (formData: SignupFormData) => void;
  // For prefilling the form with data if the user navigates to another step and then comes back
  formData: AllFormData;
};

/**
 * Form collecting basic information for a provider to sign up.
 */
export const SignupForm = ({ onContinue, onBeforeUnload, formData }: SignupFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<SignupFormData>({ defaultValues: formData });
  const [showPatientContactModal, setShowPatientContactModal] = useState(false);
  const [hereAsValue, credentialsValue, heardAboutFromValue] = useWatch({
    control,
    name: ['here_as', 'credentials', 'heard_about_from'],
  });

  const isPatient = hereAsValue === 'patient';

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    onBeforeUnload(getValues());
    // We need to return a truthy value to get the browser to show the alert
    // that tries to persuade the user to stay and fill out the form
    return true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <FormContainer title="Sign up" showExtraContent={true}>
        <StyledSignupForm onSubmit={handleSubmit(onContinue)}>
          <Controller
            name="here_as"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownContainer>
                <FormLabel id="signing-up-as-label">I am signing up as...</FormLabel>
                <Dropdown
                  labelId="signing-up-as-label"
                  id="signing-up-as"
                  label="I am signing up as..."
                  $hasError={!!errors.here_as}
                  {...field}
                >
                  {getHereAsOptions()}
                </Dropdown>
                {errors.here_as && requiredErrorMessage}
              </DropdownContainer>
            )}
          />

          {isPatient && (
            // TODO: use expanding container component
            <StyledInfoBox includeIcon={false}>
              <Typography styledAs="bodyExtraSmallSpaceGrotesk" renderedAs="div">
                <InfoBoxContent>
                  <div>
                    Are you a patient? Ask your doctor to sign up for Tandem, or reach out to support@withtandem.com.
                  </div>
                  <div>
                    If your doctor isn't using Tandem but you're interested in our support services, please ask them to
                    sign up!
                  </div>
                  <FlexRow>
                    <SeeHowItWorksButton href="https://withtandem.com/patients">
                      <Typography styledAs="bodySmallSpaceGrotesk" weight="500" renderedAs="span">
                        See how it works
                      </Typography>
                    </SeeHowItWorksButton>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setShowPatientContactModal(true);
                      }}
                    >
                      Contact Tandem
                    </Button>
                  </FlexRow>
                </InfoBoxContent>
              </Typography>
            </StyledInfoBox>
          )}

          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownContainer>
                <FormLabel id="role-label">Role</FormLabel>
                <Dropdown
                  labelId="role-label"
                  id="role"
                  label="Role"
                  $hasError={!!errors.role}
                  disabled={isPatient}
                  {...field}
                >
                  {getRoleOptions()}
                </Dropdown>
                {errors.role && requiredErrorMessage}
              </DropdownContainer>
            )}
          />

          <Controller
            name="credentials"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownContainer>
                <FormLabel id="credentials-label">Credentials</FormLabel>
                <Dropdown
                  labelId="credentials-label"
                  id="credentials"
                  label="Credentials"
                  $hasError={!!errors.credentials}
                  disabled={isPatient}
                  {...field}
                >
                  {getCredentialsOptions()}
                </Dropdown>
                {errors.credentials && requiredErrorMessage}
              </DropdownContainer>
            )}
          />

          {credentialsValue && credentialsValue.includes('other') && (
            <Controller
              control={control}
              name="specify_other_credentials"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="Specify other credentials"
                  hasError={!!errors.specify_other_credentials}
                  disabled={isPatient}
                  {...field}
                />
              )}
            />
          )}

          <ControllerWrapper>
            <Controller
              control={control}
              name="first_name"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="First name"
                  autoComplete="given-name"
                  hasError={!!errors.first_name}
                  disabled={isPatient}
                  {...field}
                />
              )}
            />
            {errors.first_name && requiredErrorMessage}
          </ControllerWrapper>

          <ControllerWrapper>
            <Controller
              control={control}
              name="last_name"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="Last name"
                  autoComplete="family-name"
                  hasError={!!errors.last_name}
                  disabled={isPatient}
                  {...field}
                />
              )}
            />
            {errors.last_name && requiredErrorMessage}
          </ControllerWrapper>

          <ControllerWrapper>
            <Controller
              control={control}
              name="email"
              rules={{
                required: { value: true, message: requiredErrorString },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email',
                },
              }}
              render={({ field }) => (
                <Input label="Email" autoComplete="email" hasError={!!errors.email} disabled={isPatient} {...field} />
              )}
            />
            {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
          </ControllerWrapper>

          <ControllerWrapper>
            <Controller
              control={control}
              name="phone"
              rules={{
                required: { value: true, message: requiredErrorString },
                pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message: 'Invalid phone',
                },
              }}
              render={({ field }) => (
                <Input label="Phone" autoComplete="tel" hasError={!!errors.phone} disabled={isPatient} {...field} />
              )}
            />
            {errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
          </ControllerWrapper>

          <Controller
            name="heard_about_from"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownContainer>
                <FormLabel id="heard-about-from-label">Where did you hear about Tandem?</FormLabel>
                <Dropdown
                  labelId="heard-about-from-label"
                  id="heard-about-from"
                  label="Where did you hear about Tandem?"
                  $hasError={!!errors.heard_about_from}
                  disabled={isPatient}
                  {...field}
                >
                  {getHeardAboutFromOptions()}
                </Dropdown>
                {errors.heard_about_from && requiredErrorMessage}
              </DropdownContainer>
            )}
          />

          {heardAboutFromValue && heardAboutFromValue === 'other' && (
            <Controller
              control={control}
              name="specify_other_heard_about_from"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="Specify where you heard about Tandem"
                  hasError={!!errors.specify_other_heard_about_from}
                  disabled={isPatient}
                  {...field}
                />
              )}
            />
          )}

          <Button type="submit" width="full" size="lg" disabled={isPatient}>
            Continue
          </Button>
        </StyledSignupForm>
      </FormContainer>

      {showPatientContactModal && <PatientContactModal onClose={() => setShowPatientContactModal(false)} />}
    </>
  );
};
