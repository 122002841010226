import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { AuthContext } from '../auth';
import { client } from '../api';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import PageContainer from '../components/PageContainer';
import NotificationSettings from '../components/settings/NotificationSettings';
import UsersTable from '../components/settings/UsersTable';
import { FilterBarRow, FilterGroup, FilterLabel, Table, TableCell, TableHeader } from '../components/Table';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

type Tab = 'config' | 'notifications' | 'users';

type ConfigData = {
  show_config: boolean;
  icd_mapping: Record<string, string>;
};

const SettingsTabs = styled(FilterBarRow)`
  margin: 1rem 0;
`;

const HelperText = styled(Typography)`
  padding-left: 0.5rem;
`;

// TODO(design-system): Replace with Link component
const Link = styled.a`
  color: var(--dark-gray);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid var(--medium-gray);
  padding-bottom: 2px;
`;

const ConfigContainer = styled.div`
  max-width: 40em;
`;

const ConfigView: React.FC<{ data: ConfigData }> = ({ data }) => {
  const { icd_mapping } = data;
  return (
    <ConfigContainer>
      <HelperText styledAs="bodyDMSans" marginBottom="1rem">
        Below are your account settings for default ICD-10 codes to use on prior authorization requests for specific
        medications, unless otherwise specified in clinical documentation.
        <br />
        <br />
        You can <Link href="mailto:provider-support@withtandem.com">contact support</Link> at any time to update these
        settings.
      </HelperText>
      <Table>
        <thead>
          <tr>
            <TableHeader>Drug Name</TableHeader>
            <TableHeader>ICD-10 Code</TableHeader>
          </tr>
        </thead>
        <tbody>
          {Object.entries(icd_mapping).map(([ingredient, code]) => (
            <tr key={ingredient}>
              <TableCell>{ingredient}</TableCell>
              <TableCell>{code}</TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </ConfigContainer>
  );
};

const SettingsPage: React.FC = () => {
  const [tab, setTab] = useState<Tab>('notifications');
  const authContext = useContext(AuthContext);

  const configQuery = useQuery<ConfigData>({
    queryKey: ['config'],
    queryFn: () => {
      return client.get(`config`).then((res) => res.data);
    },
  });

  let body;
  if (configQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (configQuery.error) {
    body = <PageLoadError />;
  } else if (!configQuery.data) {
    body = null;
  } else {
    const showConfig = configQuery.data.show_config;
    const isAdmin = authContext.user?.is_admin;
    const showUsers = isAdmin && authContext.user?.feature_flags.settings_users_tab;

    body = (
      <>
        {showConfig || showUsers ? (
          <SettingsTabs>
            <FilterGroup>
              <FilterLabel $active={tab === 'notifications'} onClick={() => setTab('notifications')}>
                Notifications
              </FilterLabel>
              {showUsers && (
                <FilterLabel $active={tab === 'users'} onClick={() => setTab('users')}>
                  Users
                </FilterLabel>
              )}
              <FilterLabel $active={tab === 'config'} onClick={() => setTab('config')}>
                Other Configurations
              </FilterLabel>
            </FilterGroup>
          </SettingsTabs>
        ) : null}

        {tab === 'config' && showConfig && <ConfigView data={configQuery.data} />}
        {tab === 'notifications' && <NotificationSettings />}
        {tab === 'users' && showUsers && <UsersTable />}
      </>
    );
  }

  return <PageContainer title="Settings">{body}</PageContainer>;
};

export default SettingsPage;
