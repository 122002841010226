import styled from 'styled-components';
import { SearchField } from './Input';
import { Button } from './Button/Button';
import ChevronDown from 'design-system/src/assets/icons/chevronDown.svg?react';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { TableDropdownFilter } from './TableDropdownFilter/TableDropdownFilter';

export const TableContainer = styled.div`
  font-size: 0.9rem;
  color: var(--dark-gray);
  height: 100%;
  box-sizing: border-box;
`;

export const FilterBar = styled.div`
  display: flex;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--border-gray);
  gap: 1rem;
  flex-direction: column;
`;

export const FilterBarRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  // This is necessary for when the filters are replaced with the bulk actions buttons
  // TODO: consider opacity fading out the filter row contents and placing the bulk
  // action buttons on top using position: absolute so we don't have to hardcode the height
  min-height: 46px;
`;

export const FilterBarRowSection = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1rem;
`;

export const BulkActionsRow = styled(FilterBarRow)`
  justfiy-content: space-between;
`;

export const BulkActionButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  color: var(--dark-gray);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;

  &:hover {
    background-color: var(--light-border-gray);
    color: var(--black);
  }
`;

export const ToggleSelectionCheckbox = styled.button<{ $checked: boolean }>`
  all: unset;
  display: flex;
  width: 18px;
  height: 18px;
  color: var(--${({ $checked }) => ($checked ? 'purple' : 'medium-gray')});

  &:disabled {
    color: var(--light-border-gray);
    cursor: not-allowed;
  }
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 1.25rem;
  border-collapse: collapse;
  font-size: 1rem;
`;

export const TableHeader = styled(Typography).attrs({
  renderedAs: 'th',
  styledAs: 'bodyExtraSmallSpaceGrotesk',
  weight: 500,
})<{ $width?: string }>`
  cursor: pointer;
  padding: 0.625rem;
  border: none;
  text-align: left;
  border-bottom: 1px solid var(--border-gray);
  white-space: nowrap;
  ${({ $width }) => ($width ? `width: ${$width};` : '')};
`;

export const TableCell = styled(Typography).attrs({
  renderedAs: 'td',
  styledAs: 'bodySmallDMSans',
})<{ $width?: string }>`
  padding: 0.625rem;
  border: none;
  color: var(--black);
  ${({ $width }) => ($width ? `width: ${$width};` : '')};
`;

export const SearchButton = styled(Button)`
  margin-left: auto;
`;

export const SearchInput = styled(SearchField)<{
  $placement?: 'left' | 'right';
  $width?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
}>(
  ({ $placement = 'right', $width = '15.5rem' }) => `
  margin-left: ${$placement === 'right' ? 'auto' : '0'};
  margin-right: ${$placement === 'left' ? 'auto' : '0'};
  width: ${$width || '15.5rem'};
`,
);

export const Row = styled.tr`
  &:last-of-type {
    border-bottom: none;
  }
`;

// TODO: turn into a proper component that applies the onClick handler to onKeyDown
export const ClickableRow = styled.tr.attrs({ tabIndex: 0 })<{ $selected?: boolean; $new?: boolean }>`
  cursor: pointer;
  ${standardTransitionStyles}

  &:hover,
  &:focus-visible {
    background-color: var(--light-border-gray);
  }

  // child TableCell
  & > td {
    ${({ $new }) => ($new ? 'font-weight: bold;' : '')}
  }

  &:last-of-type {
    border-bottom: none;
  }

  ${focusVisibleStyles}
`;

export const FilterGroup = styled.div`
  display: flex;
  padding: 0.3rem;
  background-color: var(--surface);
  gap: 0.5rem;
  border-radius: 8px;
`;

export const FilterLabel = styled.button<{ $active: boolean }>`
  color: var(${({ $active }) => ($active ? '--black' : '--dark-gray')});
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 590;
  line-height: 1rem;
  padding: 0.5rem 0.75rem;
  ${({ $active }) => ($active ? 'background-color: var(--white);' : 'background: none;')}
  ${({ $active }) => ($active ? 'box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);' : '')}
  border-radius: 5px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

export const FilterBadge = styled.button<{ $active: boolean }>`
  color: var(${({ $active }) => ($active ? '--dark-gray' : '--black')});
  font-size: 0.9rem;
  font-weight: 590;
  line-height: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(${({ $active }) => ($active ? '--light-border-gray' : '--extra-light-surface')});
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  cursor: pointer;
  white-space: nowrap;
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
  }

  ${focusVisibleStyles}
`;

export const OfficeNameTableDropdownFilter = styled(TableDropdownFilter)`
  // Office names are especially long
  width: 22.5rem;
`;

export const ActionButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  margin: 0;
`;

const SortIndicatorIcon = styled(ChevronDown)<{ $desc: boolean }>`
  color: var(--black);
  transform: rotate(${({ $desc }) => ($desc ? 0 : 180)}deg);
  transition: transform 0.2s ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

// This container makes sure there is always the same amount of space to the right
// of the header text, regardless of whether the sorting indicator is present, to
// avoid the column width changing when the sorting indicator appears/disappears.
const IconContainer = styled.div`
  width: 1rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`;

export type HeaderSortingIndicatorProps = {
  // Typescript says this can also be a string, but I don't know
  // what string what would be...
  isSorted: boolean | string;
  sortDirection: 'asc' | 'desc' | false;
};

/**
 * Up/down chevron for headers of sorted table columns.
 */
export const HeaderSortingIndicator = ({ isSorted, sortDirection }: HeaderSortingIndicatorProps) => {
  return (
    <IconContainer>
      {isSorted ? (
        <SortIndicatorIcon
          $desc={sortDirection === 'desc'}
          title={`sorted ${sortDirection === 'desc' ? 'descending' : 'ascending'}`}
        />
      ) : null}
    </IconContainer>
  );
};
