import styled from 'styled-components';
import { Typography } from '../Typography/Typography';

const StyledInitialsAvatar = styled.div`
  background-color: var(--dark-gray);
  color: var(--white);
  border-radius: var(--border-radius-circle);
  display: flex;
  min-width: 32px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
`;

const Initials = styled(Typography)`
  color: var(--white);
`;

export type InitialsAvatarProps = {
  className?: string;
  name: string;
};

/**
 * A circular avatar component that displays the first letter of the name.
 */
export const InitialsAvatar = ({ name, className }: InitialsAvatarProps) => {
  return (
    <StyledInitialsAvatar className={className}>
      <Initials renderedAs="span" styledAs="bodySpaceGrotesk" weight={500}>
        {name.charAt(0)}
      </Initials>
    </StyledInitialsAvatar>
  );
};

export default InitialsAvatar;
