import { Modal, ModalTitle } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import styled from 'styled-components';
import { Drug, TaskType } from '../../api';
import { Patient } from '../../api';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import { useId, useState } from 'react';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { useMutation } from '@tanstack/react-query';
import { client } from '../../api';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const RadioButton = styled.input`
  cursor: pointer;
  width: 20px;
  height: 20px;
  accent-color: var(--purple);
  margin-top: 0;
`;

// putting bodySmallDMSans styles directly here to override the radio label styles
const RadioLabel = styled(Typography)`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
`;

export type DeclineReason =
  | 'already_enrolled'
  | 'internal'
  | 'should_not_be_enrolled'
  | 'no_longer_needs'
  // NOTE: this string is used in python/partner_portal/blueprints/rx.py and needs to be kept in sync
  | 'new_therapy'
  | 'other';

export type DeclineReasonOption = { label: string; value: DeclineReason };

const reasonOptions: DeclineReasonOption[] = [
  { label: 'The patient is already enrolled', value: 'already_enrolled' },
  { label: 'My practice will manage this enrollment internally', value: 'internal' },
  { label: 'The patient should not be enrolled', value: 'should_not_be_enrolled' },
  { label: 'The patient no longer needs this drug', value: 'no_longer_needs' },
  { label: 'The patient is switching therapies', value: 'new_therapy' },
  { label: 'Other', value: 'other' },
];

const modalEnrollmentDeclineReasons: DeclineReason[] = [
  'already_enrolled',
  'internal',
  'should_not_be_enrolled',
  'other',
];

const modalRenewalDeclineReasons: DeclineReason[] = ['no_longer_needs', 'new_therapy', 'other'];

const modalPrescriptionCancelReasons: DeclineReason[] = ['new_therapy', 'other'];

// Key type is string since it doesn't account for all task types
const taskTypeToItemLabel: Partial<Record<TaskType, string>> = {
  'Form signature request': 'enrollment',
  'PA expiring': 'renewal',
  'PA denied - Select next step': 'appeal',
};

type DeclineProps = {
  closeModal: () => void;
  patient: Patient;
  drug: Drug;
  taskType: TaskType;
  taskId: string;
  onTaskUpdate: () => void;
};

const DeclineModal = ({ closeModal, patient, drug, taskType, taskId, onTaskUpdate }: DeclineProps) => {
  const [declineReason, setDeclineReason] = useState('');
  const navigate = useNavigate();

  const declineTask = useMutation({
    mutationFn: async () => {
      await client.post(`tasks/${taskId}/decline`, {
        decline_reason: declineReason,
      });
    },
    onSuccess: () => {
      onTaskUpdate();
      toast.custom(() => <Toast variant="success">Task declined</Toast>);
      navigate('/tasks');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const cancelPrescription = useMutation({
    mutationFn: async () => {
      const res = await client.post(`rxs/${drug.id}/cancel`, {
        cancel_reason: declineReason,
      });
      return res.data;
    },
    onSuccess: () => {
      onTaskUpdate();
      toast.custom(() => <Toast variant="success">Prescription cancelled successfully</Toast>);
      navigate('/tasks');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const extraInfoData = [
    { label: 'Patient', value: `${patient?.first_name} ${patient?.last_name}` },
    { label: 'Drug', value: drug?.name },
  ];

  const cancelsPrescription = [
    'PA denied - Select next step',
    'PA blocked - Missing information',
    'Appeal drafted - Ready for review',
    'PA denied - Ready to appeal',
  ].includes(taskType);

  let modalDeclineReasons: DeclineReason[] = [];
  if (cancelsPrescription) {
    modalDeclineReasons = modalPrescriptionCancelReasons;
  } else {
    switch (taskType) {
      case 'Form signature request':
        modalDeclineReasons = modalEnrollmentDeclineReasons;
        break;
      case 'PA expiring':
        modalDeclineReasons = modalRenewalDeclineReasons;
        break;
    }
  }

  const modalDeclineOptions = reasonOptions.filter((option) => modalDeclineReasons.includes(option.value));
  const submitMutation = cancelsPrescription ? cancelPrescription : declineTask;

  const id = useId();

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>
        {cancelsPrescription ? 'Cancel prescription' : `Decline ${taskTypeToItemLabel[taskType]}`}
      </ModalTitle>
      <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="60px" />

      {cancelsPrescription ? (
        <Typography styledAs="bodyDMSans" id={`decline-reason-${id}`}>
          Tandem will stop processing this prescription and notify the patient of cancellation. Please select a reason
          for cancellation:
        </Typography>
      ) : (
        <Typography styledAs="bodyDMSans" id={`decline-reason-${id}`}>
          Why are you declining {taskTypeToItemLabel[taskType]}?
        </Typography>
      )}

      <RadioGroup aria-labelledby={`decline-reason-${id}`}>
        {modalDeclineOptions.map((reason) => (
          <RadioOption key={reason.value}>
            <RadioButton
              type="radio"
              id={reason.value}
              name="declineReason"
              value={reason.value}
              checked={declineReason === reason.value}
              onChange={() => setDeclineReason(reason.value as DeclineReason)}
            />
            <RadioLabel as="label" htmlFor={reason.value}>
              {reason.label}
            </RadioLabel>
          </RadioOption>
        ))}
      </RadioGroup>

      <ButtonRow>
        <Button variant="tertiary" onClick={closeModal}>
          {cancelsPrescription ? 'Back' : 'Cancel'}
        </Button>
        <Button
          onClick={() => submitMutation.mutate()}
          disabled={!declineReason || submitMutation.isPending}
          isLoading={submitMutation.isPending}
        >
          {cancelsPrescription ? 'Cancel prescription' : 'Submit'}
        </Button>
      </ButtonRow>
    </Modal>
  );
};

export default DeclineModal;
