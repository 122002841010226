import { containerBoxShadow } from 'design-system/src/sharedStyles';
import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  min-height: 100%;
`;

export const TopNavAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CONTENT_OUTER_WRAPPER_PADDING = 20;

export const ContentOuterWrapper = styled.div`
  padding: ${CONTENT_OUTER_WRAPPER_PADDING}px;
  // This allows the pdf iframe to expand and fill the page
  height: 100%;
`;

export const CenteredContent = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`;

export const Hairline = styled.div`
  margin: 1.5rem 0;
  height: 1px;
  width: 100%;
  background-color: var(--light-border-gray);
`;

// TODO(design-system): convert into Card component
export const StyledContainer = styled.div`
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--white);

  ${containerBoxShadow}
`;
