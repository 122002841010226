import { Typography } from 'design-system/src/components/Typography/Typography';
import { InfoBox } from 'design-system/src/components/InfoBox/InfoBox';

/**
 * A notice banner about premium drug features.
 */
export const PharmaRelationshipNotice = () => {
  return (
    <InfoBox variant="transparent" noMargin>
      <Typography styledAs="bodyExtraSmallDMSans">
        Tandem established a business relationship with the pharmaceutical manufacturer that results in a differentiated
        user experience. There may be lower cost medications available or preferred on the patient's plan.
      </Typography>
    </InfoBox>
  );
};

export default PharmaRelationshipNotice;
