import styled from 'styled-components';

export enum Alignment {
  Left = 'flex-start',
  Right = 'flex-end',
  Center = 'center',
  SpaceBetween = 'space-between',
}

const StyledButtonRow = styled.div<{ $align: Alignment; $fullWidth?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  align-items: center;
  flex-wrap: wrap;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`;

export type ButtonRowProps = {
  children: React.ReactNode;
  align?: Alignment;
  className?: string;
  fullWidth?: boolean;
};

/**
 * A horizontal bar for a row of buttons with alignment options.
 *
 * Using this component will keep the gaps we're placing between buttons consistent.
 */
const ButtonRow = ({ children, align = Alignment.Right, className, fullWidth }: ButtonRowProps) => {
  return (
    <StyledButtonRow $align={align} $fullWidth={fullWidth} className={className}>
      {children}
    </StyledButtonRow>
  );
};

export default ButtonRow;
