import styled from 'styled-components';
import { containerBoxShadow } from 'design-system/src/sharedStyles';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

// TODO: make this an actual form
export const Form = styled.div<{ $hasError?: boolean }>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.75rem;
  position: relative;
  overflow: hidden;
  transition: border-color 0.2s ease-out;
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-medium);
  ${containerBoxShadow}

  ${({ $hasError }) => $hasError && `border-color: var(--red);`}

  // TODO: set this around the textarea, not on the border itself
  // so it doesn't override the error state
  &:has(:focus-visible) {
    border-color: var(--purple);
    background: var(--white);
  }

  textarea {
    border: none;
    outline: none;
    // 2px padding on the top and left allows the full cursor/caret to be visible
    padding: 2px 0 0 2px;
    caret-color: var(--purple);
    font-size: 1rem;
    background-color: transparent;

    // TODO(design-system): refactor this to use the typography styles
    /* Body Small/DM Regular */
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.571%;
  }

  textarea::placeholder {
    color: var(--disabled-gray);
  }
`;

export const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  backdrop-filter: blur(3px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it sits above other content */
`;

export const ResponseInput = styled(TextareaAutosize)`
  border-radius: 6px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: none;
  // To ensure that the text input area is visible
  min-height: 24px;

  &:focus-visible {
    background: var(--white);
  }
`;
