import { Modal, ModalContent, ModalTitle } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Drug, NewCommentFile, Patient, client, FormFilesDocumentTypes } from '../../api';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import CommentAndFileSection from './CommentAndFileSection';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { StandardErrorToast, Toast } from 'design-system/src/components/Toast/Toast';
import * as Sentry from '@sentry/react';
import { tasksQueryKey } from '../../queries';

type NewFollowupTaskModalProps = {
  closeModal: () => void;
  patient: Patient;
  drug: Drug;
  patientId: string;
  prescriptionId: string;
  taskId: string;
};

const NewFollowupTaskModal = ({
  closeModal,
  patient,
  drug,
  patientId,
  prescriptionId,
  taskId,
}: NewFollowupTaskModalProps) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [files, setFiles] = useState<NewCommentFile[]>([]);
  const queryClient = useQueryClient();

  const extraInfoData = [
    { label: 'Patient', value: `${patient.first_name} ${patient.last_name}` },
    { label: 'Drug', value: drug.name },
  ];

  const declineTaskAndCreateNew = useMutation({
    mutationFn: async () => {
      await client.post(`tasks/${taskId}/decline`, {
        decline_reason: 'other',
      });
    },
    onSuccess: () => {
      createHelpRequestTask.mutate();
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const createHelpRequestTask = useMutation<{ task_id: string }, Error, void>({
    mutationFn: async () => {
      const formData = new FormData();
      formData.append('patient_id', patientId);
      formData.append('prescription_id', prescriptionId);
      formData.append('related_task_id', taskId);
      formData.append('text', inputValue);
      formData.append('type', 'help_request');
      formData.append('task_source', 'portal_task_view');

      if (files.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};

        files.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      return client.post(`tasks`, formData).then((res) => res.data);
    },
    onSuccess: ({ task_id }) => {
      queryClient.invalidateQueries({
        queryKey: tasksQueryKey(),
      });
      toast.custom(() => <Toast variant="success">Task declined and new task created.</Toast>);
      closeModal();
      navigate(`/tasks/${task_id}`);
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      Sentry.captureException(error);
      console.error(error);
    },
  });

  const handleFilesChange = (newFiles: NewCommentFile[]) => {
    setFiles(newFiles);
  };

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>Something else?</ModalTitle>
      <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="80px" />
      <ModalContent>
        <Typography>
          If you would like to do something other than appeal or cancel this prescription, please explain below.
        </Typography>
        <Typography>
          <strong>Note:</strong> Clicking Submit will decline this task and create a new task.
        </Typography>
        <CommentAndFileSection
          inputRef={inputRef}
          placeholder="Add details"
          hideSubmitButton
          files={files}
          onFilesChange={handleFilesChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </ModalContent>

      <ButtonRow align={Alignment.SpaceBetween}>
        <Button variant="tertiary" onClick={closeModal}>
          Back
        </Button>
        <Button
          onClick={() => declineTaskAndCreateNew.mutate()}
          isLoading={declineTaskAndCreateNew.isPending || createHelpRequestTask.isPending}
        >
          Submit
        </Button>
      </ButtonRow>
    </Modal>
  );
};

export default NewFollowupTaskModal;
