import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { useContext } from 'react';
import PageContainer from '../components/PageContainer';
import { AuthContext } from '../auth';
import TrainingVideoEmbed from '../components/TrainingVideoEmbed/TrainingVideoEmbed';

const TutorialPage: React.FC = () => {
  const { user } = useContext(AuthContext);

  let body;
  if (!user?.practice_training_video_link) {
    body = <PageLoadError />;
  } else {
    body = <TrainingVideoEmbed videoLink={user.practice_training_video_link} />;
  }

  return <PageContainer title="Tutorial">{body}</PageContainer>;
};

export default TutorialPage;
