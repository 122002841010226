import { Modal, ModalContent, ModalTitle } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import { useMutation } from '@tanstack/react-query';
import { client } from '../../api';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Patient, Drug } from '../../api';

type SwitchTherapyModalProps = {
  closeModal: () => void;
  patient: Patient;
  drug: Drug;
  onTaskUpdate: () => void;
};

const SwitchTherapyModal = ({ closeModal, patient, drug, onTaskUpdate }: SwitchTherapyModalProps) => {
  const navigate = useNavigate();

  const cancelPrescription = useMutation({
    mutationFn: async () => {
      const res = await client.post(`rxs/${drug.id}/cancel`, {
        cancel_reason: 'new_therapy',
      });
      return res.data;
    },
    onSuccess: () => {
      onTaskUpdate();
      toast.custom(() => <Toast variant="success">Prescription cancelled.</Toast>);
      navigate('/tasks');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const extraInfoData = [
    { label: 'Patient', value: `${patient.first_name} ${patient.last_name}` },
    { label: 'Drug', value: drug.name },
  ];

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>Switch therapy</ModalTitle>
      <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="80px" />
      <ModalContent>
        <Typography>
          Tandem will stop processing this prescription and notify the patient of cancellation. Please send a
          prescription for the new therapy to Tandem.
        </Typography>
        <ButtonRow align={Alignment.SpaceBetween}>
          <Button variant="tertiary" onClick={closeModal}>
            Back
          </Button>
          <Button onClick={() => cancelPrescription.mutate()} isLoading={cancelPrescription.isPending}>
            Cancel prescription
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

export default SwitchTherapyModal;
