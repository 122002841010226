import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import CheckCircleFilled from '../../assets/icons/checkCircleFilled.svg?react';
import Warning from '../../assets/icons/warning.svg?react';
import Info from '../../assets/icons/infoIcon.svg?react';
import ChevronRight from '../../assets/icons/chevronRight.svg?react';
import { Typography } from '../Typography/Typography';

type BannerVariant = 'success' | 'warning' | 'error' | 'neutral';

const successBanner = css`
  background-color: var(--light-green-2);
  color: var(--dark-green);
`;

const warningBanner = css`
  background-color: var(--light-yellow);
  color: var(--black);
`;

const errorBanner = css`
  background-color: var(--extra-light-red);
  color: var(--dark-red);
`;

const neutralBanner = css`
  background-color: var(--background-gray);
  color: var(--black);
`;

const StyledBanner = styled.div<{ $variant: BannerVariant }>`
  // transparent border is for windows high contrast mode
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  text-decoration: none;

  ${({ $variant }) => $variant === 'success' && successBanner}
  ${({ $variant }) => $variant === 'warning' && warningBanner}
  ${({ $variant }) => $variant === 'error' && errorBanner}
  ${({ $variant }) => $variant === 'neutral' && neutralBanner}
`;

const bannerIconColorMap: Record<BannerVariant, string> = {
  success: 'var(--green)',
  warning: 'var(--black)',
  error: 'var(--red)',
  neutral: 'var(--black)',
};

const BannerIcon = styled.div<{ $variant: BannerVariant }>`
  color: ${({ $variant }) => bannerIconColorMap[$variant]};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Title = styled.div<{ $hasUrl?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ $hasUrl }) => $hasUrl && `text-decoration: underline;`}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export type BannerProps = {
  variant: BannerVariant;
  title: string;
  description?: string;
  url?: string;
};

/**
 * A banner that appears across the top of the page.
 */
export const Banner = ({ variant, title, description, url }: BannerProps) => {
  let icon = <Warning />;
  if (variant === 'success') {
    icon = <CheckCircleFilled />;
  } else if (variant === 'neutral') {
    icon = <Info />;
  }

  return (
    <StyledBanner $variant={variant} as={url ? Link : 'div'} to={url}>
      <BannerIcon $variant={variant}>{icon}</BannerIcon>

      <Body>
        <Title $hasUrl={!!url}>
          <Typography weight={600}>{title}</Typography>
          {url && <ChevronRight />}
        </Title>

        {description && <Typography>{description}</Typography>}
      </Body>
    </StyledBanner>
  );
};

export default Banner;
