import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import ChevronLeft from 'design-system/src/assets/icons/chevronLeft.svg?react';
import LinkChain from 'design-system/src/assets/icons/linkChain.svg?react';
import LayoutWindow from 'design-system/src/assets/icons/layoutWindow.svg?react';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import Button, { IconButton } from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';

const StyledItemViewTopNav = styled.div`
  // NOTE: the top nav was shifting in size when we were only using 'height'
  min-height: var(--item-view-top-nav-height);
  padding: 0 0.75rem;
  box-sizing: border-box;
  background-color: var(--extra-light-surface);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // TODO: turn this into an overflow menu on narrow windows instead of wrapping
  flex-wrap: wrap;
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

const RightSideContent = styled(ButtonRow)`
  // TODO: turn this into an overflow menu on narrow windows instead of wrapping
  flex-wrap: wrap;
  align-items: center;
`;

const BackButton = styled(Button)`
  border: none;

  // TODO: I think there's a better way to do this..
  > svg {
    width: 0.625rem;
    height: 0.625rem;
    margin-bottom: 1px;
  }
`;

export type ItemViewTopNavProps = {
  isSidebarCollapsed?: boolean;
  setIsSidebarCollapsed?: Dispatch<SetStateAction<boolean>>;
  // If the item has no sidebar to collapse
  noSidebar?: boolean;
  // additional content that will be displayed on the right side of the top nav,
  // to the left of the permanent buttons
  additionalRightSideContent?: React.ReactNode;
};

/**
 * A bar that sits on top of the page. It's designed to work for a detailed view of an item in a table and contains a button for toggling visibility of the sidebar.
 */
export const ItemViewTopNav = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  noSidebar,
  additionalRightSideContent,
}: ItemViewTopNavProps) => {
  return (
    <StyledItemViewTopNav>
      <BackButton variant="tertiary" size="sm" onClick={() => history.back()}>
        <ChevronLeft aria-hidden={true} />
        <Typography renderedAs="span" styledAs="bodySmallSpaceGrotesk">
          Back
        </Typography>
      </BackButton>

      <RightSideContent>
        {additionalRightSideContent}

        <IconButton
          size="sm"
          onClick={() => {
            navigator.clipboard.writeText(window.location.href).then(
              () => {
                toast.custom(() => <Toast variant="success">Link copied</Toast>);
              },
              () => {
                toast.custom(() => <StandardErrorToast />);
              },
            );
          }}
        >
          <LinkChain title="copy link to current page" />
        </IconButton>

        {!noSidebar && isSidebarCollapsed !== undefined && setIsSidebarCollapsed && (
          <IconButton size="sm" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
            <LayoutWindow title={isSidebarCollapsed ? 'expand sidebar' : 'collapse sidebar'} />
          </IconButton>
        )}
      </RightSideContent>
    </StyledItemViewTopNav>
  );
};
