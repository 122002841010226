import styled from 'styled-components';
import { Tooltip } from 'design-system/src/components/Tooltip/Tooltip';

const StyledTruncatedText = styled.div<{ $lines: number }>`
  // limit to a specified number of lines of text, ending in ellipsis
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.$lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type TruncatedTextProps = {
  children: string | null;
  lines?: number;
};

/**
 * Displays a string of text, limited to a specified number of lines, ending in ellipsis, with a tooltip containing the full text.
 */
export const TruncatedText = ({ children, lines = 3 }: TruncatedTextProps) => {
  return children ? (
    <Tooltip content={children}>
      <StyledTruncatedText $lines={lines}>{children}</StyledTruncatedText>
    </Tooltip>
  ) : null;
};

export default TruncatedText;
