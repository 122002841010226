import styled from 'styled-components';
import InfoIcon from 'design-system/src/assets/icons/infoIcon.svg?react';

type InfoBoxVariant = 'light' | 'medium' | 'transparent';

const getStylesForVariant = (variant: InfoBoxVariant | undefined) => {
  if (variant === 'light') {
    return {
      background: 'var(--background-gray)',
      color: 'var(--black)',
    };
  }
  if (variant === 'transparent') {
    return {
      background: 'transparent',
      border: '1px solid var(--light-border-gray)',
      color: 'var(--dark-gray)',
    };
  }
  return {
    background: 'var(--light-border-gray)',
    color: 'var(--black)',
  };
};

const StyledInfoBox = styled.div<{ $variant?: InfoBoxVariant; $noMargin?: boolean }>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  ${({ $variant }) => getStylesForVariant($variant)}
  margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '24px')};
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  color: 'inherit';
`;

export type InfoBoxProps = {
  children: React.ReactNode;
  className?: string;
  includeIcon?: boolean;
  variant?: InfoBoxVariant;
  noMargin?: boolean;
};

export const InfoBox = ({
  children,
  className,
  includeIcon = true,
  variant = 'light',
  noMargin = false,
}: InfoBoxProps) => {
  return (
    <StyledInfoBox className={className} $variant={variant} $noMargin={noMargin}>
      {includeIcon && <StyledInfoIcon />}
      {children}
    </StyledInfoBox>
  );
};
