import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { AuthContext } from '../auth';
import { client } from '../api';
import { NavSidebar } from './NavSidebar';
import { TopNavbar } from './TopNavbar/TopNavbar';
import { OnboardingModal } from './OnboardingModal/OnboardingModal';
import Banner, { BannerProps } from 'design-system/src/components/Banner/Banner';
import { throttle } from 'lodash';

const Container = styled.div<{ $background: Background; $navbarPlacement: NavbarPlacement; $bannersHeight: number }>`
  background-color: ${({ $background }) =>
    $background === 'light' ? 'var(--light-surface);' : 'var(--extra-light-surface)'};
  min-height: calc(100vh - ${({ $bannersHeight }) => $bannersHeight}px);
  min-height: calc(100dvh - ${({ $bannersHeight }) => $bannersHeight}px); //iOS safari
  display: flex;
  flex-direction: ${({ $navbarPlacement }) => ($navbarPlacement === 'top' ? 'column;' : 'row')};
`;

const BannersContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // TODO: add z-index list
  z-index: 3;
`;

const MainContent = styled.div<{
  $background: Background;
  $navbarPlacement: NavbarPlacement;
  $noPadding?: boolean;
  $bannersHeight: number;
}>`
  flex: 1;
  ${({ $noPadding }) => ($noPadding ? null : 'padding: 1.25rem;')}
  margin-left: ${({ $navbarPlacement }) => ($navbarPlacement === 'top' ? '0;' : 'var(--nav-sidebar-width)')};
  background-color: ${({ $background }) =>
    $background === 'light' ? 'var(--light-surface);' : 'var(--extra-light-surface)'};
  margin-top: ${({ $bannersHeight }) => $bannersHeight}px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

// light is used for logged out pages and extraLight is logged in pages
type Background = 'extraLight' | 'light';
type NavbarPlacement = 'side' | 'top';

interface PageContainerProps {
  title?: string;
  children: React.ReactNode;
  onBack?: () => void;
  centered?: boolean;
  background?: Background;
  navbarPlacement?: NavbarPlacement;
  // Removes padding from main content
  noPadding?: boolean;
}

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  children,
  background = 'extraLight',
  navbarPlacement = 'side',
  noPadding,
}) => {
  const authContext = useContext(AuthContext);
  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banners, setBanners] = useState<BannerProps[]>(authContext.user?.banners || []);
  const [bannersHeight, setBannersHeight] = useState(0);
  const queryClient = useQueryClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const bannersContainerRef = useRef<HTMLDivElement>(null);

  const getBannersContainerHeight = () => {
    if (bannersContainerRef.current) {
      const bannersHeight = bannersContainerRef.current.clientHeight;
      setBannersHeight(bannersHeight);
    }
  };

  const throttledGetBannersContainerHeight = throttle(getBannersContainerHeight, 1000);

  useEffect(() => {
    window.addEventListener('resize', throttledGetBannersContainerHeight);

    return () => {
      window.removeEventListener('resize', throttledGetBannersContainerHeight);
    };
  }, []);

  useEffect(() => {
    throttledGetBannersContainerHeight();
  }, [containerRef.current]);

  useEffect(() => {
    if (authContext.user?.onboarding_modal.should_show && !onboardingModalVisible) {
      setOnboardingModalVisible(true);
    }
    if (!authContext.user?.onboarding_modal.should_show && onboardingModalVisible) {
      setOnboardingModalVisible(false);
    }
    if (authContext.user?.banners) {
      setBanners(authContext.user.banners);
    }
  }, [authContext]);

  const submitOnboardingModalView = useMutation({
    mutationFn: async () => {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      authContext.setUser({
        ...authContext.user!,
        onboarding_modal: {
          ...authContext.user!.onboarding_modal,
          should_show: false,
        },
      });

      return client
        .post('/onboarding-modal-view', {
          version: authContext.user?.onboarding_modal.version,
        })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      setIsSubmitting(false);
    },
  });

  return (
    <>
      <BannersContainer ref={bannersContainerRef}>
        {banners.map((banner) => (
          <Banner key={banner.title} {...banner} />
        ))}
      </BannersContainer>

      <Container
        $background={background}
        $navbarPlacement={navbarPlacement}
        $bannersHeight={bannersHeight}
        ref={containerRef}
      >
        {navbarPlacement === 'side' ? <NavSidebar bannersHeight={bannersHeight} /> : <TopNavbar />}
        <MainContent
          $background={background}
          $navbarPlacement={navbarPlacement}
          $noPadding={noPadding}
          $bannersHeight={bannersHeight}
        >
          {title && (
            <TitleContainer>
              <Typography styledAs="h5" renderedAs="h1">
                {title}
              </Typography>
            </TitleContainer>
          )}
          {children}
          {onboardingModalVisible && !!authContext.user?.practice_training_video_link && (
            <OnboardingModal
              onClose={() => {
                setOnboardingModalVisible(false);
                submitOnboardingModalView.mutate();
              }}
              practiceTrainingVideoLink={authContext.user.practice_training_video_link}
            />
          )}
        </MainContent>
      </Container>
    </>
  );
};

export default PageContainer;
