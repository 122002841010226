import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useId } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { toast } from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';

import type { OrgMember, OrgMemberRole } from '../../../api';
import { FormLabel } from '../../FormLabel';
import { client } from '../../../api';
import { Input } from '../../Input';
import { Dropdown, DropdownItem } from '../../Dropdown.tsx';

const StyledFieldset = styled.fieldset`
  all: unset;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type FormData = {
  first_name: string;
  last_name: string;
  email_address: string;
  role: OrgMemberRole;
};

export type EditMemberModalProps = {
  onClose: () => void;
  selectedMember: OrgMember;
  members: OrgMember[];
};

/**
 * A modal for editing a member of an organization.
 */
const EditMemberModal = ({ onClose, selectedMember, members }: EditMemberModalProps) => {
  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const duplicateEmailErrorMessageId = useId();
  const queryClient = useQueryClient();

  const submit = useMutation<{ success: boolean }, AxiosError<{ error: string }>, FormData>({
    mutationFn: async (data) => {
      if (isTrainingMode) {
        console.warn('Training Mode: Mocking user creation, no real request sent.');
        // Simulating a successful response without sending the request
        return Promise.resolve({ success: true });
      }

      return client.post(`org-members/edit/${selectedMember.id}`, data).then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => <Toast variant="success">User updated</Toast>);
      onClose();
    },
    onError: async (error) => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      first_name: selectedMember?.name?.split(' ')[0] || '',
      last_name: selectedMember?.name?.split(' ')[1] || '',
      email_address: selectedMember?.email_address || '',
      role: selectedMember?.is_admin ? 'stytch_admin' : 'stytch_member',
    },
  });

  const [emailValue] = useWatch({ control, name: ['email_address'] });
  const emailAlreadyExists =
    // if we're editing a member, it's expected that the email is the same as the selected member's email
    emailValue !== selectedMember?.email_address &&
    !!emailValue &&
    !!members.find((member) => member.email_address === emailValue);

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Edit user</ModalTitle>
      <ModalContent>
        <StyledFieldset disabled={submit.isPending}>
          <StyledForm onSubmit={handleSubmit((data) => submit.mutate(data))}>
            <Controller
              control={control}
              name="first_name"
              rules={{ required: true }}
              render={({ field }) => <Input label="First name" {...field} />}
            />

            <Controller
              control={control}
              name="last_name"
              rules={{ required: true }}
              render={({ field }) => <Input label="Last name" {...field} />}
            />

            {/* TODO: do we want to add email domain validation here? */}
            {/* wrapper div to stop the flex gap from separating the input and error message */}
            <div>
              <Controller
                control={control}
                name="email_address"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input label="Email" aria-describedby={duplicateEmailErrorMessageId} {...field} />
                )}
              />
              <ErrorMessage id={duplicateEmailErrorMessageId} visible={emailAlreadyExists}>
                User with this email address already exists
              </ErrorMessage>
            </div>

            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({ field }) => (
                <DropdownContainer>
                  <FormLabel id="role-label">Role</FormLabel>
                  <Dropdown
                    labelId="role-label"
                    id="role"
                    value={field.value}
                    label="Role"
                    onChange={field.onChange}
                    // the disabled fieldset isn't enough to disable the dropdown; we have to manually disable it too
                    disabled={submit.isPending || selectedMember.is_current_user}
                  >
                    <DropdownItem value="stytch_member">Non-admin</DropdownItem>
                    <DropdownItem value="stytch_admin">Admin</DropdownItem>
                  </Dropdown>
                </DropdownContainer>
              )}
            />

            <ButtonRow>
              {/* TODO: disable if the current values are the same as the default values */}
              <Button type="button" onClick={onClose} variant="tertiary" size="sm">
                Cancel
              </Button>
              <Button
                type="submit"
                // TODO: disable if editing and the current values are the same as the default values
                disabled={!formState.isValid || emailAlreadyExists}
                isLoading={submit.isPending}
                size="sm"
              >
                Save
              </Button>
            </ButtonRow>

            <ErrorMessage visible={submit.isError}>{submit.error?.response?.data.error}</ErrorMessage>
          </StyledForm>
        </StyledFieldset>
      </ModalContent>
    </Modal>
  );
};

export default EditMemberModal;
