import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';
import {
  client,
  NewCommentFile,
  FormFilesDocumentTypes,
  TaskComment,
  TaskEvent,
  PaInformationRequestTask,
} from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { useContext, useRef, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { TaskContext } from './contexts';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import Button from 'design-system/src/components/Button/Button';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { NoDocumentationAttachedWarningModal } from './NoDocumentationAttachedWarningModal/NoDocumentationAttachedWarningModal';
import DeclineModal from './DeclineModal';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import { Badge } from 'design-system/src/components/Badge/Badge';
import { ActionContainer } from './TaskActions';
import CommentAndFileSection from './CommentAndFileSection';

const SmallPrint = styled(Typography)`
  margin-top: 16px;
`;

/**
 * Version of TaskView that is used for PA information requests.
 */
const PaInformationRequestTaskView: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showAddInfoInput, setShowAddInfoInput] = useState(false);
  const [showAddNoInfoReasonInput, setShowAddNoInfoReasonInput] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showNoDocumentationWarningModal, setShowNoDocumentationWarningModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);

  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);

  const navigate = useNavigate();

  const onSubmit = () => {
    if (newFilesUploaded.length || showNoDocumentationWarningModal || showAddNoInfoReasonInput) {
      submit.mutate(undefined);
      return;
    }

    setShowNoDocumentationWarningModal(true);
  };

  const submit = useMutation<void, Error, string | undefined>({
    mutationFn: async (ehrSubmission?: string) => {
      // TODO: move all this into a util or something
      const formData = new FormData();
      formData.append('message', inputValue);
      formData.append('ehr_submission', ehrSubmission || '');
      if (showAddNoInfoReasonInput) {
        formData.append('submit_without_info', 'true');
      }

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};

        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      const res = await client.post(`tasks/${taskId}/submit-pa-information-request`, formData);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
      setNewFilesUploaded([]);
      setInputValue('');
      navigate('/tasks');
      toast.custom(() => <Toast variant="success">Response submitted successfully</Toast>);
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  let body;
  let declineModal;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const { drug, patient, prescriber } = taskQuery.data;
    const task = taskQuery.data.task as PaInformationRequestTask;

    declineModal = (
      <DeclineModal
        closeModal={() => setShowCancelConfirmationModal(false)}
        patient={patient}
        drug={drug}
        taskType={task.type}
        taskId={taskId}
        onTaskUpdate={onTaskUpdate}
      />
    );

    // Additional check that task.timeline isn't null so that this doesn't result in an error when executed
    const comments = (
      <TimelineItemList>
        {task.timeline &&
          task.timeline.map((item, index) => {
            let author;
            let action;
            let timestamp;
            let message;
            if (item.item_type === 'comment') {
              const comment = item as TaskComment;
              timestamp = comment.created_at;
              author = comment.direction === 'inbound' ? comment.user : 'Tandem';
              message = comment.message;
            } else {
              const event = item as TaskEvent;
              timestamp = event.event_timestamp;
              author = event.user;
              action = event.action;
              message = event.label;
            }
            return (
              <TimelineItem
                key={`task-item-${item.id}`}
                isLastEventInList={index === task.timeline.length - 1}
                author={author}
                action={action}
                timestamp={timestamp}
                message={message}
                attachedFilesIds={item.attachment_document_ids}
              />
            );
          })}
      </TimelineItemList>
    );

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            additionalRightSideContent={task.status === 'completed' && <Badge variant="neutral">Closed</Badge>}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" marginBottom="1rem">
                {task.type}
              </Typography>
              <GeneralInfoTable
                patientName={drug.patient_name}
                prescriberName={drug.prescriber_name}
                dob={drug.dob}
                prescribedOn={drug.prescription_recieved_at}
                drugName={drug.name}
                prescriptionId={drug.id}
              />

              {task.timeline && comments}

              {task.status !== 'completed' && (
                <ActionContainer>
                  <ButtonRow align={Alignment.Left}>
                    <Button
                      variant={showAddInfoInput ? 'highlighted' : showAddNoInfoReasonInput ? 'tertiary' : 'primary'}
                      onClick={() => {
                        setShowAddInfoInput(!showAddInfoInput);
                        setShowAddNoInfoReasonInput(false);
                      }}
                      size="sm"
                      disabled={submit.isPending}
                    >
                      {task.is_pharma_contract_task ? 'Provide suggested info' : 'Provide required info'}
                    </Button>

                    <Button
                      variant={showAddNoInfoReasonInput ? 'highlighted' : 'tertiary'}
                      onClick={() => {
                        setShowAddNoInfoReasonInput(!showAddNoInfoReasonInput);
                        setShowAddInfoInput(false);
                      }}
                      size="sm"
                      disabled={submit.isPending}
                    >
                      Submit without info
                    </Button>

                    <Button
                      variant="tertiary"
                      onClick={() => {
                        setShowCancelConfirmationModal(true);
                        setShowAddInfoInput(false);
                        setShowAddNoInfoReasonInput(false);
                      }}
                      size="sm"
                      disabled={submit.isPending}
                    >
                      Cancel prescription
                    </Button>
                  </ButtonRow>

                  {(showAddInfoInput || showAddNoInfoReasonInput) && (
                    <CommentAndFileSection
                      placeholder={
                        showAddInfoInput
                          ? 'Add response to PA question(s)'
                          : 'If information is unavailable, please provide an appropriate answer to the question(s).'
                      }
                      fileTypesOptions={['Lab Results', 'Medical Records', 'Other']}
                      onSubmit={onSubmit}
                      isSubmitting={updating}
                      inputRef={inputRef}
                      files={newFilesUploaded}
                      onFilesChange={setNewFilesUploaded}
                      inActionBar={true}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      autoFocus
                    />
                  )}
                </ActionContainer>
              )}

              <SmallPrint styledAs="bodyExtraExtraSmallDMSans">
                Please note that the prescribing licensed healthcare provider is responsible for ensuring that all the
                information provided on the prior authorization is accurate with respect to the patient. Tandem
                populates forms and letters based on information that you provide in the patient's medical record or
                directly, and Tandem is not responsible for the truth and accuracy of information provided by you.
              </SmallPrint>
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {!isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} showPharmaRelationshipNotice={task.is_pharma_contract_task} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      {body}

      {showNoDocumentationWarningModal && taskQuery.data?.task && (
        <NoDocumentationAttachedWarningModal
          task={taskQuery.data.task as PaInformationRequestTask}
          updating={updating}
          onSubmit={(ehrSubmission?: string) => submit.mutate(ehrSubmission)}
          onClose={() => setShowNoDocumentationWarningModal(false)}
        />
      )}

      {showCancelConfirmationModal && declineModal}
    </>
  );
};

export default PaInformationRequestTaskView;
