// The focusVisibleStyles without the &:focus-visible {} wrapper
export const focusVisibleStylesNoWrapper = `
    box-shadow:
      0px 0px 0px 1px #fff,
      0px 0px 0px 3px var(--purple);
    // We have a transparent outline for windows
    // high contrast mode, which removes decorative
    // styling like box-shadow but makes borders
    // and outlines 100% opacity black
    outline: 1px solid rgba(0, 0, 0, 0);
`;

export const focusVisibleStyles = `
  &:focus-visible {
    ${focusVisibleStylesNoWrapper}
  }
`;

export const standardTransitionStyles = `
  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out,
    border-color 0.2s ease-out,
    box-shadow 0.2s ease-out;
`;

export const popoverBoxshadow = `
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const containerBoxShadow = `
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
`;
