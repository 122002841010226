import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TaskContext } from '../components/tasks/contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Task, PaInformationRequestTask, TaskResponse, client } from '../api';
import { taskQueryKey, tasksQueryKey } from '../queries';
import TaskView from '../components/tasks/TaskView';
import UnstructuredTaskView from '../components/tasks/UnstructuredTaskView';
import PaInformationRequestTaskView from '../components/tasks/PaInformationRequestTaskView';
import { AuthContext, AuthContextType } from '../auth';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import PageContainer from '../components/PageContainer';

// these tasks are always structured
const STRUCTURED_TASK_TYPES = [
  'PA denied - Select next step',
  'Appeal drafted - Ready for review',
  'PA denied - Ready to appeal',
  'PA expiring',
];

interface TaskTypeViews {
  [key: string]: {
    component: React.FC<{}>;
    flag: string;
  };
}

const taskTypeViews: TaskTypeViews = {
  'PA blocked - Missing information': {
    component: PaInformationRequestTaskView,
    flag: 'document_gap_structured_tasks',
  },
};

const getTaskViewComponent = (task?: Task | PaInformationRequestTask, authContext?: AuthContextType) => {
  const taskType = task?.type;

  if (taskType) {
    // for task types that have a feature flag, check if the user has access to it and get the
    // appropriate view component if so
    if (taskType in taskTypeViews) {
      const taskFlagName = taskTypeViews[taskType].flag;
      if (authContext?.user?.feature_flags[taskFlagName]) {
        return taskTypeViews[taskType].component;
      }
    }

    // Form signature request tasks are only structured if they have an embedded_document_url
    if (taskType === 'Form signature request' && task?.embedded_document_url) {
      return TaskView;
    }

    // for tasks that are always structured, return the generic structured view component
    if (STRUCTURED_TASK_TYPES.includes(taskType)) {
      return TaskView;
    }
  }

  return UnstructuredTaskView;
};

const TaskPage: React.FC = () => {
  // TODO: use taskQuery.isPending instead
  const [updating, setUpdating] = useState(false);
  const authContext = useContext(AuthContext);
  const params = useParams();
  const taskId = params.taskId!;

  const queryClient = useQueryClient();
  const taskQuery = useQuery<TaskResponse>({
    queryKey: taskQueryKey(taskId),
    queryFn: async () => {
      const res = await client.get(`tasks/${taskId}`);
      return res.data;
    },
  });

  let body;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const TaskViewComponent = getTaskViewComponent(taskQuery.data?.task, authContext);

    body = (
      <TaskContext.Provider
        value={{
          taskId,
          taskQuery,
          updating,
          setUpdating,
          refetchTask: async () =>
            queryClient.invalidateQueries({
              queryKey: taskQueryKey(taskId),
              exact: true,
            }),
          onTaskUpdate: async () => {
            queryClient.invalidateQueries({
              queryKey: tasksQueryKey(),
            });
            queryClient.invalidateQueries({
              queryKey: taskQueryKey(taskId),
            });
            setUpdating(false);
          },
        }}
      >
        <TaskViewComponent />
      </TaskContext.Provider>
    );
  }

  return <PageContainer noPadding={true}>{body}</PageContainer>;
};

export default TaskPage;
