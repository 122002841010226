import styled from 'styled-components';
import { ButtonSubmittingSpinner } from 'design-system/src/components/ButtonSubmittingSpinner';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';

export const IconButton = styled.button<{ $disabled?: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: var(--border-radius-medium);
  color: var(--dark-gray);
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
  }

  ${focusVisibleStyles}

  &:disabled {
    cursor: not-allowed;
    background-color: var(--light-border-gray);
    color: var(--disabled-gray);
  }
`;

const StyledButton = styled.button`
  height: 44px;
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  font-weight: 600;
  background: var(--purple);
  color: var(--white);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  &[disabled] {
    background: var(--border-gray);
    cursor: not-allowed;
  }
`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  // If true, shows a loading spinner instead of the children
  isLoading?: boolean;
  // If true, uses a black loading spinner instead of a white one when isLoading is true
  hasLightBackground?: boolean;
}

/**
 * Button that supports loading state
 */
export const Button: React.FC<ButtonProps> = ({ children, isLoading, hasLightBackground, ...props }) => {
  return (
    <StyledButton {...props}>
      {isLoading ? <ButtonSubmittingSpinner hasLightBackground={hasLightBackground} /> : children}
    </StyledButton>
  );
};
