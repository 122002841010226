import styled from 'styled-components';
import { Typography } from '../Typography/Typography';
import React from 'react';

// TODO: create a component and swap out existing custom RadioGroup definitions

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RadioOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

export const RadioButton = styled.input`
  cursor: pointer;
  width: 20px;
  height: 20px;
  accent-color: var(--purple);
  margin-top: 0;
`;

// putting bodySmallDMSans styles directly here to override the radio label styles
export const RadioLabel = styled(Typography)`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
`;

export const RadioBlockGroup = styled(RadioGroup)`
  gap: 0.5rem;
  width: 100%;
`;

export const RadioBlockOption = styled(RadioOption)`
  height: 30px;
  border: 1px solid var(--light-border-gray);
  border-radius: var(--border-radius-medium);
  padding: 0.5rem;

  &:hover {
    transition: background-color 0.2s ease-out;
    background-color: var(--faded-purple);
  }

  &[data-checked='true'] {
    transition: background-color 0.2s ease-out;
    background-color: var(--faded-purple);
  }
`;

type RadioOptionWrapperProps = {
  id: string;
  name: string;
  value: string;
  children: React.ReactNode;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RadioOptionWrapper = ({ id, name, value, children, checked, onChange }: RadioOptionWrapperProps) => {
  return (
    <RadioBlockOption htmlFor={id} data-checked={checked}>
      <RadioButton type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} />
      {children}
    </RadioBlockOption>
  );
};
