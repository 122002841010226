import { client } from './api';
import React, { PropsWithChildren } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { BannerProps } from 'design-system/src/components/Banner/Banner';

type Tabs =
  | 'dashboard'
  | 'prescriptions'
  | 'tasks'
  | 'appeal_reviews'
  | 'pa_reviews'
  | 'notifications'
  | 'prior_auths'
  | 'fax_verification';

type User = {
  tabs: Tabs[];
  email: string;
  is_admin: boolean;
  practice_id: string | null;
  has_settings_page: boolean;
  onboarding_modal: {
    version: number;
    should_show: boolean;
  };
  feature_flags: {
    [key: string]: boolean;
  };
  banners: BannerProps[];
  practice_training_video_link: string | null;
};

export type AuthContextType = {
  auth: boolean;
  user: User | null;
  loaded: boolean;
  setUser: (user: User | null) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  auth: false,
  user: null,
  loaded: false,
  setUser: () => {},
});

export const LoginProvider = (props: PropsWithChildren<{}>) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['checkLogin'],
    queryFn: () => client.get('info').then((res) => res.data),
    retry: false,
  });

  const queryClient = useQueryClient();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  let value;
  if (isError) {
    value = { auth: false, user: null, loaded: true, setUser: () => {} };
  } else {
    value = {
      auth: true,
      user: data.user,
      loaded: true,
      setUser: (user: User | null) => {
        queryClient.setQueryData(['checkLogin'], { user });
      },
    };
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const LoginRequired = (props: PropsWithChildren<{}>) => {
  const authContext = React.useContext(AuthContext);
  const location = useLocation();

  if (authContext.auth === false) {
    const urlParams = new URLSearchParams(location.search);
    const org = urlParams.get('org');
    if (org) {
      urlParams.delete('org');
    }

    const loginPath = org ? `/login/${org}` : `/login`;

    let next = location.pathname;
    if (urlParams.toString() !== '') {
      next = `${next}?${urlParams.toString()}`;
    }

    let redirectUrl;
    if (next === '/') {
      redirectUrl = `${loginPath}`;
    } else {
      redirectUrl = `${loginPath}?next=${encodeURIComponent(next)}`;
    }
    return <Navigate to={redirectUrl} />;
  }

  return <>{props.children}</>;
};
