import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IconButton,
  baseButtonStyles,
  borderButtonStyles,
  iconButtonStyles,
  largeIconButtonStyles,
  secondaryButtonStyles,
} from 'design-system/src/components/Button/Button';
import { standardTransitionStyles, focusVisibleStyles } from 'design-system/src/sharedStyles';
import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuHeading,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from '../PopoverMenu';
import { FlexRow } from '../Layout';
import { Typography } from 'design-system/src/components/Typography/Typography';
import {
  FileInfoResponse,
  fileTypes,
  FileType,
  NewCommentFile,
  apiHost,
  client,
  getRxFilePreviewUrl,
  getRxFileDownloadUrl,
  fileTypeToUILabel,
} from '../../api';
import { practiceFileQueryKey, taskFileQueryKey } from '../../queries';
import { useQuery } from '@tanstack/react-query';
import { TaskContext } from './contexts';
import Paperclip from 'design-system/src/assets/icons/paperclip.svg?react';
import Close from 'design-system/src/assets/icons/close.svg?react';
import { truncateLongText } from '../../utils';
import Button from 'design-system/src/components/Button/Button';
import FilePreviewModal from 'design-system/src/components/FilePreviewModal/FilePreviewModal';
import { css } from 'styled-components';

export const UploadedFileRow = styled(FlexRow)`
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const fileDisplayStyles = css`
  display: inline-flex;
  flex-direction: column;
  border-radius: var(--border-radius-medium);
  margin-right: 0.5rem;
  cursor: pointer;
  padding: 10px;
  width: 168px;
  ${standardTransitionStyles}
  ${secondaryButtonStyles}
`;

const FileDisplay = styled.div`
  ${fileDisplayStyles}
`;

const attachedFileDisplayStyles = `
  box-sizing: border-box;
  width: 168px;
  // Set a height to keep stop it from shifting if the error text
  // appears
  height: 58px;
  display: flex;
  flex-direction: row;
  margin-right: 0;
  padding: 10px;
  gap: 0.5rem;
`;

const AttachedFileDisplay = styled(FileDisplay)`
  ${attachedFileDisplayStyles}
`;

const DownloadableFileDisplay = styled(AttachedFileDisplay)<{ $downloadable: boolean }>`
  all: unset;
  ${fileDisplayStyles}
  ${attachedFileDisplayStyles}
  cursor: ${({ $downloadable }) => ($downloadable ? 'pointer' : 'auto')};
  ${standardTransitionStyles}

  &:hover {
    background-color: ${({ $downloadable }) => ($downloadable ? 'var(--light-border-gray)' : 'transparent')};
  }
  ${focusVisibleStyles}
`;

const UploadPopoverButton = styled(PopoverMenuButton)`
  // TODO: add a method to Button.tsx that generates these styles
  ${baseButtonStyles}
  ${iconButtonStyles}
  ${largeIconButtonStyles}
  ${standardTransitionStyles}
  ${focusVisibleStyles}
  ${borderButtonStyles}
`;

const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  // The negative margin allows us to make the button a little bigger than
  // the space allows, sitting on top of the padding and gap around it
  margin: -0.25;
  display: flex;
  justify-content: center;
  align-items: center;
  ${standardTransitionStyles}
  &:hover {
    background-color: var(--light-border-gray);
  }
  ${focusVisibleStyles}
`;

const FileIconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  color: var(--dark-gray);
`;

const HiddenInput = styled.input`
  display: none;
`;

const loadingText = 'Loading...';

const getFileName = (isLoading: boolean, isError: boolean, data: FileInfoResponse | undefined) => {
  if (isLoading) {
    return loadingText;
  }
  if (isError) {
    return "File isn't available. Try again later.";
  }
  return data ? truncateLongText(data.filename, 24) : null;
};

const getFileType = (data: FileInfoResponse | undefined) => {
  if (!data) {
    return loadingText;
  }
  const fileType = data?.tags.find((tag) => tag.name === 'Document Type')?.value;
  if (!fileType || !fileTypes.includes(fileType)) {
    return '';
  }
  return fileTypeToUILabel[fileType];
};

interface FileUploaderProps {
  onFilesUpload: (newFiles: NewCommentFile[]) => void;
  fileTypesOptions?: FileType[];
}

export const FileUploader: React.FC<FileUploaderProps> = ({ onFilesUpload, fileTypesOptions = fileTypes }) => {
  const [uploadingFileType, setUploadingFileType] = React.useState<FileType | ''>('');
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (uploadingFileType === '' || files.length === 0) {
      return;
    }

    const filesWithType = files.map((file) => ({
      file: file,
      document_type: uploadingFileType,
    }));
    onFilesUpload(filesWithType);
    setUploadingFileType('');

    // Reset the file input value so the same file can be uploaded again if needed
    // We want to show an error message in that case instead of silently blocking the user
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (fileInputRef.current) {
      // onCancel isn't supported in react yet, but it will be soon
      // https://github.com/facebook/react/pull/27897
      fileInputRef.current.addEventListener('cancel', () => {
        // Reset the state if the user cancels out of the file selection dialog. Otherwise
        // the user won't be able to select the same file type again.
        setUploadingFileType('');
      });
    }
  }, []);

  useEffect(() => {
    if (!!uploadingFileType && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [uploadingFileType]);

  if (fileTypesOptions.length === 0) {
    console.error('fileTypesOptions is an empty array');
    return null;
  }
  const buttonChildren = <Paperclip title="attach file" />;

  return (
    <>
      <HiddenInput type="file" ref={fileInputRef} onChange={handleFileChange} multiple />

      {fileTypesOptions.length === 1 ? (
        <IconButton
          variant="tertiary"
          size="sm"
          onClick={() => {
            setUploadingFileType(fileTypesOptions[0]);
          }}
        >
          {buttonChildren}
        </IconButton>
      ) : (
        <PopoverMenu>
          <UploadPopoverButton>{buttonChildren}</UploadPopoverButton>

          <PopoverMenuItems>
            <PopoverMenuSection>
              <PopoverMenuHeading>What type of file are you uploading?</PopoverMenuHeading>

              {fileTypesOptions.map((fileType: FileType) => {
                return (
                  <PopoverMenuItem key={`file-type-option-${fileType}`}>
                    <PopoverMenuItemButton
                      data-file-type={fileType}
                      onClick={(event) => {
                        const target = event.target as HTMLElement;
                        const fileType = target.dataset.fileType as FileType;
                        setUploadingFileType(fileType);
                      }}
                    >
                      {fileTypeToUILabel[fileType]}
                    </PopoverMenuItemButton>
                  </PopoverMenuItem>
                );
              })}
            </PopoverMenuSection>
          </PopoverMenuItems>
        </PopoverMenu>
      )}
    </>
  );
};

interface SimpleFileUploaderProps {
  onFilesUpload: (newFiles: NewCommentFile[]) => void;
  documentType?: FileType;
}

// File uploader that assumes one documentType for file uploads
export const SimpleFileUploader: React.FC<SimpleFileUploaderProps> = ({ onFilesUpload, documentType = 'Other' }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) {
      return;
    }

    const filesWithType = files.map((file) => ({
      file: file,
      document_type: documentType,
    }));
    onFilesUpload(filesWithType);

    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <HiddenInput type="file" ref={fileInputRef} onChange={handleFileChange} multiple />
      <Button variant="secondary" onClick={() => fileInputRef.current?.click()}>
        Select files
      </Button>
    </>
  );
};

const FileInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.25rem;
`;

const FileName = styled(Typography).attrs({ styledAs: 'bodyExtraExtraSmallDMSans' })`
  color: var(--dark-gray);
`;

const StyledFileType = styled(Typography).attrs({ styledAs: 'bodyExtraSmallDMSans', weight: 600 })``;

interface UploadedFileProps {
  filename: string;
  fileType: FileType;
  onFileDelete: (filename: string, document_type: FileType) => void;
}

// A file that the user has selected to submit with their
// task comment
export const UploadedFile: React.FC<UploadedFileProps> = ({ filename, fileType, onFileDelete }) => {
  const filenameForUI = truncateLongText(filename, 24);
  const fileTypeForUI = fileTypeToUILabel[fileType];

  return (
    <AttachedFileDisplay>
      {/* TODO: make this only appear on hover */}
      <RemoveButton onClick={() => onFileDelete(filename, fileType)}>
        <FileIconContainer>
          <Close title="remove file" />
        </FileIconContainer>
      </RemoveButton>

      <FileInfo>
        <StyledFileType>{fileTypeForUI}</StyledFileType>
        <FileName>{filenameForUI}</FileName>
      </FileInfo>
    </AttachedFileDisplay>
  );
};

// A file that is attached to a comment that has been successfully submitted
export const AttachedFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { taskId, taskQuery } = useContext(TaskContext);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.isLoading, query.isError, query.data);
  const fileType = getFileType(query.data);

  const previewable = !query.isLoading && !query.isError;

  const handleFilePreview = (e: React.MouseEvent) => {
    if (previewable) {
      e.preventDefault();
      setIsPreviewVisible(true);
    }
  };

  const handleClosePreview = () => {
    setIsPreviewVisible(false);
  };

  const rxId = taskQuery?.data?.drug?.id;

  return (
    <>
      <FileDisplay as="div" onClick={handleFilePreview}>
        <FileInfo>
          {!query.isError && <StyledFileType>{fileType}</StyledFileType>}
          <FileName>{filename}</FileName>
        </FileInfo>
      </FileDisplay>

      {isPreviewVisible && previewable && rxId && (
        <FilePreviewModal
          fileUrl={getRxFilePreviewUrl(rxId, fileId)}
          onClose={handleClosePreview}
          fileHeader={fileType}
          downloadUrl={getRxFileDownloadUrl(rxId, fileId)}
        />
      )}
    </>
  );
};

// A file that belongs to a practice
export const PracticeFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const query = useQuery<FileInfoResponse>({
    queryKey: practiceFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/practice-files/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.isLoading, query.isError, query.data);
  const fileType = getFileType(query.data);

  const downloadable = !query.isLoading && !query.isError;

  return (
    <DownloadableFileDisplay
      $downloadable={downloadable}
      as={downloadable ? 'a' : 'div'}
      href={downloadable ? `${apiHost}/files/${fileId}/download` : undefined}
      download={downloadable}
    >
      <FileInfo>
        {!query.isError && <StyledFileType>{fileType}</StyledFileType>}
        <FileName>{filename}</FileName>
      </FileInfo>
    </DownloadableFileDisplay>
  );
};
