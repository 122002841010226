import type { MultiselectAutocompleteOption } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';

// The string used to separate multiple selections of a single category when combined
// into a string, for example in the url search params.
// 2 backslashes are necessary because the 1st backslash escapes the 2nd one.
// Keep in sync with python/partner_portal/rx_table_query.py
export const MULTI_SELECT_DELINEATOR = '\\,';

export const drugCategoryDropdownOptions: MultiselectAutocompleteOption[] = [
  { description: 'Biologic', value: 'biologic' },
  { description: 'Non-Biologic', value: 'non_biologic' },
];
