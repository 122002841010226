import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRef, useState, useId } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SelectChangeEvent } from '@mui/material';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import Button, {
  baseButtonStyles,
  largeButtonStyles,
  borderButtonStyles,
  tertiaryButtonStyles,
} from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { toast } from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import Subtract from 'design-system/src/assets/icons/subtract.svg?react';
import Plus from 'design-system/src/assets/icons/plus.svg?react';
import { IconButton } from 'design-system/src/components/Button/Button';
import { Typography } from 'design-system/src/components/Typography/Typography.tsx';
import { standardTransitionStyles, focusVisibleStyles } from 'design-system/src/sharedStyles';

import type { OrgMember } from '../../../api';
import { client } from '../../../api';
import { Dropdown, DropdownItem } from '../../Dropdown.tsx';
import { TableCell, TableContainer, TableHeader } from '../../Table.tsx';

const UploadFileLabel = styled.label`
  // TODO(design-system): add a method to Button.tsx that generates these styles
  ${baseButtonStyles}
  ${tertiaryButtonStyles}
  ${largeButtonStyles}
  ${standardTransitionStyles}
  ${focusVisibleStyles}
  ${borderButtonStyles}
  width: fit-content;
`;

const InstructionsList = styled.ul`
  padding-inline-start: 26px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const CustomTableContainer = styled(TableContainer)`
  > table {
    border-collapse: collapse;
  }
`;

const CustomTableHeader = styled(TableHeader)`
  border: none;
`;

const CustomTableRow = styled.tr`
  border: none;
`;

const CustomTableCell = styled(TableCell)<{ $hasError: boolean }>`
  padding: 0;
  border: 1px solid var(--${({ $hasError }) => ($hasError ? 'red' : 'border-gray')});
  ${({ $hasError }) => $hasError && `background-color: var(--extra-light-red);`}
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
  }
`;

const CellInput = styled.input`
  border: none;
  height: 46px;
  padding: 0 8px;
  // so the background color of the row is visible
  background-color: transparent;

  &:focus-visible {
    // the standard focus visible box shadow gets cut off by the border of the table cell
    // so we're using a custom outline color instead
    outline-color: var(--purple);
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin: 0 2px;

  fieldset {
    // we already have a border on the table cell, so we don't need another one
    border: none !important;
  }
`;

const RemoveRowIconCell = styled.td`
  width: 46px;
  display: block;
  padding-left: 4px;
  // override the error state of the row, which doesn't apply to this cell
  border: 1px solid var(--white);
  background-color: var(--white);
`;

const DuplicateEmailError = styled(ErrorMessage)`
  // vertically align with first column header
  margin-left: 60px;
`;

const ModalFooter = styled(ButtonRow)`
  // so it can sit outside of the modal content and be pushed to the bottom of the modal
  margin-top: 16px;
`;

export type AddMembersModalProps = {
  onClose: () => void;
  members: OrgMember[];
};

/**
 * A modal for adding one or more members of an organization.
 */
const AddMembersModal = ({ onClose, members }: AddMembersModalProps) => {
  const [tableData, setTableData] = useState<string[][]>([['', '', '', '']]);
  const duplicateEmailErrorMessageId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';

  const queryClient = useQueryClient();

  const tableColumns = ['First name', 'Last name', 'Email', 'Role'];

  const addRowsAndFilterOutEmptyRows = (newRows: string[][]) => {
    const cleanedNewRows = newRows.map((row) =>
      row.map((cell) => {
        if (cell.toLowerCase() === 'admin') {
          return 'stytch_admin';
        } else if (cell.toLowerCase() === 'non-admin') {
          return 'stytch_member';
        }
        return cell;
      }),
    );
    setTableData((existingRows) => [
      // initially the first row is empty, so we need to filter out the empty rows
      // and replace it with the new data
      ...existingRows.filter((row) => !row.every((cell) => !cell)),
      ...cleanedNewRows,
    ]);
  };

  const handleCellInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
    rowIndex: number,
    cellIndex: number,
  ) => {
    const value = event.target.value as string;
    const newTableData = [...tableData];
    newTableData[rowIndex][cellIndex] = value;
    setTableData(newTableData);
  };

  const handleCellInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('text');

    // allow users to paste regular text without interpreting it as a CSV
    if (!pastedText || !pastedText.includes('\n')) {
      return;
    }

    event.preventDefault();

    const lines = pastedText.toString().split('\n');
    lines?.forEach((line) => {
      const cells = line.split('\t');
      addRowsAndFilterOutEmptyRows([cells]);
    });
  };

  const handleRemoveRow = (rowIndex: number) => {
    // the table needs at least one row, so instead of removing the row, we'll replace it with an empty row
    if (tableData.length === 1) {
      const newTableData = [...tableData];
      newTableData[0] = Array(tableColumns.length).fill('');
      setTableData(newTableData);
      return;
    }
    const newTableData = [...tableData];
    newTableData.splice(rowIndex, 1);
    setTableData(newTableData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const contents = event.target?.result;
        const lines = contents?.toString().split(/\r?\n/);

        lines?.forEach((line) => {
          const cells = line.split(',');
          addRowsAndFilterOutEmptyRows([cells]);
        });

        if (inputRef.current) {
          inputRef.current.value = '';
        }
      };

      reader.readAsText(file);
    } else {
      alert('Failed to load file');
    }
  };

  const submit = useMutation<{ success: boolean }, AxiosError<{ error: string }>>({
    mutationFn: async () => {
      if (isTrainingMode) {
        console.warn('Training Mode: Mocking user creation, no real request sent.');
        // Simulating a successful response without sending the request
        return Promise.resolve({ member_id: 'mock-member-id' });
      }

      const data = tableData
        // filter out empty rows
        .filter((row) => !row.every((cell) => !cell))
        .map((row) => ({
          first_name: row[0],
          last_name: row[1],
          email_address: row[2],
          role: row[3],
        }));

      return client.post('org-members/add', data).then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => <Toast variant="success">Users added</Toast>);
      onClose();
    },
    onError: async (error) => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
    },
  });

  const duplicateEmails = tableData
    .filter((row) => members.some((member) => member.email_address === row[2]))
    .map((row) => row[2]);

  return (
    <Modal onClose={onClose} wide fullHeight>
      <ModalTitle>Add users</ModalTitle>
      <ModalContent>
        <Typography styledAs="bodySpaceGrotesk">
          Add your staff using one of the following methods:
          <InstructionsList>
            <li>type the relevant information into each cell in a row</li>
            <li>upload a .csv file</li>
            <li>copy and paste from a spreadsheet into any cell</li>
          </InstructionsList>
          The "role" value needs to be either "Admin" or "Non-admin".
        </Typography>

        <UploadFileLabel htmlFor="fileinput">
          <HiddenInput type="file" id="fileinput" ref={inputRef} onChange={handleFileUpload} accept=".csv" />
          <Typography renderedAs="span" styledAs="bodySmallSpaceGrotesk" weight="500">
            Upload .csv
          </Typography>
        </UploadFileLabel>

        <div>
          <DuplicateEmailError visible={duplicateEmails.length > 0} id={duplicateEmailErrorMessageId}>
            {duplicateEmails.length} emails are already in the system.
          </DuplicateEmailError>
          <CustomTableContainer>
            <table>
              <thead>
                <tr>
                  <th className="sr-only">Remove button</th>
                  {tableColumns.map((column) => (
                    <CustomTableHeader key={column}>{column}</CustomTableHeader>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <CustomTableRow key={rowIndex}>
                    <RemoveRowIconCell>
                      <IconButton onClick={() => handleRemoveRow(rowIndex)}>
                        <Subtract title="Remove row" />
                      </IconButton>
                    </RemoveRowIconCell>
                    {row.map((cellValue, cellIndex) => (
                      <CustomTableCell key={`${rowIndex}-${cellIndex}`} $hasError={duplicateEmails.includes(row[2])}>
                        {cellIndex === 3 ? (
                          <DropdownContainer>
                            <Dropdown
                              value={cellValue}
                              onChange={(event) => {
                                handleCellInputChange(event, rowIndex, cellIndex);
                              }}
                            >
                              <DropdownItem value="stytch_member">Non-admin</DropdownItem>
                              <DropdownItem value="stytch_admin">Admin</DropdownItem>
                            </Dropdown>
                          </DropdownContainer>
                        ) : (
                          <CellInput
                            value={cellValue}
                            onChange={(event) => handleCellInputChange(event, rowIndex, cellIndex)}
                            onPaste={(event) => handleCellInputPaste(event)}
                          />
                        )}
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>
                ))}
              </tbody>
            </table>
          </CustomTableContainer>
        </div>

        <Button
          onClick={() => setTableData([...tableData, Array(tableColumns.length).fill('')])}
          width="full"
          variant="tertiary"
        >
          Add row <Plus aria-hidden />
        </Button>
      </ModalContent>

      <ModalFooter>
        <Button onClick={onClose} variant="tertiary" size="sm" disabled={submit.isPending}>
          Cancel
        </Button>
        <Button
          onClick={() => submit.mutate()}
          disabled={tableData.every((row) => row.every((cell) => !cell)) || duplicateEmails.length > 0}
          isLoading={submit.isPending}
          size="sm"
        >
          Add
        </Button>
      </ModalFooter>

      <ErrorMessage visible={submit.isError}>{submit.error?.response?.data.error}</ErrorMessage>
    </Modal>
  );
};

export default AddMembersModal;
