import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { toast } from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';

import { client } from '../../../api';
import type { OrgMember } from '../../../api';
import { ExtraInfoTable } from '../../ExtraInfoTable/ExtraInfoTable';

export type DeleteMemberModalProps = {
  onClose: () => void;
  selectedMembers: OrgMember[];
};

const StyledExtraInfoTable = styled(ExtraInfoTable)`
  margin-bottom: 0;
`;

/**
 * A modal for deleting a member of an organization.
 */
const DeleteMemberModal = ({ onClose, selectedMembers }: DeleteMemberModalProps) => {
  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const queryClient = useQueryClient();

  const submit = useMutation<{ success: boolean }, AxiosError<{ error: string }>>({
    mutationFn: async () => {
      if (isTrainingMode) {
        console.warn('Training Mode: Mocking user deletion, no real request sent.');
        // Simulating a successful response without sending the request
        return Promise.resolve({ success: true });
      }

      return client
        .post('org-members/delete', { member_ids: selectedMembers.map((member) => member.id) })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => (
        <Toast variant="success">{selectedMembers.length === 1 ? 'User deleted' : 'Users deleted'}</Toast>
      ));
      onClose();
    },
    onError: async (error) => {
      await queryClient.invalidateQueries({ queryKey: ['orgMembers'] });
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
    },
  });

  const userWordFormat = selectedMembers.length === 1 ? 'user' : 'users';

  return (
    <Modal onClose={onClose}>
      <ModalTitle>{`Delete ${userWordFormat}?`}</ModalTitle>
      <ModalContent>
        <Typography>The following {userWordFormat} will be permanently removed from Tandem:</Typography>
        <StyledExtraInfoTable
          marginTop="0"
          noHairline
          minFieldLabelWidth="200px"
          data={selectedMembers.map((member) => ({
            label: member.name || '',
            value: member.email_address,
          }))}
        />

        <ButtonRow>
          <Button onClick={onClose} variant="tertiary" size="sm" disabled={submit.isPending}>
            Cancel
          </Button>
          <Button onClick={() => submit.mutate()} isLoading={submit.isPending} size="sm">
            Delete {userWordFormat}
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

export default DeleteMemberModal;
