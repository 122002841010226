import { Modal, ModalTitle } from 'design-system/src/components/Modal';
import TrainingVideoEmbed from '../TrainingVideoEmbed/TrainingVideoEmbed';

export type OnboardingModalProps = {
  onClose: () => void;
  practiceTrainingVideoLink: string | null;
};

/**
 * A modal with information to help new users nagivate the app
 */
export const OnboardingModal = ({ onClose, practiceTrainingVideoLink }: OnboardingModalProps) => {
  return (
    <Modal wide={true} onClose={onClose}>
      <ModalTitle>Welcome to the new Tandem Portal</ModalTitle>
      <TrainingVideoEmbed videoLink={practiceTrainingVideoLink ?? ''} />
    </Modal>
  );
};
