import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { RenderHtmlString } from 'design-system/src/components/RenderHtmlString/RenderHtmlString';
import { formatStandardDate } from '../../utils';
import { AttachedFile } from '../tasks/Files';
import React from 'react';

const TimelineItemListWrapper = styled.div`
  margin-bottom: 16px;
`;

export const StyledTimelineItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO: rename these to be more general because they're being used for events in rx statuses and reviews
export const StyledEventHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const BulletPoint = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 12px;
  border-radius: 50%;
  background-color: var(--border-gray);
`;

export const AuthorAndTimestamp = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const EventTimestamp = styled(Typography).attrs({
  styledAs: 'bodyExtraExtraSmallDMSans',
})`
  color: var(--dark-gray);
`;

export const EventBody = styled.div<{ $lastEvent: boolean }>`
  padding-left: 1rem;

  ${({ $lastEvent }) => {
    return !$lastEvent
      ? `
      border-left: 1px var(--border-gray) solid;
      margin-left: 3px;
    `
      : `
      // The last comment does not have the vertical line to the left
      margin-left: 4px;
    `;
  }}
`;

export const EventGap = styled.div<{ $lastEvent: boolean }>`
  height: 0;

  ${({ $lastEvent }) => {
    // The gap after the last comment does not have the vertical line to the left
    return (
      !$lastEvent &&
      `
      height: 12px;
      padding-left: 1rem;
      border-left: 1px var(--border-gray) solid;
      margin-left: 3px;
    `
    );
  }}
`;

const EventDescription = styled(Typography)`
  margin-top: 0.5rem;
`;

const EventFiles = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export type TimelineItemProps = {
  isLastEventInList: boolean;
  author?: string;
  // Note: regular comments won't have an `action`
  action?: string;
  // In ISO format
  timestamp: string;
  // Won't exist on action comments (e.g. appeal review acceptance)
  message?: string;
  attachedFilesIds?: string[];
};

export type TimelineItemListProps = {
  children: React.ReactElement[] | React.ReactElement;
};

/**
 * Wrapper for a list of TimelineItem`s (can be a "list" of 1 item).
 *
 * Includes the title "Activity" above the list.
 */
export const TimelineItemList = ({ children }: TimelineItemListProps) => {
  return (
    <TimelineItemListWrapper>
      <Typography styledAs="bodyLargeSpaceGrotesk" weight={500} marginBottom="1rem">
        Activity
      </Typography>
      <StyledTimelineItemList>{children}</StyledTimelineItemList>
    </TimelineItemListWrapper>
  );
};

/**
 * A comment in a list of comments on a task.
 *
 * Can also be used for items in a list of activity that appears like a comment (e.g. appeal review rejection) on a page for a task-like object (e.g. an appeal review).
 *
 * This component is intended to be used in conjunction with the TimelineItemList (also defined in this file).
 */
export const TimelineItem = ({
  isLastEventInList,
  author,
  action,
  timestamp,
  message,
  attachedFilesIds,
}: TimelineItemProps) => {
  return (
    <div>
      <StyledEventHeader>
        <BulletPoint />
        <AuthorAndTimestamp>
          {author && (
            <Typography styledAs="bodySmallDMSans" weight={600}>
              {author}
            </Typography>
          )}
          {action && <Typography styledAs="bodySmallDMSans">{action}</Typography>}
          {(author || action) && <span aria-hidden>·</span>}
          <EventTimestamp>{formatStandardDate(timestamp)}</EventTimestamp>
        </AuthorAndTimestamp>
      </StyledEventHeader>

      <EventBody $lastEvent={isLastEventInList}>
        {message && (
          <EventDescription styledAs="bodySmallDMSans">
            <RenderHtmlString>{message}</RenderHtmlString>
          </EventDescription>
        )}

        {attachedFilesIds && !!attachedFilesIds.length && (
          <EventFiles>
            {attachedFilesIds.map((fileId: string) => (
              <AttachedFile key={fileId} fileId={fileId} />
            ))}
          </EventFiles>
        )}
      </EventBody>

      <EventGap $lastEvent={isLastEventInList} />
    </div>
  );
};
