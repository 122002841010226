import { useId } from 'react';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import Search from '../assets/search.svg?react';
import { FormLabel } from './FormLabel';

import React from 'react';
import styled from 'styled-components';

type SharedProps = {
  id?: string;
  placeholder?: string;
  label: string;
  // If passed, the label will not be visually displayed.
  // Instead, it will be used as placeholder text and accessibly linked to the input.
  hideLabel?: boolean;
  hasError?: boolean;
  helperText?: string;
};

type InputProps = SharedProps & React.HTMLProps<HTMLInputElement>;

type TextAreaProps = SharedProps & React.HTMLProps<HTMLTextAreaElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, label, placeholder = '', type = 'text', hasError, helperText, hideLabel = false, ...rest }, ref) => {
    const inputId = id || useId();
    const helperTextId = useId();

    return (
      <InputContainer>
        <FormLabel htmlFor={inputId} className={hideLabel ? 'sr-only' : ''}>
          {label}
        </FormLabel>
        {helperText && (
          <HelperText id={helperTextId} styledAs="bodyExtraSmallDMSans">
            {helperText}
          </HelperText>
        )}
        <StyledInput
          id={inputId}
          ref={ref}
          type={type}
          placeholder={placeholder}
          $hasError={hasError}
          aria-describedby={helperTextId}
          {...rest}
        />
      </InputContainer>
    );
  },
);

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, label, placeholder = '', helperText, ...rest }, ref) => {
    const inputId = id || useId();
    const helperTextId = useId();

    return (
      <InputContainer>
        <FormLabel htmlFor={inputId}>{label}</FormLabel>
        {helperText && (
          <HelperText id={helperTextId} styledAs="bodySmallDMSans">
            {helperText}
          </HelperText>
        )}
        <StyledTextArea id={inputId} ref={ref} placeholder={placeholder} aria-describedby={helperTextId} {...rest} />
      </InputContainer>
    );
  },
);

const InnerIconContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.75rem;
`;
export const SearchField = React.forwardRef<HTMLInputElement, InputProps & { loading?: boolean }>(
  ({ id, label, placeholder = '', hideLabel = false, className, loading = false, ...rest }, ref) => {
    const elementId = id || useId();

    return (
      <InputContainer className={className}>
        {!hideLabel && <FormLabel htmlFor={elementId}>{label}</FormLabel>}
        <StyledInput
          id={elementId}
          ref={ref}
          type="text"
          placeholder={hideLabel ? label : placeholder}
          aria-label={hideLabel ? label : undefined}
          autoComplete="off"
          $icon
          {...rest}
        />
        <InnerIconContainer>
          {loading ? <LoadingSpinner /> : <SearchIcon aria-hidden={true} $hideLabel={hideLabel} />}
        </InnerIconContainer>
      </InputContainer>
    );
  },
);

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input<{ $icon?: boolean; $hasError?: boolean }>`
  border: 1px solid var(--border-gray);
  background-color: transparent;
  border-radius: var(--border-radius-medium);
  padding: 10px 12px 10px ${({ $icon = false }) => ($icon ? '2.75rem' : '12px')};
  height: 44px;
  box-sizing: border-box;
  ${standardTransitionStyles}

  font-size: 0.9rem;
  font-style: normal;
  line-height: 1rem;
  color: var(--black);

  ${focusVisibleStyles}

  &:disabled {
    background-color: var(--background-gray);
    cursor: not-allowed;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    border-color: var(--red);
    
    &:focus-visible {
      border-color: var(--red);
      box-shadow: 0 0 2px var(--red);
    }
  `}
`;

const StyledTextArea = styled.textarea`
  border: 1px solid var(--medium-gray);
  background-color: transparent;
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1.41;

  &:focus-visible {
    outline: none;
    border: 1px solid var(--purple);
    box-shadow: 0 0 2px var(--purple);
    background-color: var(--white);
  }
`;

const SearchIcon = styled(Search)<{ $hideLabel?: boolean }>`
  position: absolute;
  left: 12px;
  top: ${({ $hideLabel }) => ($hideLabel ? '10px' : '36px')};
  color: var(--black);
`;

const HelperText = styled(Typography)`
  margin-bottom: 4px;
`;
