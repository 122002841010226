import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Typography } from 'design-system/src/components/Typography/Typography';
import ChevronRight from 'design-system/src/assets/icons/chevronRight.svg?react';
import EyeIcon from 'design-system/src/assets/icons/eye.svg?react';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import FilePreviewModal from 'design-system/src/components/FilePreviewModal/FilePreviewModal';
import { Tooltip } from 'design-system/src/components/Tooltip/Tooltip';
import { DownloadFileButton } from 'design-system/src/components/DownloadFileButton/DownloadFileButton';
import { containerBoxShadow, focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import { IconButton } from '../components/Button/Button';
import PageContainer from '../components/PageContainer';
import { client, getRxFileDownloadUrl, getRxFilePreviewUrl, StatusTimelineEvent } from '../api';
import { truncateLongText, formatStandardDateLocaleString, formatSimpleDate } from '../utils';
import type {
  RxTask,
  RxDocument,
  RxResponse,
  RxTaskType,
  RxTaskListType,
  LastContactedPatient,
  FileType,
  PADecision,
} from '../api';
import { ItemViewTopNav } from '../components/tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
  Hairline,
} from '../components/tableItemDetailViewComponents/Containers';
import { Sidebar, SidebarTopBar } from '../components/sidebar/Sidebar';
import {
  DrugPanel,
  PatientPanel,
  PrescriberPanel,
  PharmacyPanel,
  InsurancePanel,
} from '../components/sidebar/SidebarPanels';
import { GeneralInfoTable } from '../components/GeneralInfoTable/GeneralInfoTable';
import { ExtraInfoTable } from '../components/ExtraInfoTable/ExtraInfoTable';
import { CollapsibleTimeline } from '../components/CollapsibleTimeline/CollapsibleTimeline';

import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';

import { Link } from 'react-router-dom';
import { Row, Table, TableCell, TableContainer, TableHeader, HeaderSortingIndicator } from '../components/Table';
import { Button } from '../components/Button/Button';
import { ToggleButton, ToggleButtonContainer } from '../components/ToggleButton/ToggleButton';
import { FlexRow } from 'design-system/src/components/Layout';
import { breakpoints } from 'design-system/src/tokens/breakpoints';

const Headline = styled(Typography)`
  margin-bottom: 1rem;
`;

// TODO(design-system): use Link styles when available
const linkStyles = css`
  cursor: pointer;
  color: var(--black);
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: var(--medium-gray);
  // Pushes the underline away from the text
  text-underline-offset: 3px;
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

const MobileLink = styled.a`
  ${linkStyles}

  // intentionall using a breakpoint 1px below what DesktopLink is using
  // to avoid a gap when neither link is visible
  @media (min-width: ${breakpoints.smallDesktopMin}px) {
    display: none;
  }
`;

const DesktopLink = styled.button`
  all: unset;

  ${linkStyles}

  // intentionall using a breakpoint 1px above what MobileLink is using
  // to avoid a gap when neither link is visible
  @media (max-width: ${breakpoints.tabletMax}px) {
    display: none;
  }
`;

const LeftSide = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// TODO(design-system): convert into ClickableRowCard component
const Task = styled(Link)`
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--white);
  ${containerBoxShadow}
  ${standardTransitionStyles}

  &:hover {
    background: var(--light-border-gray);
  }

  ${focusVisibleStyles}
`;

const CreateTaskButton = styled(Button)`
  display: inline-flex;
  flex-direction: column;
  padding: 0.25rem 0.75rem;
  height: 36px;
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  color: var(--black);
  background: transparent;
  ${standardTransitionStyles}

  &:hover {
    background: var(--light-border-gray);
  }

  ${focusVisibleStyles}
`;

const TitleRow = styled(FlexRow)`
  margin-bottom: 1rem;
`;

const DownloadFileCell = styled.div`
  display: flex;
  gap: 4px;
  color: var(--dark-gray);

  // only show the preview button on large screens
  > button {
    @media (max-width: ${breakpoints.tabletMax}px) {
      display: none;
    }
  }
`;

const PreviewTableDocumentContainer: React.FC<{
  fileId: string;
  rxId: string;
  documentType: FileType;
}> = ({ fileId, rxId, documentType }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const fileUrl = getRxFilePreviewUrl(rxId, fileId);

  const handleFilePreview = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };

  return (
    <Tooltip content="Preview">
      <IconButton onClick={handleFilePreview}>
        <EyeIcon aria-hidden="true" />
        {isPreviewVisible && (
          <FilePreviewModal
            fileUrl={fileUrl}
            onClose={handleClosePreview}
            fileHeader={documentType}
            downloadUrl={getRxFileDownloadUrl(rxId, fileId)}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

interface DocumentTableProps {
  rxId: string;
  data: RxDocument[];
}

const DocumentsTable = ({ rxId, data }: DocumentTableProps) => {
  const columns = [
    {
      header: 'Date',
      accessorKey: 'date',
      id: 'date',
      // TODO: fix `any`
      cell: (info: any) => formatStandardDateLocaleString(info.getValue()),
    },
    {
      header: 'Document type',
      accessorKey: 'document_type',
      id: 'document_type',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
      cell: (info: any) => truncateLongText(info.getValue()),
    },
    {
      header: '',
      id: 'download',
      cell: (info: any) => (
        <DownloadFileCell key={info.cell.id}>
          <PreviewTableDocumentContainer
            rxId={rxId}
            fileId={info.row.original.id}
            documentType={info.row.original.document_type}
          />
          <DownloadFileButton downloadUrl={getRxFileDownloadUrl(rxId, info.row.original.id)} />
        </DownloadFileCell>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableContainer>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.id !== 'download' && (
                      <HeaderSortingIndicator
                        isSorted={header.column.getIsSorted()}
                        sortDirection={header.column.getNextSortingOrder()}
                      />
                    )}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <Row key={row.original.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </Row>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No documents.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const RxPage: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [hideClosedTasks, setHideClosedTasks] = useState(true);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const navigate = useNavigate();

  const params = useParams();
  const query = useQuery<RxResponse>({
    queryKey: ['rxs', params.rxId],
    queryFn: () => {
      return client.get(`rxs/${params.rxId}`).then((res) => res.data);
    },
  });

  const queryClient = useQueryClient();

  const getLastContactedPatientCopy = (lastContactPatient: LastContactedPatient) => {
    const { action, timestamp } = lastContactPatient;

    if (!action || !timestamp) {
      return null;
    }

    const titleCase = (str: string) => str[0].toUpperCase() + str.slice(1);
    return `${titleCase(action)} patient on ${formatSimpleDate(timestamp)}`;
  };

  const getTaskUrl = (taskType: RxTaskType, id: string) => {
    let table = 'tasks';
    if (taskType === 'appeal_review') {
      table = 'appeal-reviews';
    } else if (taskType === 'prior_auth_review') {
      table = 'prior-auth-reviews';
    }

    return `/${table}/${id}`;
  };

  const getTasksHeadlineCopy = (task_list_type: RxTaskListType) => {
    if (task_list_type === 'all') {
      return 'Tasks and reviews';
    }
    if (task_list_type === 'tasks') {
      return 'Tasks';
    }
    if (task_list_type === 'reviews') {
      return 'Reviews';
    }
    return null;
  };

  const getNoTasksCopy = (task_list_type: RxTaskListType) => {
    if (task_list_type === 'all') {
      return 'No tasks or reviews';
    }
    if (task_list_type === 'tasks') {
      return 'No tasks';
    }
    if (task_list_type === 'reviews') {
      return 'No reviews';
    }
    return null;
  };

  let body;
  if (query.isLoading) {
    body = <LoadingSpinner />;
  } else if (query.error) {
    body = <PageLoadError />;
  } else if (query.data) {
    const { pa_decision, status_timelines, task_list_type, tasks, documents, last_contacted_patient } = query.data.rx;
    const { patient, prescriber, drug, pharmacy, insurance } = query.data;

    const getInsuranceWithPALetterNote = (
      pa_decision: PADecision,
      insurance_status_timeline: StatusTimelineEvent[],
    ) => {
      if (!insurance_status_timeline.length || (pa_decision !== 'APPROVAL' && pa_decision !== 'DENIAL')) {
        return insurance_status_timeline;
      }

      const docType = pa_decision === 'APPROVAL' ? 'PA Approval Letter' : 'PA Denial Letter';
      const doc = documents.find((document) => document.document_type === docType);
      const docEventText = pa_decision === 'APPROVAL' ? 'PA approved' : 'PA denied';
      const docEventIndex = insurance_status_timeline.findIndex((event) => event.event === docEventText);
      // if we can't find the event for some reason, attach the letter to the most recent event
      const eventWithLetterIndex = docEventIndex === -1 ? insurance_status_timeline.length - 1 : docEventIndex;
      const rxFileLinkText = '(See letter)';

      const handleFilePreview = (e: React.MouseEvent) => {
        e.stopPropagation();
        setPreviewVisible(true);
      };

      const handleClosePreview = () => {
        setPreviewVisible(false);
      };

      const suffix = !doc ? (
        '(Awaiting letter)'
      ) : (
        <>
          <MobileLink href={getRxFileDownloadUrl(params.rxId || '', doc.id)}>{rxFileLinkText}</MobileLink>
          <DesktopLink onClick={handleFilePreview}>{rxFileLinkText}</DesktopLink>
          {isPreviewVisible && (
            <FilePreviewModal
              fileUrl={getRxFilePreviewUrl(params.rxId || '', doc.id)}
              onClose={handleClosePreview}
              fileHeader={docType}
              downloadUrl={getRxFileDownloadUrl(params.rxId || '', doc.id)}
            />
          )}
        </>
      );

      // make a copy to avoid stacking the suffix on rerenders
      const statusTimelineCopy = structuredClone(insurance_status_timeline);
      statusTimelineCopy[eventWithLetterIndex].event = (
        <>
          {insurance_status_timeline[eventWithLetterIndex].event} {suffix}
        </>
      );

      return statusTimelineCopy;
    };

    const getTimelineEvents = (category: 'insurance' | 'pharmacy' | 'bridge' | 'pap' | 'hub') => {
      const statusTimeline =
        category === 'insurance'
          ? getInsuranceWithPALetterNote(pa_decision, status_timelines.insurance)
          : status_timelines[category];

      return statusTimeline.length > 0 ? <CollapsibleTimeline events={statusTimeline} /> : null;
    };

    const statusTimelines = [
      {
        label: 'Coverage',
        value: getTimelineEvents('insurance'),
      },
      {
        label: 'Pharmacy',
        value: getTimelineEvents('pharmacy'),
      },
      {
        label: 'HUB',
        value: getTimelineEvents('hub'),
      },
      {
        label: 'Bridge',
        value: getTimelineEvents('bridge'),
      },
      {
        label: 'PAP',
        value: getTimelineEvents('pap'),
      },
    ];

    const taskList = hideClosedTasks ? tasks.filter((task) => task.status === 'open') : tasks;
    const lastContactedPatientCopy = getLastContactedPatientCopy(last_contacted_patient);

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

          <ContentOuterWrapper>
            <CenteredContent>
              <Headline styledAs="h6">{drug.name}</Headline>
              <GeneralInfoTable
                patientName={`${patient.first_name} ${patient.last_name}`}
                dob={patient.date_of_birth}
                prescriberName={prescriber.name}
                prescribedOn={drug.prescription_recieved_at}
                // don't pass in the medication info because it's redundant
                prescriptionId={null}
                drugName={null}
                // Hide last contacted row if null
                // TODO: consider showing fallback "Not contacted yet" value instead
                extraData={
                  lastContactedPatientCopy ? [{ label: 'Last contacted', value: lastContactedPatientCopy }] : []
                }
              />
              <ExtraInfoTable data={statusTimelines} />

              {task_list_type !== 'none' && (
                <>
                  <TitleRow>
                    <LeftSide>
                      <Typography styledAs="bodyLargeSpaceGrotesk" weight={500}>
                        {getTasksHeadlineCopy(task_list_type)}
                      </Typography>
                      <ToggleButtonContainer>
                        <ToggleButton
                          onClick={() => setHideClosedTasks(false)}
                          isChecked={!hideClosedTasks}
                          ariaLabel="Show all tasks"
                        >
                          <Typography renderedAs="span" styledAs="bodyExtraSmallSpaceGrotesk">
                            All
                          </Typography>
                        </ToggleButton>
                        <ToggleButton
                          onClick={() => setHideClosedTasks(true)}
                          isChecked={hideClosedTasks}
                          ariaLabel="Only show open tasks"
                        >
                          <Typography renderedAs="span" styledAs="bodyExtraSmallSpaceGrotesk">
                            Open
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonContainer>
                    </LeftSide>

                    {(task_list_type === 'all' || task_list_type === 'tasks') && (
                      <CreateTaskButton
                        onClick={() => {
                          navigate(`new-task?patientId=${patient.id}&source=portal_rx_page`);
                        }}
                      >
                        Create task
                      </CreateTaskButton>
                    )}
                  </TitleRow>

                  <TasksContainer>
                    {!taskList.length ? (
                      <Typography styledAs="bodySmallDMSans">{getNoTasksCopy(task_list_type)}</Typography>
                    ) : null}
                    {taskList.map((task: RxTask) => {
                      const taskUrl = getTaskUrl(task.type, task.id);

                      return (
                        <Task to={taskUrl} key={`task-${task.id}`}>
                          <div>
                            <Typography styledAs="bodySmallDMSans" weight={500}>
                              {task.sub_type}
                            </Typography>
                            <Typography styledAs="bodyExtraSmallDMSans">
                              Created {moment(task.created_at).format('MM/DD/YY')}
                            </Typography>
                          </div>
                          <ChevronRight aria-hidden={true} />
                        </Task>
                      );
                    })}
                  </TasksContainer>

                  <Hairline />
                </>
              )}

              <Headline styledAs="bodyLargeSpaceGrotesk" weight={500}>
                Documents
              </Headline>
              <DocumentsTable rxId={params.rxId as string} data={documents} />
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {!isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} linkToRxPage={false} />
            <PharmacyPanel pharmacy={pharmacy} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
            <InsurancePanel insurance={insurance} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      <PageContainer noPadding={true}>{body}</PageContainer>
      <Outlet
        context={{
          reload: () => queryClient.resetQueries({ queryKey: ['rxs', params.rxId] }),
        }}
      />
    </>
  );
};

export default RxPage;
