import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PriorAuthReviewContext } from '../components/priorAuthReviews/contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PriorAuthReviewResponse, client } from '../api';
import { priorAuthReviewQueryKey, priorAuthReviewsQueryKey } from '../queries';
import PriorAuthReviewView from '../components/priorAuthReviews/PriorAuthReviewView';
import PageContainer from '../components/PageContainer';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

const PriorAuthReviewPage: React.FC = () => {
  const params = useParams();
  const priorAuthReviewId = params.priorAuthReviewId!;
  const queryClient = useQueryClient();
  const priorAuthReviewQuery = useQuery<PriorAuthReviewResponse>({
    queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
    queryFn: async () => {
      const res = await client.get(`prior-auth-reviews/${priorAuthReviewId}`);
      return res.data;
    },
  });

  // TODO: use priorAuthReviewQuery.isPending instead
  const [updating, setUpdating] = useState(false);

  let body;
  if (priorAuthReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (priorAuthReviewQuery.error) {
    body = <PageLoadError />;
  } else if (priorAuthReviewQuery.data) {
    body = (
      <PriorAuthReviewContext.Provider
        value={{
          priorAuthReviewId,
          priorAuthReviewQuery,
          updating,
          setUpdating,
          refetchPriorAuthReview: async () =>
            queryClient.invalidateQueries({
              queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
              exact: true,
            }),
          onPriorAuthReviewUpdate: async () => {
            queryClient.invalidateQueries({
              queryKey: priorAuthReviewsQueryKey(),
            });
            queryClient.invalidateQueries({
              queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
            });
            setUpdating(false);
          },
        }}
      >
        <PriorAuthReviewView />
      </PriorAuthReviewContext.Provider>
    );
  }

  return <PageContainer noPadding={true}>{body}</PageContainer>;
};

export default PriorAuthReviewPage;
