import { useId, useState, ReactNode } from 'react';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import styled from 'styled-components';
import ChevronDown from 'design-system/src/assets/icons/chevronDown.svg?react';
import { formatSimpleDate } from '../../utils';
import {
  StyledEventHeader,
  BulletPoint,
  AuthorAndTimestamp,
  EventBody,
  EventGap,
  EventTimestamp,
} from '../TimelineItem/TimelineItem';
import { ExpandingContainer } from 'design-system/src/components/ExpandingContainer/ExpandingContainer';

const ANIMATION_DURATION = 300;
const GAP_WIDTH = '4px';

const Trigger = styled.button<{ $isOpen: boolean }>`
  all: unset;
  cursor: pointer;
  padding: 0 4px;
  // push the trigger to the left so it's horizontally aligned with the other elements in the timeline
  margin-left: -4px;
  border-radius: var(--border-radius-small);
  ${standardTransitionStyles}

  svg {
    margin-left: ${GAP_WIDTH};
    transition: transform ${ANIMATION_DURATION}ms ease-out;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  ${({ $isOpen }) => {
    return (
      $isOpen &&
      `
      svg {
        transform: rotate(180deg);
      }
    `
    );
  }}

  &:hover {
    background-color: var(--light-border-gray);
  }

  ${focusVisibleStyles}
`;

const LatestEventTimestampWithDot = styled(AuthorAndTimestamp)`
  padding-left: ${GAP_WIDTH};
`;

export type CollapsibleTimelineProps = {
  events: {
    event: string | ReactNode;
    timestamp: string | null;
  }[];
};

/**
 * Timeline of events where the most recent event is always visible and the rest
 * can be toggled between collapsed and expanded.
 */
export const CollapsibleTimeline = ({ events }: CollapsibleTimelineProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const collapsibleBodyId = useId();

  if (events.length === 0) {
    return null;
  }

  const latestEvent = events[events.length - 1];
  const latestEventTimestamp = latestEvent.timestamp;
  const latestEventTimestampWithDot = latestEventTimestamp ? (
    <>
      <span aria-hidden>·</span>
      <EventTimestamp>{formatSimpleDate(latestEventTimestamp)}</EventTimestamp>
    </>
  ) : null;

  if (events.length === 1) {
    return (
      <AuthorAndTimestamp>
        <Typography styledAs="bodySmallDMSans">{latestEvent.event}</Typography>
        {latestEventTimestampWithDot}
      </AuthorAndTimestamp>
    );
  }

  return (
    <div>
      <ExpandingContainer id={collapsibleBodyId} visible={isOpen} animationDuration={ANIMATION_DURATION}>
        {/* We don't include the last event in this list because it will be rendered in the trigger instead */}
        {events.slice(0, -1).map((event) => (
          <div key={`${event.event?.toString()}-${event.timestamp}`}>
            <StyledEventHeader>
              <BulletPoint />
              <AuthorAndTimestamp>
                <Typography styledAs="bodySmallDMSans">{event.event}</Typography>
              </AuthorAndTimestamp>
            </StyledEventHeader>

            {event.timestamp && (
              <EventBody $lastEvent={false}>
                <EventTimestamp>{formatSimpleDate(event.timestamp)}</EventTimestamp>
              </EventBody>
            )}

            <EventGap $lastEvent={false} />
          </div>
        ))}
      </ExpandingContainer>

      <StyledEventHeader>
        <ExpandingContainer visible={isOpen} axis="horizontal" animationDuration={ANIMATION_DURATION}>
          <BulletPoint />
        </ExpandingContainer>
        <Trigger
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls={collapsibleBodyId}
          $isOpen={isOpen}
        >
          <StyledEventHeader>
            <Typography styledAs="bodySmallDMSans">{latestEvent.event}</Typography>
            {latestEventTimestampWithDot && (
              // When the timeline is open, the timestamp moves below the event
              <ExpandingContainer visible={!isOpen} axis="horizontal" animationDuration={ANIMATION_DURATION}>
                <LatestEventTimestampWithDot>{latestEventTimestampWithDot}</LatestEventTimestampWithDot>
              </ExpandingContainer>
            )}
            <ChevronDown title={isOpen ? 'Collapse timeline' : 'Expand timeline'} />
          </StyledEventHeader>
        </Trigger>
      </StyledEventHeader>

      {latestEventTimestamp && (
        <ExpandingContainer visible={isOpen} animationDuration={ANIMATION_DURATION}>
          <EventBody $lastEvent={true}>
            <EventTimestamp>{formatSimpleDate(latestEventTimestamp)}</EventTimestamp>
          </EventBody>
        </ExpandingContainer>
      )}

      <EventGap $lastEvent={true} />
    </div>
  );
};
