import styled from 'styled-components';
import { ExpandingContainer } from '../ExpandingContainer/ExpandingContainer';
import { Typography } from '../Typography/Typography';

const StyledErrorMessage = styled(Typography)`
  color: var(--red);
`;

export interface ErrorMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  // `true` by default
  visible?: boolean;
  // The space between the margin and the button/input/etc above it
  // This is animated in with the height of the error message
  // so it's revealed smoothly and there's no uneccessary whitespace
  // when the error message is hidden
  marginTop?: string;
}

/**
 * A component for displaying error messages.
 *
 * The message is colored red and will animate in when mounted. If you set the `visible` prop to `false` remove the error message, it will also animate out.
 */
export const ErrorMessage = ({ children, marginTop = '0.25rem', ...rest }: ErrorMessageProps) => {
  return (
    <ExpandingContainer marginTop={marginTop} {...rest}>
      <StyledErrorMessage weight={500}>{children}</StyledErrorMessage>
    </ExpandingContainer>
  );
};
