import { useQuery } from '@tanstack/react-query';
import { useState, useId } from 'react';
import styled from 'styled-components';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import { Dropdown, DropdownItem } from '../../Dropdown.tsx';
import type { DropdownChangeEvent } from '../../Dropdown.tsx';
import { FormLabel } from '../../FormLabel.tsx';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { client } from '../../../api';
import type { PaInformationRequestTask } from '../../../api';
import { formatStandardDate } from '../../../utils';

const HelperText = styled(Typography)`
  color: var(--dark-gray);
`;

type EhrStepContentsProps = {
  visitNotesDates?: string[];
  ehrSubmission: string;
  setEhrSubmission: (ehrSubmission: string) => void;
};

const EhrStepContents = ({ visitNotesDates, ehrSubmission, setEhrSubmission }: EhrStepContentsProps) => {
  const visitNotesOptions = visitNotesDates
    ? visitNotesDates.map((date: string) => ({
        label: formatStandardDate(date),
        value: date,
      }))
    : [];
  visitNotesOptions.push({
    label: 'I added a new visit note',
    value: 'new_visit_note',
  });

  const ehrDropdownLabelId = useId();

  return (
    <>
      <HelperText styledAs="bodySmallDMSans">
        Note: Evidence must be uploaded as a visit note for Tandem to access it.
      </HelperText>
      <FormLabel id={ehrDropdownLabelId}>In which visit note is the evidence uploaded?</FormLabel>
      <Dropdown
        labelId={ehrDropdownLabelId}
        value={ehrSubmission}
        onChange={(event: DropdownChangeEvent) => {
          const value = event.target.value as string;
          setEhrSubmission(value);
        }}
      >
        {visitNotesOptions.map(({ label, value }: { label: string; value: string }) => (
          <DropdownItem key={value} value={value}>
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

export type NoDocumentationAttachedWarningModalProps = {
  task: PaInformationRequestTask;
  updating: boolean;
  onSubmit: (ehrSubmission?: string) => void;
  onClose: () => void;
};

/**
 * A modal that warns the user that no documentation is attached to the pa information request task submission data.
 */
export const NoDocumentationAttachedWarningModal = ({
  task,
  updating,
  onSubmit,
  onClose,
}: NoDocumentationAttachedWarningModalProps) => {
  const [showEhrStep, setShowEhrStep] = useState(false);
  const [ehrSubmission, setEhrSubmission] = useState<string>('');

  const visitNoteDatesQuery = useQuery({
    queryKey: ['ehr-visit-note-dates', task.id],
    queryFn: () => {
      return client.get(`tasks/${task.id}/ehr-visit-note-dates`).then((res) => res.data);
    },
  });

  const practiceHasEhr = task.practice_has_ehr;
  let ehrButton = null;
  if (practiceHasEhr) {
    if (showEhrStep) {
      ehrButton = (
        <Button
          size="sm"
          variant="tertiary"
          onClick={() => {
            setEhrSubmission('');
            setShowEhrStep(false);
          }}
        >
          Back
        </Button>
      );
    } else {
      ehrButton = (
        <Button size="sm" variant="tertiary" onClick={() => setShowEhrStep(true)}>
          Uploaded in EHR
        </Button>
      );
    }
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle>{showEhrStep ? 'Evidence uploaded in the EHR' : 'No Documentation Attached'}</ModalTitle>

      <ModalContent>
        {showEhrStep ? (
          <EhrStepContents
            visitNotesDates={visitNoteDatesQuery.data?.visit_notes_dates}
            ehrSubmission={ehrSubmission}
            setEhrSubmission={setEhrSubmission}
          />
        ) : (
          <Typography styledAs="bodySmallDMSans">
            Notice: Plans typically require documentation to support responses
          </Typography>
        )}

        <ButtonRow>
          {ehrButton}

          <Button
            size="sm"
            onClick={() => onSubmit(ehrSubmission || undefined)}
            disabled={showEhrStep && !ehrSubmission}
            isLoading={updating}
          >
            {showEhrStep ? 'Submit' : 'Submit without documentation'}
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};
