import styled from 'styled-components';

export type TrainingVideoEmbedProps = {
  videoLink: string;
};

const IframeContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  max-height: 80vh;
  max-height: 80dvh;
  width: 100%;
  aspect-ratio: 1.8421052631578947;
  padding: 40px 0;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

/**
 * An iframe that displays a training video embedded in an iframe.
 */
export const TrainingVideoEmbed = ({ videoLink }: TrainingVideoEmbedProps) => {
  return (
    <IframeContainer>
      <StyledIframe
        src={videoLink}
        loading="lazy"
        title="Tandem Training for Providers"
        allow="clipboard-write"
        allowFullScreen
      />
    </IframeContainer>
  );
};

export default TrainingVideoEmbed;
