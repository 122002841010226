import { RouterLink } from 'design-system/src/components/Button/Button';
import { formatSimpleDate, formatStandardDate } from '../../utils';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import { ReactNode } from 'react';

export type GeneralInfoTableProps = {
  // First and last name
  patientName: string | null;
  // First and last name
  prescriberName: string | null;
  // Unformatted datestring
  dob: string | null;
  // Unformatted datestring
  prescribedOn: string | null;
  drugName: string | null;
  prescriptionId: string | null;
  // Anything else you want tacked on to the bottom of the table
  extraData?: Array<{ label: string; value: string | null | ReactNode }>;
};

/**
 * Table with patient and prescriber info for top of detail pages (rx page, task page, review pages)
 */
export const GeneralInfoTable = ({
  patientName,
  prescriberName,
  dob,
  prescribedOn,
  drugName,
  prescriptionId,
  extraData = [],
}: GeneralInfoTableProps) => {
  let patientValue = patientName;
  if (dob) {
    patientValue += ` (${formatStandardDate(dob)})`;
  }
  let prescriberValue = prescriberName;
  if (prescribedOn) {
    prescriberValue += ` on ${formatSimpleDate(prescribedOn)}`;
  }

  const generalData = [];
  if (drugName && prescriptionId) {
    generalData.push({
      label: 'Drug',
      value: <RouterLink to={`/rxs/${prescriptionId}`}>{drugName}</RouterLink>,
    });
  }
  generalData.push({ label: 'Patient', value: patientValue });
  generalData.push({ label: 'Prescribed by', value: prescriberValue });
  const withExtraData = [...generalData, ...extraData];

  return <ExtraInfoTable data={withExtraData} />;
};
