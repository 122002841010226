import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/logo.svg?react';
import { AuthContext } from '../auth';
import { useStytchB2BClient } from '@stytch/react/b2b';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { client } from '../api';
import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuHeading,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from './PopoverMenu';
import Avatar from '../assets/avatar.svg?react';
import PieChart from '../assets/pieChart.svg?react';
import PieChartInverted from '../assets/pieChartInverted.svg?react';
import Tasks from '../assets/tasks.svg?react';
import TasksInverted from '../assets/tasksInverted.svg?react';
import CheckingBox from 'design-system/src/assets/icons/checkingBox.svg?react';
import CheckingBoxInverted from 'design-system/src/assets/icons/checkingBoxInverted.svg?react';
import Drug from 'design-system/src/assets/icons/drug.svg?react';
import DrugInverted from 'design-system/src/assets/icons/drugInverted.svg?react';
import Alarm from 'design-system/src/assets/icons/alarm.svg?react';
import AlarmInverted from 'design-system/src/assets/icons/alarmInverted.svg?react';
import LightBulb from 'design-system/src/assets/icons/lightBulb.svg?react';
import LightBulbFilled from 'design-system/src/assets/icons/lightBulbFilled.svg?react';
import Documentation from 'design-system/src/assets/icons/documentation.svg?react';
import ExpandWindow from 'design-system/src/assets/icons/expandWindow.svg?react';
import Warning from 'design-system/src/assets/icons/warning.svg?react';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';

const buttonStyles = `
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--black);
  background-color: transparent;
  border-radius: var(--border-radius-medium);
  border: none;

  transition: background 0.2s ease-out, box-shadow 0.2s ease-out;

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px var(--white), 0px 0px 0px 3px var(--purple);
    // for Windows High Contrast Mode, which removes box shadows but makes all borders
    // 100% opacity black
    outline: 1px solid rgba(0,0,0,0);
  }
`;

const navButtonStyles = `
  ${buttonStyles}

  // TODO(design-system): use body medium typography styles
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.009375rem;

  &:hover {
    background: var(--cream2);
  }
  &:active, &:focus-visible {
    background: var(--cream3);
  }
`;

const StyledNavLink = styled(NavLink)`
  ${navButtonStyles}

  text-decoration: none;
  display: block;
`;

const StyledLink = styled.a`
  ${navButtonStyles}

  text-decoration: none;
  display: block;
`;

const AccountButton = styled(PopoverMenuButton)`
  ${navButtonStyles}

  padding: 0.75rem;
`;

const NavSidebarContainer = styled.div<{ $bannersHeight: number }>`
  // TODO: move to core css file, apply to everything
  box-sizing: border-box;

  width: var(--nav-sidebar-width);
  position: fixed;
  height: calc(100vh - ${({ $bannersHeight }) => $bannersHeight}px);
  height: calc(100dvh - ${({ $bannersHeight }) => $bannersHeight}px); // iOS safari
  top: ${({ $bannersHeight }) => $bannersHeight}px;
  // z-index lifts the sidebar above the main page content
  z-index: 2;

  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  background: var(--light-surface);
  border-right: 1px solid var(--border-gray);
`;

const LogoLink = styled.a`
  // TODO: move to core css file, apply to everything
  box-sizing: border-box;
  height: 3rem;
  padding: 0.875rem 1rem;
  // for the focus outline
  border-radius: var(--border-radius-medium);
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavItemContainer = styled.div<{ $isActive: boolean }>`
  color: var(--dark-purple);

  padding: 0.75rem;
  display: flex;
  gap: 0.75rem;
  border-radius: var(--border-radius-medium);
  ${({ $isActive }) => ($isActive ? 'background-color: var(--cream3)' : '')}
`;

const UserGuideText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NavItemIcon = styled.div`
  display: flex;
  align-items: center;
  // Most of the icons are 16px wide, but one of them is 18px, so we'll use that
  // for consistency
  width: 18px;
`;

type NavItemProps = {
  icon: React.ReactNode;
  // The version of the icon that appears when this item is active
  // If not provided, the regular icon will be used
  activeIcon?: React.ReactNode;
  text: string | React.ReactNode;
  isActive?: boolean;
};

const NavItem = ({ icon, activeIcon, text, isActive = false }: NavItemProps) => {
  return (
    <NavItemContainer $isActive={isActive}>
      <NavItemIcon>{isActive && activeIcon ? activeIcon : icon}</NavItemIcon>
      <div>{text}</div>
    </NavItemContainer>
  );
};

const AccountMenu = ({ email, hasSettingsPage }: { email?: string; hasSettingsPage?: boolean }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useMutation({
    mutationFn: () => {
      return client.post('logout');
    },
    onSuccess: async () => {
      stytch.session.revoke();
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate('/login');
    },
  });

  const stytch = useStytchB2BClient();

  return (
    <PopoverMenu>
      <AccountButton>
        <Avatar aria-hidden={true} /> Account
      </AccountButton>

      <PopoverMenuItems>
        <PopoverMenuSection>
          {email && <PopoverMenuHeading>{email}</PopoverMenuHeading>}

          <PopoverMenuItem>
            <PopoverMenuItemButton onClick={() => window.open('/tos-provider', '_blank')?.focus()}>
              Terms of service <span className="sr-only">opens in a new tab</span>
            </PopoverMenuItemButton>
          </PopoverMenuItem>
          {hasSettingsPage && (
            <PopoverMenuItem>
              <PopoverMenuItemButton onClick={() => navigate('/settings')}>Settings</PopoverMenuItemButton>
            </PopoverMenuItem>
          )}
          <PopoverMenuItem>
            <PopoverMenuItemButton onClick={() => logout.mutate()}>Sign out</PopoverMenuItemButton>
          </PopoverMenuItem>
        </PopoverMenuSection>
      </PopoverMenuItems>
    </PopoverMenu>
  );
};

export const NavSidebar = ({ bannersHeight }: { bannersHeight: number }) => {
  const authContext = React.useContext(AuthContext);

  // this is necessary for the detail pages to show the correct tab as active
  const isPrescriptionTab = window.location.pathname.includes('/rxs');
  const isTasksTab = window.location.pathname.includes('/tasks');
  const isPriorAuthReviewsTab = window.location.pathname.includes('/prior-auth-reviews');
  const isAppealReviewsTab = window.location.pathname.includes('/appeal-reviews');

  return (
    <NavSidebarContainer $bannersHeight={bannersHeight}>
      {/* Use anchor tag to force a full page reload in case the auth status has changed */}
      <LogoLink href="/">
        <Logo title="Home" />
      </LogoLink>
      {authContext.auth && (
        <NavItems>
          <Tabs>
            {authContext.user?.tabs.includes('prescriptions') && (
              <StyledNavLink to="/prescriptions">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive || isPrescriptionTab}
                    icon={<Drug aria-hidden={true} />}
                    activeIcon={<DrugInverted aria-hidden={true} />}
                    text="Prescriptions"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('fax_verification') && (
              <StyledNavLink to="/verify-fax">
                {({ isActive }) => (
                  <NavItem isActive={isActive} icon={<Warning aria-hidden={true} />} text="Fax Verification" />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('tasks') && (
              <StyledNavLink to="/tasks">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive || isTasksTab}
                    icon={<Tasks aria-hidden={true} />}
                    activeIcon={<TasksInverted aria-hidden={true} />}
                    text="Tasks"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('pa_reviews') && (
              <StyledNavLink to="/prior-auth-reviews">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive || isPriorAuthReviewsTab}
                    icon={<CheckingBox aria-hidden={true} />}
                    activeIcon={<CheckingBoxInverted aria-hidden={true} />}
                    text="PA Reviews"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('appeal_reviews') && (
              <StyledNavLink to="/appeal-reviews">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive || isAppealReviewsTab}
                    icon={<CheckingBox aria-hidden={true} />}
                    activeIcon={<CheckingBoxInverted aria-hidden={true} />}
                    text="Appeal Reviews"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('prior_auths') && (
              <StyledNavLink to="/expiring-prior-auths">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<Alarm aria-hidden={true} />}
                    activeIcon={<AlarmInverted aria-hidden={true} />}
                    text="Expiring PAs"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('dashboard') && (
              <StyledNavLink to="/analytics">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<PieChart aria-hidden={true} />}
                    activeIcon={<PieChartInverted aria-hidden={true} />}
                    text="Analytics"
                  />
                )}
              </StyledNavLink>
            )}
            {/* we use the practice_training_video_link to determine which tutorial to show.
            if it's null, it's better to not show the tutorial tab at all */}
            {!!authContext.user?.practice_training_video_link && (
              <StyledNavLink to="/tutorial">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<LightBulb aria-hidden={true} />}
                    activeIcon={<LightBulbFilled aria-hidden={true} />}
                    text="Tutorial"
                  />
                )}
              </StyledNavLink>
            )}
            <StyledLink href="https://withtandem.com/manual" target="_blank" rel="noopener noreferrer">
              <NavItem
                icon={<Documentation aria-hidden={true} />}
                text={
                  <UserGuideText>
                    User Manual
                    <ExpandWindow title="opens in a new tab" />
                  </UserGuideText>
                }
              />
            </StyledLink>
          </Tabs>

          {authContext.auth && (
            <AccountMenu email={authContext.user?.email} hasSettingsPage={authContext.user?.has_settings_page} />
          )}
        </NavItems>
      )}
    </NavSidebarContainer>
  );
};
