import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
// TODO: swap in design system components once we have them
import { Switch } from '@mui/material';
import { Checkbox } from 'design-system/src/components/Checkbox/Checkbox';
import Button from 'design-system/src/components/Button/Button';
import { client, NotificationData, NotificationUpdateData, ErrorResponse } from '../../api';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { Table, TableCell, TableContainer } from '../Table';
import { SearchField } from '../Input';

const SubHeading = styled.div`
  margin: 1rem 0;
`;

const StyledSearchField = styled(SearchField)`
  width: 300px;
  margin: 4px 0;
`;

const HelperText = styled(Typography)`
  font-style: italic;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
`;

const CustomTableContainer = styled(TableContainer)`
  margin-bottom: 1rem;
  // Allow table to take natural width but enable scrolling if needed
  width: fit-content;
  // Screen width - sidebar width - body padding
  max-width: calc(100vw - var(--nav-sidebar-width) - 2.5rem);
  // Add fixed height to enable vertical scrolling
  max-height: 36rem;
  overflow-x: auto;
  overflow-y: auto;

  // Add position relative for sticky positioning context
  position: relative;

  // Style for sticky header
  thead tr {
    position: sticky;
    top: 0;
    background: var(--extra-light-surface);
    z-index: 2;
  }
  // Style for sticky first column
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    background: var(--extra-light-surface);
    z-index: 1;
  }
  // Handle corner cell (intersection of sticky header and column)
  thead tr th:first-child {
    z-index: 3;
  }
`;

const CustomTableCell = styled(TableCell)`
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  min-width: 5rem;
`;

const switchStyling = {
  color: `var(--disabled-gray)`,

  // Below selectors override MUI default styles
  // target Checkbox styles
  '&.Mui-checked': {
    color: `var(--purple)`,
  },
  // target Switch styles
  // note: using !important as a quick solution,
  // for some reason Switch selectors are more specific
  '.Mui-checked': {
    color: `var(--purple) !important`,
  },
  '.Mui-checked+.MuiSwitch-track': {
    backgroundColor: `var(--purple) !important`,
  },
};

/**
 * TODO: currently this component is an admin view
 * where you can set notification preferences for all users
 * in an organization.
 *
 * Instead, we should introduce the concept of an Admin role
 * -- for non-Admin users, they'll only be able to
 * edit their own notification preferences.
 */
const NotificationSettings: React.FC = () => {
  const { data, isLoading, error } = useQuery<NotificationData>({
    queryKey: ['notification-settings'],
    queryFn: () => {
      return client.get(`notification/settings`).then((res) => res.data);
    },
  });
  const [memberSettings, setMemberSettings] = useState<NotificationUpdateData>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Also store memberSettings in local state so we can use conditional logic in the table
  useEffect(() => {
    if (data) {
      const settings: NotificationUpdateData = {};
      data.members.forEach((member) => {
        settings[member.id] = {
          email_address: member.email_address,
          notifications: member.trusted_metadata.notifications,
          npis: member.trusted_metadata.npis,
        };
      });
      setMemberSettings(settings);
    }
  }, [data]);

  const filteredMembers = useMemo(() => {
    return Object.entries(memberSettings).filter(([_, settings]) => {
      return searchQuery ? settings.email_address?.toLowerCase().includes(searchQuery.toLowerCase()) : true;
    });
  }, [memberSettings, searchQuery]);

  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const queryClient = useQueryClient();

  const submit = useMutation<{}, AxiosError<ErrorResponse>, NotificationUpdateData>({
    mutationFn: async (data) => {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      if (isTrainingMode) {
        console.warn('Training Mode: Mocking save notification settings');
        queryClient.setQueryData(['notificationSettings'], data);
        return Promise.resolve({ success: true });
      }
      // TODO: it'd be nice to check for changes from the existing state
      // and only send the diff
      return client.post(`notification/settings/save`, data).then((res) => res.data);
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Settings saved</Toast>);
    },
    onError: () => {
      toast.custom(() => <Toast variant="error">Error saving settings</Toast>);
    },
    onSettled: () => {
      setIsSubmitting(false);
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (error) {
    return <PageLoadError />;
  } else if (!data) {
    return null;
  }
  const allNPIs = data.providers.map((provider) => provider.npi);
  return (
    <>
      <SubHeading>
        <Typography renderedAs="h2" styledAs="h6">
          Notification preferences
        </Typography>
      </SubHeading>
      <Typography>Turn on providers to receive email notifications for.</Typography>
      <StyledSearchField
        // TODO: if we add names, update this copy and the filter logic to use them
        label="Search by email"
        placeholder="Search by email"
        hideLabel
        value={searchQuery}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
      />
      <CustomTableContainer>
        <Table>
          <thead>
            <tr>
              <CustomTableCell>Account</CustomTableCell>
              <CustomTableCell>Enable email notifications</CustomTableCell>
              <CustomTableCell>Subscribe to all providers</CustomTableCell>
              {data.providers.map((provider) => (
                <CustomTableCell key={provider.npi}>
                  <div>{provider.name}</div>
                  <Typography styledAs="bodySmallDMSans">NPI: {provider.npi}</Typography>
                </CustomTableCell>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map(([id, settings]) => {
              const { email_address, notifications: notificationsEnabled, npis } = settings;

              return (
                <tr key={id}>
                  <CustomTableCell>{email_address}</CustomTableCell>
                  {/* Enable notifications */}
                  <CustomTableCell>
                    <Switch
                      sx={switchStyling}
                      checked={!!notificationsEnabled}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberSettings({
                          ...memberSettings,
                          [id]: {
                            ...settings,
                            notifications: e.target.checked,
                          },
                        });
                      }}
                    />
                  </CustomTableCell>
                  {/* Toggle subscribing to all or no providers */}
                  <CustomTableCell>
                    <Checkbox
                      disabled={!notificationsEnabled}
                      checked={!!notificationsEnabled && (!npis || npis.length === allNPIs.length)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberSettings({
                          ...memberSettings,
                          [id]: {
                            ...settings,
                            npis: e.target.checked ? allNPIs : [],
                          },
                        });
                      }}
                    />
                  </CustomTableCell>
                  {/* Subscribe to individual providers */}
                  {data.providers.map((provider) => (
                    <CustomTableCell key={provider.npi}>
                      <Checkbox
                        disabled={!notificationsEnabled}
                        checked={!!notificationsEnabled && (!npis || npis.includes(provider.npi))}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setMemberSettings({
                            ...memberSettings,
                            [id]: {
                              ...settings,
                              npis: e.target.checked
                                ? [...(npis || []), provider.npi]
                                : (npis || []).filter((npi) => npi !== provider.npi),
                            },
                          });
                        }}
                      />
                    </CustomTableCell>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CustomTableContainer>
      <Button size="lg" width="full" onClick={() => submit.mutate(memberSettings)} isLoading={isSubmitting}>
        Save settings
      </Button>
      <HelperText>Please allow up to 24 hours for changes to take effect.</HelperText>
    </>
  );
};

export default NotificationSettings;
