import styled, { css } from 'styled-components';
import { Typography } from '../Typography/Typography';

const neutralBadgeStyles = css`
  color: var(--black);
  background: var(--surface);
`;

const successBadgeStyles = css`
  color: var(--dark-green);
  // TODO: add this to the design system?
  background: #c7eac7;
`;

const warningBadgeStyles = css`
  color: var(--dark-yellow);
  background: var(--light-yellow);
`;

const errorBadgeStyles = css`
  color: var(--dark-red);
  background: var(--light-red);
`;

const badgeVariants = ['neutral', 'success', 'warning', 'error'] as const;
export type BadgeVariant = (typeof badgeVariants)[number];

const badgeVariantStyles = {
  neutral: neutralBadgeStyles,
  success: successBadgeStyles,
  warning: warningBadgeStyles,
  error: errorBadgeStyles,
};

const StyledBadge = styled.div<{ $variant: BadgeVariant }>`
  width: fit-content;
  padding: 4px 8px;
  border-radius: var(--border-radius-large);
  // transparent border so the shape is visible on windows high contrast mode,
  // which removes decorative styles like background color but makes all borders
  // 100% opacity black
  border: 1px solid rgba(0, 0, 0, 0);

  ${({ $variant }) => badgeVariantStyles[$variant]}
`;

export type BadgeProps = {
  variant: BadgeVariant;
  children: React.ReactNode;
};

/**
 * A pill-shaped badge for displaying a status or a tag.
 */
export const Badge = ({ variant, children }: BadgeProps) => {
  return (
    <StyledBadge $variant={variant}>
      <Typography styledAs="bodySmallSpaceGrotesk">{children}</Typography>
    </StyledBadge>
  );
};

export default Badge;
