import { Modal, ModalContent, ModalTitle } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import { useQueryClient } from '@tanstack/react-query';
import { client, Drug, Patient } from '../../api';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import { useNavigate } from 'react-router-dom';
import { StandardErrorToast, Toast } from 'design-system/src/components/Toast/Toast';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { tasksQueryKey } from '../../queries';

export type AdditionalInput = {
  needs_draft_review?: boolean;
};

type SubmitAppealModalProps = {
  closeModal: () => void;
  patient: Patient;
  drug: Drug;
  taskId: string;
  includeEnrollmentNotice: boolean;
};

const SubmitAppealModal = ({ closeModal, patient, drug, taskId, includeEnrollmentNotice }: SubmitAppealModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const extraInfoData = [
    { label: 'Patient', value: `${patient?.first_name} ${patient?.last_name}` },
    { label: 'Drug', value: drug?.name },
  ];

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleTaskResponse = async () => {
    try {
      const formData = new FormData();
      formData.append('task_response', 'approve_and_send');
      setIsSubmitting(true);
      await client.post(`tasks/${taskId}/respond`, formData);
      toast.custom(() => <Toast variant="success">Appeal letter approved.</Toast>);
      navigate('/tasks');
      queryClient.invalidateQueries({
        queryKey: tasksQueryKey(),
      });
    } catch (error) {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  let modalCopy;
  if (includeEnrollmentNotice) {
    modalCopy = `Tandem will obtain patient consent (if required), submit the appeal letter to the patient's plan, and start the enrollment process for the Bridge or PAP.`;
  } else {
    modalCopy = `Tandem will obtain patient consent (if required) and submit the appeal letter to the patient's plan.`;
  }

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>Submit appeal</ModalTitle>
      <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="80px" />
      <ModalContent>
        <Typography>{modalCopy}</Typography>
        <ButtonRow align={Alignment.SpaceBetween}>
          <Button variant="tertiary" onClick={closeModal}>
            Back
          </Button>
          <Button onClick={handleTaskResponse} isLoading={isSubmitting}>
            Submit
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

export default SubmitAppealModal;
