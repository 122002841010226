import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table';

import { noResultsCheckFiltersCopy } from 'design-system/src/sharedCopy';
import type { TaskSummary } from '../../api';
import { drugCategoryDropdownOptions } from '../../constants';
import {
  sortOfficeNames,
  stringsToDropdownOptions,
  truncateLongText,
  getInitialMultiselectFilterValueFromCookie,
  getInitialTableColumnFilterValueFromCookie,
  updateTableColumnFilterValueCookie,
} from '../../utils';
import { TableMultiselectFilter } from '../TableDropdownFilter/TableDropdownFilter';
import type { MultiselectAutocompleteOption } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import { AuthContext } from '../../auth';
import TruncatedText from 'design-system/src/components/TruncatedText/TruncatedText';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  ActionButton,
} from '../Table';

const columnHelper = createColumnHelper<TaskSummary>();

interface TaskTableProps {
  data: TaskSummary[];
}

const taskDateSortingFn = (a: Row<TaskSummary>, b: Row<TaskSummary>, columnId: string) => {
  return new Date(b.getValue(columnId)).getTime() - new Date(a.getValue(columnId)).getTime();
};

const taskTableMultiSelectFilter = (row: Row<TaskSummary>, columnId: string, filterValue: string[]) => {
  if (!filterValue.length) {
    // No filters are applied; return all rows
    return true;
  }

  // @ts-expect-error columnId will be one of the column heading keys
  return filterValue.includes(row.original[columnId]);
};

// drug info columns need a special filter function because they're in a nested
// object
const drugInfoMultiSelectFilter = (
  row: Row<TaskSummary>,
  columnId: 'prescriber_name' | 'drug_category',
  filterValue: string[],
) => {
  if (!filterValue.length) {
    // No filters are applied; return all rows
    return true;
  }

  return filterValue.includes(row.original.drug?.[columnId] || '');
};

const TasksTable: React.FC<TaskTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const authContext = React.useContext(AuthContext);
  const userEmail = authContext.user?.email;

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    { id: 'status', value: searchParams.get('status') || 'open' },
    {
      id: 'office_name',
      value: getInitialTableColumnFilterValueFromCookie('tasks', 'office_name', userEmail),
    },
    {
      id: 'prescriber_name',
      value: getInitialTableColumnFilterValueFromCookie('tasks', 'prescriber_name', userEmail),
    },
  ]);

  const typeStrings: string[] = [];
  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((task) => {
    if (task.task_type) {
      typeStrings.push(task.task_type);
    }
    if (task.drug && task.drug.prescriber_name) {
      prescriberStrings.push(task.drug.prescriber_name);
    }
    if (task.office_name) {
      officeNameStrings.push(task.office_name);
    }
  });
  // TODO: should we instead pass sortOfficeNames into the TableMultiselectFilter?
  const sortedOfficeNameStrings = officeNameStrings.sort(sortOfficeNames);

  const columns = [
    columnHelper.accessor('last_comment_created_at', {
      header: 'Last commented',
      sortingFn: taskDateSortingFn,
    }),
    columnHelper.accessor('created_by', {
      header: 'Created by',
      cell: (info) => truncateLongText(info.getValue(), 12, 0),
    }),
    columnHelper.accessor('task_type', {
      header: 'Type',
      filterFn: taskTableMultiSelectFilter,
    }),
    columnHelper.accessor((row) => row.drug?.prescriber_name || '', {
      id: 'prescriber_name',
      header: 'Prescriber',
      filterFn: (row, _, filterValue) => drugInfoMultiSelectFilter(row, 'prescriber_name', filterValue),
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
    }),
    columnHelper.accessor('patient_dob', {
      header: 'DOB',
      sortingFn: taskDateSortingFn,
    }),
    columnHelper.accessor((row) => row.drug?.drug_name || '', {
      id: 'drug_name',
      header: 'Drug',
    }),
    columnHelper.accessor('drug.prescription_recieved_at', {
      header: 'Rx date',
      sortingFn: taskDateSortingFn,
    }),
    columnHelper.accessor('last_comment_message', {
      header: 'Last comment',
      cell: (info) => <TruncatedText>{info.getValue()}</TruncatedText>,
    }),

    // these columns are only used for filtering, not displaying
    columnHelper.accessor('status', {
      header: 'Status',
      filterFn: 'equalsString',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((row) => row.drug?.drug_category || '', {
      id: 'drug_category',
      header: 'Drug category',
      filterFn: (row, _, filterValue) => drugInfoMultiSelectFilter(row, 'drug_category', filterValue),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
      filterFn: taskTableMultiSelectFilter,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    // these columns are only used for filtering, not displaying
    initialState: { columnVisibility: { status: false, drug_category: false, office_name: false } },
  });

  const setStatus = React.useCallback(
    (status: string) => {
      setSearchParams({ status });
      table.getColumn('status')!.setFilterValue(status);
    },
    [setSearchParams, table],
  );

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <FilterGroup>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'open'}
              onClick={() => setStatus('open')}
            >
              Requires response
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'sent'}
              onClick={() => setStatus('sent')}
            >
              Awaiting Tandem
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'completed'}
              onClick={() => setStatus('completed')}
            >
              Closed
            </FilterLabel>
          </FilterGroup>

          <SearchInput
            label="Search by name"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
          />

          <ActionButton onClick={() => navigate('new?source=portal_task_page')}>Create task</ActionButton>
        </FilterBarRow>

        <FilterBarRow>
          <TableMultiselectFilter
            options={stringsToDropdownOptions(sortedOfficeNameStrings)}
            label={'Office'}
            initialValue={getInitialMultiselectFilterValueFromCookie('tasks', 'office_name', userEmail)}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('office_name')!.setFilterValue(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'tasks', 'office_name', userEmail);
            }}
          />
          <TableMultiselectFilter
            options={stringsToDropdownOptions(prescriberStrings)}
            label={'Prescriber'}
            initialValue={getInitialMultiselectFilterValueFromCookie('tasks', 'prescriber_name', userEmail)}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('prescriber_name')!.setFilterValue(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'tasks', 'prescriber_name', userEmail);
            }}
          />
          <TableMultiselectFilter
            options={stringsToDropdownOptions(typeStrings)}
            label={'Type'}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('task_type')!.setFilterValue(selectedValuesList);
            }}
          />
          <TableMultiselectFilter
            options={drugCategoryDropdownOptions}
            label={'Drug category'}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('drug_category')!.setFilterValue(selectedValuesList);
            }}
          />
        </FilterBarRow>
      </FilterBar>

      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === taskId}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    navigate(`/rxs/${row.original.id}`);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>{noResultsCheckFiltersCopy}</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default TasksTable;
