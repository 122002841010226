import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

type StyleProps = {
  $inDOM: boolean;
  $animatedIn: boolean;
  $marginTop: string | 0;
  $axis: 'vertical' | 'horizontal';
  $animationDuration: number;
};

const StyledExpandingContainer = styled.div<StyleProps>`
  // use display: none when container is visually hidden so
  // it's hidden from screen readers too
  display: ${({ $inDOM }) => ($inDOM ? 'grid' : 'none')};

  // We're using CSS grid in order to smoothly animate the height of the container
  // Other methods for animating height require knowing the approximate height of
  // the contents or leave empty space where the element would be rendered
  grid-template-${({ $axis }) => ($axis === 'vertical' ? 'rows' : 'columns')}: ${({ $animatedIn }) =>
    $animatedIn ? '1fr' : '0fr'};

  margin-top: ${({ $animatedIn, $marginTop }) => ($animatedIn ? $marginTop : 0)};

  transition:
    grid-template-rows ${({ $animationDuration }) => $animationDuration}ms ease-out,
    grid-template-columns ${({ $animationDuration }) => $animationDuration}ms ease-out,
    margin-top ${({ $animationDuration }) => $animationDuration}ms ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  // This is necessary for the height to shrink when the element is hidden
  > div {
    overflow: hidden;
  }
`;

export interface ExpandingContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  // `true` by default
  visible?: boolean;
  // The space between the margin and the button/input/etc above it
  // This is animated in with the height of the error message
  // so it's revealed smoothly and there's no uneccessary whitespace
  // when the error message is hidden
  marginTop?: string | 0;
  // Whether the container expands and contracts vertically or horizontally
  axis?: 'vertical' | 'horizontal';
  // The duration of the animation in milliseconds
  animationDuration?: number;
}

/**
 * A container that expands to reveal contents and collapses to hide them.
 *
 * Does not contain the mechanism for showing/hiding; this is just the animating
 * container.
 */
export const ExpandingContainer = ({
  children,
  visible = true,
  marginTop = 0,
  axis = 'vertical',
  animationDuration = 200,
  ...rest
}: ExpandingContainerProps) => {
  // We have prop and state visible variables to account for animation.
  // This is because we cannot animate from a `display: block` state,
  // in which an element is not in the DOM, to a different `display` state.
  // When the `visible` prop is set to `true`, we place the element in the DOM
  // and *then* animate it in. When the `visible` prop is set to `false`, we animate
  // the element out and *then* remove it from the DOM.
  const [inDOM, setInDOM] = useState(false);
  const [animatedIn, setAnimatedIn] = useState(false);

  useEffect(() => {
    if (visible && !inDOM) {
      setInDOM(true);
      // Quick pause so the element is in the DOM before triggering the animation
      setTimeout(() => {
        setAnimatedIn(true);
      }, 10);
    }
    if (!visible && animatedIn) {
      setAnimatedIn(false);
      setTimeout(() => {
        setInDOM(false);
      }, animationDuration);
    }
  }, [visible]);

  return (
    <StyledExpandingContainer
      $inDOM={inDOM}
      $animatedIn={animatedIn}
      $marginTop={marginTop}
      $axis={axis}
      $animationDuration={animationDuration}
      aria-live="polite"
      {...rest}
    >
      {/* This is necessary for the height to shrink when the element is hidden */}
      <div>{children}</div>
    </StyledExpandingContainer>
  );
};
