import { TaskType } from '../../api';
import React from 'react';
import { Typography } from 'design-system/src/components/Typography/Typography';

export const PADenialInstructions = () => (
  <Typography weight="bold">Please review and select from the following options.</Typography>
);

export const AppealReviewInstructions = () => (
  <Typography weight="bold">Please review the draft appeal letter and select an option below.</Typography>
);

export const PADenialNextStepsAndAppealReviewInstructions = () => (
  <Typography weight="bold">Please review the generated appeal letter and select an option below.</Typography>
);

export const TASK_SUBTYPE_TO_INSTRUCTIONS: Partial<Record<TaskType, React.ReactNode>> = {
  'PA denied - Select next step': <PADenialInstructions />,
  'Appeal drafted - Ready for review': <AppealReviewInstructions />,
  'PA denied - Ready to appeal': <PADenialNextStepsAndAppealReviewInstructions />,
};
