export const pageLevelErrorCopy = 'Something went wrong. Please try again later.';

export const shortErrorCopy = 'An error occurred';

export const noResultsCopy = 'No results.';

export const noResultsCheckFiltersCopy = 'No results found. Please double-check your filters and try again.';

export const emptyTableCellCopy = '-';

export const loginLinkExpiredCopy =
  'This login link has expired or already been used. Please refresh the page and restart the login process.';
