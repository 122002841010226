import { Modal, ModalContent, ModalTitle } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import styled from 'styled-components';
import { UseMutationResult } from '@tanstack/react-query';
import { Drug, NewCommentFile, Patient } from '../../api';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';
import { Checkbox } from 'design-system/src/components/Checkbox/Checkbox';
import CommentAndFileSection from './CommentAndFileSection';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { useId } from 'react';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--light-border-gray);
  border-radius: var(--border-radius-small);
  margin-bottom: 1.5rem;
`;

const CheckboxLabel = styled(Typography)`
  cursor: pointer;
`;

export type AdditionalInput = {
  needs_draft_review?: boolean;
};

type AppealRequestModal = {
  closeModal: () => void;
  initiate: UseMutationResult<void, Error, void>;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  patient: Patient;
  drug: Drug;
  files: NewCommentFile[];
  onFilesChange: (files: NewCommentFile[]) => void;
  setAdditionalInput: (additionalInput: AdditionalInput) => void;
  additionalInput: AdditionalInput;
  inputValue: string;
  setInputValue: (value: string) => void;
  appealRequestModalType: AppealRequestModalType;
};

export type AppealRequestModalType = 'initiate_with_next_steps' | 'revise_with_next_steps' | 'revise_only';

const AppealRequestModal = ({
  closeModal,
  patient,
  drug,
  initiate,
  inputRef,
  files,
  onFilesChange,
  setAdditionalInput,
  additionalInput,
  inputValue,
  setInputValue,
  appealRequestModalType,
}: AppealRequestModal) => {
  const extraInfoData = [
    { label: 'Patient', value: `${patient?.first_name} ${patient?.last_name}` },
    { label: 'Drug', value: drug?.name },
  ];

  const id = useId();

  const title = appealRequestModalType === 'initiate_with_next_steps' ? 'Initiate appeal' : 'Revise appeal';

  let instructions = '';
  switch (appealRequestModalType) {
    case 'initiate_with_next_steps':
      instructions = `Tandem will generate an appeal letter based on the patient’s records and all available information regarding
          the denial. Tandem will also start the enrollment process for the Bridge or PAP, if available.`;
      break;
    case 'revise_with_next_steps':
      instructions = `Tandem will generate a revised appeal letter and start the enrollment process for the Bridge or PAP, if available.`;
      break;
    case 'revise_only':
      // No top-level instructions needed
      break;
  }

  const isFirstDraft = appealRequestModalType === 'initiate_with_next_steps';

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>{title}</ModalTitle>
      <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="80px" />
      <ModalContent>
        <Typography>{instructions}</Typography>
        <Typography>
          {isFirstDraft && <strong>Optional: </strong>}Provide additional context, updated documentation, or specific
          edits to incorporate into the appeal {isFirstDraft ? 'letter' : 'revision'}.
        </Typography>
        <CommentAndFileSection
          onSubmit={() => initiate.mutate()}
          isSubmitting={initiate.isPending}
          inputRef={inputRef}
          placeholder="Add additional information..."
          hideSubmitButton
          files={files}
          onFilesChange={onFilesChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </ModalContent>
      <CheckboxContainer>
        <Checkbox
          checked={additionalInput.needs_draft_review}
          onChange={(e) => setAdditionalInput({ ...additionalInput, needs_draft_review: e.target.checked })}
          id={`draft-review-${id}`}
        />
        <CheckboxLabel as="label" htmlFor={`draft-review-${id}`}>
          I want to review the next draft of this appeal letter before submitting.
        </CheckboxLabel>
      </CheckboxContainer>

      <ButtonRow>
        <Button variant="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={() => initiate.mutate()} isLoading={initiate.isPending}>
          Submit
        </Button>
      </ButtonRow>
    </Modal>
  );
};

export default AppealRequestModal;
