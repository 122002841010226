import styled from 'styled-components';
import { BODY_MAX_WIDTH } from '../constants';
import CloseModal from '../assets/icons/closeModal.svg?react';
import { FlexColumn } from './Layout';
import { Typography } from './Typography/Typography';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { focusVisibleStyles, standardTransitionStyles } from '../sharedStyles';

const modalBorderRadius = `var(--border-radius-small)`;

const ModalBackdrop = styled(DialogBackdrop)<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-overlay-backdrop);
  z-index: 1000;
`;

const ContentContainer = styled(DialogPanel)<{ $open: boolean; $fullHeight: boolean; $wide: boolean }>`
  position: fixed;
  z-index: 1000;
  background-color: var(--white);
  height: ${({ $fullHeight }) => ($fullHeight ? '70%' : 'auto')};

  @media (max-width: ${BODY_MAX_WIDTH}) {
    // TODO: check with design if this is still the case
    // on mobile, we want the modal to be a full width bottom sheet
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    max-height: ${({ $open }) => ($open ? '100vh' : '0')};
    max-height: ${({ $open }) => ($open ? '100dvh' : '0')}; // iOS safari
    transition: max-height 0.3s ease-out;
    border-radius: ${modalBorderRadius} ${modalBorderRadius} 0 0;
  }

  @media (min-width: ${BODY_MAX_WIDTH}) {
    // on desktop, we want the modal to be a fixed width centered on the page
    top: 64px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100vh;
    max-width: ${({ $wide }) => ($wide ? '60%' : BODY_MAX_WIDTH)};
    width: 100%;
    padding: 2rem;
    border-radius: ${modalBorderRadius};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  text-align: center;
  color: var(--black);
  ${standardTransitionStyles}
  // for the focus outline
  border-radius: var(--border-radius-medium);

  ${focusVisibleStyles}
`;

const ModalFlexColumn = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`;

export const ModalTitle = styled(Typography).attrs(({ styledAs, renderedAs }) => ({
  styledAs: styledAs || 'h5',
  renderedAs: renderedAs || 'h1',
}))`
  margin-bottom: 16px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
`;

interface ModalProps {
  children: React.ReactNode;
  // Optional prop for telling the modal if it's open or not.
  // Alternatively, you can conditionally render the Modal without `open`. Example:
  // {modalIsOpen && (<Modal>...</Modal>)}
  open?: boolean;
  onClose: () => void;
  hideCloseButton?: boolean;
  fullHeight?: boolean;
  wide?: boolean;
}

/**
 * Displays content in an overlay on top of the page.
 *
 * For the title at the top of the modal content, you can use the ModalTitle component from Modal.tsx
 */
export const Modal = ({
  children,
  open = true,
  onClose,
  fullHeight = false,
  wide = false,
  hideCloseButton = false,
}: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ModalBackdrop $open={open} />
      <ContentContainer $open={open} $fullHeight={fullHeight} $wide={wide}>
        {!hideCloseButton && (
          <CloseButton onClick={onClose}>
            <CloseModal title="close" />
          </CloseButton>
        )}
        <ModalFlexColumn $spacing="none">{children}</ModalFlexColumn>
      </ContentContainer>
    </Dialog>
  );
};
