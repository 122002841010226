import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PriorAuthTableResponseRow, PriorAuthTableRow, client } from '../api';
import PageContainer from '../components/PageContainer';
import { Outlet } from 'react-router-dom';
import { expiringPriorAuthsQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

import PriorAuthTable from '../components/pas/PriorAuthTable';
import { formatStandardDate } from '../utils';
const PriorAuthTablePage: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery<PriorAuthTableRow[]>({
    queryKey: expiringPriorAuthsQueryKey(),
    queryFn: () => {
      return client.get(`expiring-prior-auths`).then((res) => {
        return res.data.map((item: PriorAuthTableResponseRow) => ({
          ...item,
          formatted_approval_until: formatStandardDate(item.pa.approval_until),
          formatted_prescription_recieved_at: formatStandardDate(item.rx.prescription_recieved_at),
          formatted_dob: formatStandardDate(item.rx.dob),
        }));
      });
    },
  });

  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const onDismiss = async (priorAuthId: string) => {
    queryClient.setQueryData(expiringPriorAuthsQueryKey(), (oldData: PriorAuthTableRow[] | undefined) => {
      if (!oldData) return [];
      return oldData.map((row) => {
        if (row.pa.id === priorAuthId) {
          return { ...row, pa: { ...row.pa, dismissed: true } };
        }
        return row;
      });
    });

    if (isTrainingMode) {
      console.warn(`Training Mode: Mocking dismiss for PA ID: ${priorAuthId}`);
      return Promise.resolve({ success: true, dismissed: true });
    }

    await client.post(`prior-auths/${priorAuthId}/dismiss`);
  };

  const onRestore = async (priorAuthId: string) => {
    queryClient.setQueryData(expiringPriorAuthsQueryKey(), (oldData: PriorAuthTableRow[] | undefined) => {
      if (!oldData) return [];
      return oldData.map((row) => {
        if (row.pa.id === priorAuthId) {
          return { ...row, pa: { ...row.pa, dismissed: false } };
        }
        return row;
      });
    });

    await client.post(`prior-auths/${priorAuthId}/restore`);
  };

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = <PageLoadError />;
  } else if (data) {
    body = <PriorAuthTable data={data} onDismiss={onDismiss} onRestore={onRestore} />;
  }

  return (
    <>
      <PageContainer title="Expiring Prior Authorizations">{body}</PageContainer>
      <Outlet />
    </>
  );
};

export default PriorAuthTablePage;
