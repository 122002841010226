import * as Sentry from '@sentry/react';
import { StytchB2B } from '@stytch/react/b2b';
import { StytchB2BUIConfig, StytchEventType } from '@stytch/vanilla-js';
import PageContainer from './PageContainer';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { client } from '../api';
import { AxiosError } from 'axios';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { AuthContext } from '../auth';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles.ts';

import { B2BProducts, AuthFlowType, B2BOAuthProviders } from '@stytch/vanilla-js';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { loginLinkExpiredCopy } from 'design-system/src/sharedCopy';

// Apply styles not included in the stytch API (which seems very limited)
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;

  // Styles from here down are for targeting stytch UI pieces that the stytch API
  // doesn't let us style directly
  * {
    // This is here specifically for the hairline separating username + password
    // inputs from oath buttons
    div {
      border-color: var(--border-gray) !important;
    }
  }

  div[size~='header'] {
    font-family: 'Space Grotesk', system-ui, Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
  }

  div[size~='body'] {
    font-size: 0.9375rem;
  }

  button,
  input {
    font-size: 0.9375rem;
    height: 2.75rem;
    ${focusVisibleStyles}
    ${standardTransitionStyles}
  }

  button {
    // For random buttons that don't fall into the 'primary'
    // or 'secondary' categories
    border-radius: var(--border-radius-medium);

    &:disabled {
      background-color: rgba(236, 233, 240, 0.5);
      border-color: rgba(236, 233, 240, 0.5);
      color: var(--disabled-gray);
    }
  }

  // I really hate to do this, but the way the input is set up
  // makes the focus outline look strange
  input[name~='password'] {
    &:focus-visible {
      box-shadow: none;
    }
  }

  input {
    padding: 0 0.75rem;
  }

  #oauth-google,
  #oauth-microsoft {
    &:hover {
      background-color: var(--light-border-gray);
      border-color: var(--light-border-gray);
    }
  }
`;

const domain = import.meta.env.VITE_STYTCH_REDIRECT_DOMAIN;

const passwordRedirectURL = `${domain}/password-reset`;
const defaultRedirect = '/';

// Apply custom styles to the pre-built UI components through stytch API
const styles = {
  fontFamily: "'DM Sans', system-ui, Helvetica, Arial, sans-serif",
  textColor: 'red',
  headerTextFont: "'Space Grotesk', system-ui, Helvetica, Arial, sans-serif",
  hideHeaderText: false,
  container: {
    backgroundColor: 'var(--white)',
    width: '464px',
    borderRadius: 'var(--border-radius-large)',
    borderColor: 'var(--border-gray)',
  },
  colors: {
    // "primary" color affects the header text color
    // !important is needed to target the "or" text
    primary: 'var(--black) !important',
    success: 'var(--green)',
    error: 'var(--red)',
  },
  buttons: {
    primary: {
      borderRadius: 'var(--border-radius-medium)',
      backgroundColor: 'var(--purple)',
      borderColor: 'var(--purple)',
      textColor: 'var(--white)',
    },
    secondary: {
      borderRadius: 'var(--border-radius-medium)',
      backgroundColor: 'transparent',
      borderColor: 'var(--border-gray)',
      textColor: 'var(--black)',
    },
  },
  inputs: {
    borderRadius: 'var(--border-radius-medium)',
    borderColor: 'var(--border-gray)',
    placeholderColor: 'var(--border-gray)',
    fontSize: '20px',
  },
};

interface FormData {
  token: string;
}

interface LoginResponse {
  // Define the structure of the response data you expect
}

interface ErrorResponse {
  error?: string;
}

const useLogin = (postLoginRedirect: string = defaultRedirect) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const login = useMutation<LoginResponse, AxiosError<ErrorResponse>, FormData>({
    mutationFn: (data) => client.post<LoginResponse>('login', data).then((res) => res.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate(postLoginRedirect);
    },
  });

  return login;
};

export const Login = () => {
  const authContext = React.useContext(AuthContext);
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const postLoginRedirect = useRef(searchParams.get('next') || undefined);

  const config = slug
    ? getConfig(AuthFlowType.Organization, postLoginRedirect.current)
    : getConfig(AuthFlowType.Discovery, postLoginRedirect.current);

  const login = useLogin(postLoginRedirect.current);
  const callbacks = {
    onEvent: (event: { type: StytchEventType; data: any }) => {
      if (
        event.type === StytchEventType.B2BDiscoveryIntermediateSessionExchange ||
        event.type === StytchEventType.B2BOAuthAuthenticate ||
        event.type === StytchEventType.B2BPasswordAuthenticate ||
        event.type === StytchEventType.B2BMagicLinkAuthenticate ||
        event.type === StytchEventType.B2BSSOAuthenticate
      ) {
        login.mutate({ token: event.data.session_token });
      }
    },
    onError: (data: any) => {
      console.error(data);
      setIsError(true);
      if (data.message.includes('unable_to_auth_magic_link')) {
        setErrorMessage(loginLinkExpiredCopy);
      } else {
        Sentry.captureException(data);
      }
    },
  };

  if (authContext.auth === true) {
    return <Navigate to={postLoginRedirect.current || defaultRedirect} />;
  }

  return (
    <PageContainer background="light" navbarPlacement="top">
      <LoginContainer>
        {isError ? (
          <PageLoadError overrideErrorMessage={errorMessage} />
        ) : (
          <StytchB2B config={config} callbacks={callbacks} styles={styles} />
        )}
      </LoginContainer>
    </PageContainer>
  );
};

export const PasswordReset = () => {
  const login = useLogin();

  const callbacks = {
    onEvent: (event: { type: StytchEventType; data: any }) => {
      if (
        event.type === StytchEventType.B2BPasswordResetByEmail ||
        event.type === StytchEventType.B2BPasswordResetBySession
      ) {
        login.mutate({ token: event.data.session_token });
      }
    },
    onError: (data: any) => {
      console.error(data);
    },
  };
  return (
    <PageContainer title="Reset Password" background="light" navbarPlacement="top">
      <LoginContainer>
        <StytchB2B config={getConfig(AuthFlowType.PasswordReset)} callbacks={callbacks} styles={styles} />
      </LoginContainer>
    </PageContainer>
  );
};

const getConfig = (type: AuthFlowType, postLoginRedirect: string | undefined = undefined): StytchB2BUIConfig => {
  const loginRedirectURL = postLoginRedirect
    ? `${domain}/login?next=${encodeURIComponent(postLoginRedirect)}`
    : `${domain}/login`;

  switch (type) {
    case AuthFlowType.Organization:
      return {
        authFlowType: AuthFlowType.Organization,
        // sso must come before oauth because stytch renders a blank row if SSO is not configured
        products: [B2BProducts.emailMagicLinks, B2BProducts.passwords, B2BProducts.sso, B2BProducts.oauth],
        emailMagicLinksOptions: {
          loginRedirectURL: loginRedirectURL,
        },
        ssoOptions: {
          loginRedirectURL: loginRedirectURL,
          // TODO: uncomment if we want to allow SSO users to join via JIT provisioning
          // signupRedirectURL: loginRedirectURL,
        },
        oauthOptions: {
          providers: [B2BOAuthProviders.Google, B2BOAuthProviders.Microsoft],
          loginRedirectURL: loginRedirectURL,
        },
        passwordOptions: {
          loginRedirectURL: loginRedirectURL,
          resetPasswordRedirectURL: passwordRedirectURL,
          resetPasswordExpirationMinutes: 20,
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
        // Skip the org selection and log the user in directly if they have a single membership,
        // unless they can join another org via an invite or JIT provisioning
        directLoginForSingleMembership: {
          status: true,
          ignoreInvites: false,
          ignoreJitProvisioning: false,
        },
      };
    case AuthFlowType.Discovery:
      return {
        authFlowType: AuthFlowType.Discovery,
        products: [B2BProducts.emailMagicLinks, B2BProducts.oauth],
        emailMagicLinksOptions: {
          discoveryRedirectURL: loginRedirectURL,
        },
        oauthOptions: {
          providers: [B2BOAuthProviders.Google, B2BOAuthProviders.Microsoft],
          discoveryRedirectURL: loginRedirectURL,
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
        // Skip the org selection and log the user in directly if they have a single membership,
        // unless they can join another org via an invite or JIT provisioning
        directLoginForSingleMembership: {
          status: true,
          ignoreInvites: false,
          ignoreJitProvisioning: false,
        },
      };
    case AuthFlowType.PasswordReset:
      return {
        authFlowType: AuthFlowType.PasswordReset,
        products: [B2BProducts.passwords],
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
      };
  }
};
