import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { UseMutationResult } from '@tanstack/react-query';
export type CloseTaskConfirmationModalProps = {
  onCloseModal: () => void;
  closeTaskMutation: UseMutationResult<void, Error, void>;
};

/**
 * A modal asking the user to confirm that they want to cancel the task.
 */
export const CloseTaskConfirmationModal = ({ onCloseModal, closeTaskMutation }: CloseTaskConfirmationModalProps) => {
  return (
    <Modal onClose={onCloseModal}>
      <ModalTitle>Close task</ModalTitle>

      <ModalContent>
        <Typography styledAs="bodySmallDMSans">You can reopen this task at any time by adding a comment.</Typography>

        <ButtonRow>
          <Button variant="tertiary" size="sm" onClick={onCloseModal} disabled={closeTaskMutation.isPending}>
            Cancel
          </Button>
          <Button size="sm" onClick={() => closeTaskMutation.mutate()} isLoading={closeTaskMutation.isPending}>
            Close task
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

export default CloseTaskConfirmationModal;
