import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';

import { Badge } from 'design-system/src/components/Badge/Badge';
import Button from 'design-system/src/components/Button/Button';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import ChevronDown from 'design-system/src/assets/icons/chevronDown.svg?react';
import ChevronUp from 'design-system/src/assets/icons/chevronUp.svg?react';
import { ExpandingContainer } from 'design-system/src/components/ExpandingContainer/ExpandingContainer';
import { InfoBox } from 'design-system/src/components/InfoBox/InfoBox';
import { FlexRow } from 'design-system/src/components/Layout';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import { RadioGroup, RadioOption, RadioButton, RadioLabel } from 'design-system/src/components/Radio/Radio';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';

import { NewCommentFile, FileType, client } from '../api';
import { titleCase } from '../utils';
import PageContainer from '../components/PageContainer';
import { ActionContainer } from '../components/tasks/TaskActions';
import { UploadedFileRow, UploadedFile, SimpleFileUploader, PracticeFile } from '../components/tasks/Files';
import { Hairline } from '../components/tableItemDetailViewComponents/Containers';
import { ExtraInfoTable } from '../components/ExtraInfoTable/ExtraInfoTable';

const FaxVerificationContainer = styled.div`
  margin: 24px;
  max-width: 600px;
`;

const FileRowContainer = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PrescriberList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const PrescriberItem = styled.li`
  padding: 16px;
  border: 1px solid var(--border-gray);
`;

const PrescriberItemContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
`;

const PrescriberColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// keep in sync with python/models/cmm_verification.py:PrescriberVerificationStatus
type VerificationStatus =
  | 'pending'
  | 'fax_selected'
  | 'fax_triggered'
  | 'verified'
  | 'error'
  | 'no_fax_numbers_available'
  | 'no_valid_fax_numbers';

type PrescriberVerification = {
  npi: string;
  name: string;
  status: VerificationStatus;
  fax_number: string | null;
  fax_options: string[];
};

const SUPPORT_PHONE_NUMBER = '(208) 254-0103';
const SUPPORT_FAX_NUMBER = '(208) 213-2108';
// 800 numbers typically use dashes instead of parentheses
const NPI_REGISTRY_PHONE_NUMBER = '1-800-465-3203';

const VerificationInstructions: React.FC = () => {
  return (
    <>
      <InfoBox>
        In order to submit PAs for your practice through CoverMyMeds, Tandem needs a verification fax for each NPI
        listed on your account.
      </InfoBox>
      <Typography renderedAs="h2" styledAs="bodyLargeSpaceGrotesk" weight={500} marginBottom="1rem">
        How to verify
      </Typography>
      <Typography>
        You will receive a prescriber verification fax from CoverMyMeds for each NPI on your account, and you must
        upload or forward each of those faxes.
      </Typography>
    </>
  );
};

const ActionBar: React.FC<{
  selectedFiles: NewCommentFile[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<NewCommentFile[]>>;
}> = ({ selectedFiles, setSelectedFiles }) => {
  const queryClient = useQueryClient();

  const uploadMutation = useMutation({
    mutationFn: async () => {
      if (selectedFiles.length === 0) return;

      const results = [];
      const errors = [];

      for (const fileInfo of selectedFiles) {
        const formData = new FormData();
        formData.append('file', fileInfo.file);

        try {
          const response = await client.post('/verification/upload', formData);
          results.push(response.data);
        } catch (error) {
          console.error('Error uploading verification file:', error);
          errors.push({
            filename: fileInfo.file.name,
            error: error,
          });
        }
      }

      return {
        files: results,
        errors: errors,
      };
    },
    onSuccess: (data) => {
      if (data && data.files) {
        setSelectedFiles([]);
        queryClient.invalidateQueries({ queryKey: ['verification-documents'] });

        if (data.errors && data.errors.length > 0) {
          toast.custom(() => (
            <Toast variant={'error'}>
              {data.errors.length} file(s) failed to upload:
              {data.errors.map((error, index) => (
                <div key={index}>{error.filename}</div>
              ))}
            </Toast>
          ));
        } else {
          toast.custom(() => <Toast variant="success">Files uploaded successfully.</Toast>);
        }
      }
    },
    onError: (error) => {
      console.error('Error uploading verification files:', error);
      Sentry.captureException(error);
      toast.custom(() => <StandardErrorToast />);
    },
  });

  const handleFileSelection = (newFiles: NewCommentFile[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveSelectedFile = (filename: string, document_type: FileType) => {
    const updatedFiles = selectedFiles.filter(
      (file) => !(file.file.name === filename && file.document_type === document_type),
    );
    setSelectedFiles(updatedFiles);
  };

  return (
    <ActionContainer>
      {selectedFiles.length > 0 && (
        <FileRowContainer>
          <UploadedFileRow>
            {selectedFiles.map((fileInfo) => (
              <UploadedFile
                key={`${fileInfo.file.name}-${fileInfo.document_type}`}
                onFileDelete={handleRemoveSelectedFile}
                filename={fileInfo.file.name}
                fileType={fileInfo.document_type}
              />
            ))}
          </UploadedFileRow>
        </FileRowContainer>
      )}

      <ButtonRow align={Alignment.Left}>
        <SimpleFileUploader onFilesUpload={handleFileSelection} />
        <Button
          onClick={() => uploadMutation.mutate()}
          disabled={selectedFiles.length === 0 || uploadMutation.isPending}
          isLoading={uploadMutation.isPending}
        >
          Upload
        </Button>
      </ButtonRow>
    </ActionContainer>
  );
};

/** Find most common fax number in list of prescriber fax options */
export const getMostCommonFax = (prescribers: PrescriberVerification[]) => {
  const faxCounts: Record<string, number> = {};
  let totalPending = 0;

  prescribers.forEach((prescriber) => {
    if (prescriber.status === 'pending') {
      totalPending++;
      prescriber.fax_options.forEach((fax) => {
        faxCounts[fax] = (faxCounts[fax] || 0) + 1;
      });
    }
  });

  const mostCommonFax = Object.entries(faxCounts).sort(([, a], [, b]) => b - a)[0];

  return mostCommonFax && mostCommonFax[1] > totalPending * 0.5 ? mostCommonFax[0] : null;
};

type ConfirmFaxModalProps = {
  onCloseModal: () => void;
  submitMutation: UseMutationResult<void, Error, { npi: string; fax_number: string }, unknown>;
  selection: { npi: string; fax_number: string };
};

const ConfirmFaxModal = ({ onCloseModal, submitMutation, selection }: ConfirmFaxModalProps) => {
  return (
    <Modal onClose={onCloseModal}>
      <ModalTitle>Confirm fax number</ModalTitle>

      <ExtraInfoTable
        data={[
          { label: 'NPI', value: selection.npi },
          { label: 'Fax number', value: selection.fax_number },
        ]}
      />

      <ModalContent>
        <Typography styledAs="bodySmallDMSans">
          Please confirm the fax number is accurate. After sending, you will need to contact our team to make any
          updates.
        </Typography>

        <ButtonRow>
          <Button variant="tertiary" size="sm" onClick={onCloseModal} disabled={submitMutation.isPending}>
            Back
          </Button>
          <Button
            size="sm"
            onClick={() => submitMutation.mutate({ npi: selection.npi, fax_number: selection.fax_number })}
            isLoading={submitMutation.isPending}
          >
            Confirm and send
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

type ConfirmNoAccessModalProps = {
  onCloseModal: () => void;
  submitMutation: UseMutationResult<void, Error, { npi: string }, unknown>;
  selection: string;
};

const ConfirmNoAccessModal = ({ onCloseModal, submitMutation, selection }: ConfirmNoAccessModalProps) => {
  return (
    <Modal onClose={onCloseModal}>
      <ModalTitle>Confirm fax number is missing</ModalTitle>

      <ExtraInfoTable data={[{ label: 'NPI', value: selection }]} />

      <ModalContent>
        <Typography styledAs="bodySmallDMSans">
          If your office fax number is not listed for a specific NPI, or you would like to add a fax number for this
          NPI, you can reach out to the NPI Registry at {NPI_REGISTRY_PHONE_NUMBER} to update the fax numbers associated
          with your account.
        </Typography>

        <ButtonRow>
          <Button variant="tertiary" size="sm" onClick={onCloseModal} disabled={submitMutation.isPending}>
            Back
          </Button>
          <Button
            size="sm"
            onClick={() => submitMutation.mutate({ npi: selection })}
            isLoading={submitMutation.isPending}
          >
            I confirm that I called the NPI Registry
          </Button>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
};

const FaxVerificationPage: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<NewCommentFile[]>([]);
  const [selections, setSelections] = useState<Record<string, string>>({});
  const [showStepOne, setShowStepOne] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isMissingFaxModalOpen, setIsMissingFaxModalOpen] = useState(false);
  // used to pass data to a modal
  const [selectionForModal, setSelectionForModal] = useState<{ npi: string; fax_number: string } | null>(null);
  const [selectionForMissingFax, setSelectionForMissingFax] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { data: prescriberData, isLoading: prescriberDataLoading } = useQuery<{
    prescribers: PrescriberVerification[];
  }>({
    queryKey: ['prescriber-verifications'],
    queryFn: async () => {
      const response = await client.get('/verification/prescribers');
      return response.data;
    },
  });

  const documentsQuery = useQuery({
    queryKey: ['verification-documents'],
    queryFn: async () => {
      const response = await client.get('/verification/documents');
      return response.data;
    },
  });

  const submitFaxSelection = useMutation<void, Error, { npi: string; fax_number: string }>({
    mutationFn: async ({ npi, fax_number }) => {
      await client.post('/verification/trigger-fax', {
        npi,
        fax_number,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prescriber-verifications'] });
      setIsConfirmModalOpen(false);
      setSelectionForModal(null);
      toast.custom(() => <Toast variant="success">Verification fax sent.</Toast>);
    },
    onError: (error) => {
      console.error('Error submitting fax selections:', error);
      Sentry.captureException(error);
      toast.custom(() => <StandardErrorToast />);
    },
  });

  const submitMissingFax = useMutation<void, Error, { npi: string }>({
    mutationFn: async ({ npi }) => {
      await client.post('/verification/missing-fax', {
        npi,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prescriber-verifications'] });
      setIsMissingFaxModalOpen(false);
      setSelectionForMissingFax(null);
      toast.custom(() => <Toast variant="success">Fax recorded as missing.</Toast>);
    },
    onError: (error) => {
      console.error('Error submitting missing fax:', error);
      Sentry.captureException(error);
      toast.custom(() => <StandardErrorToast />);
    },
  });

  // Initialize state based on prescriber verification data
  useEffect(() => {
    if (prescriberData?.prescribers) {
      // if any prescribers have a pending status, show step one
      // keep this in sync with disableSubmission logic below
      const anyPending = prescriberData.prescribers.some((prescriber) => prescriber.status === 'pending');
      if (anyPending) {
        setShowStepOne(true);
      }
      // initialize selections with most common fax, if available, otherwise first option
      const commonFax = getMostCommonFax(prescriberData.prescribers);
      const newSelections: Record<string, string> = {};
      prescriberData.prescribers.forEach((prescriber) => {
        // only set selections for pending prescribers
        if (prescriber.status === 'pending') {
          if (commonFax && prescriber.fax_options.includes(commonFax)) {
            newSelections[prescriber.npi] = commonFax;
          } else if (prescriber.fax_options.length > 0) {
            newSelections[prescriber.npi] = prescriber.fax_options[0];
          }
        }
      });
      setSelections(newSelections);
    }
  }, [prescriberData]);

  const getPrescriberStatusBadge = (status: string) => {
    switch (status) {
      case 'pending':
        return <Badge variant="warning">Action needed</Badge>;
      case 'fax_selected':
        return <Badge variant="neutral">Fax pending</Badge>;
      case 'verified':
        return <Badge variant="success">Verified</Badge>;
      case 'error':
        return <Badge variant="error">Error</Badge>;
      default:
        return <Badge variant="neutral">{titleCase(status).replaceAll('_', ' ')}</Badge>;
    }
  };
  const getPrescriberFaxOptions = (prescriber: PrescriberVerification) => {
    return prescriber.fax_options.length > 0 ? (
      <>
        <RadioGroup>
          {prescriber.fax_options.map((fax) => {
            const optionId = `${prescriber.npi}-${fax}`;
            return (
              <RadioOption key={optionId}>
                <RadioButton
                  type="radio"
                  id={optionId}
                  name={`${prescriber.npi}-fax-options`}
                  value={fax}
                  checked={selections[prescriber.npi] === fax}
                  onChange={(e) => {
                    setSelections((prev) => ({
                      ...prev,
                      [prescriber.npi]: e.target.value,
                    }));
                  }}
                />
                <RadioLabel as="label" htmlFor={optionId}>
                  {fax}
                </RadioLabel>
              </RadioOption>
            );
          })}
        </RadioGroup>
        <ButtonRow>
          <Button
            size="sm"
            onClick={() => {
              setSelectionForModal({ npi: prescriber.npi, fax_number: selections[prescriber.npi] });
              setIsConfirmModalOpen(true);
            }}
          >
            Trigger fax
          </Button>
          <Button
            variant="link"
            size="sm"
            onClick={() => {
              setSelectionForMissingFax(prescriber.npi);
              setIsMissingFaxModalOpen(true);
            }}
          >
            I don't have access to any of these numbers
          </Button>
        </ButtonRow>
      </>
    ) : (
      <Badge variant="error">No options available</Badge>
    );
  };

  if (prescriberDataLoading) {
    return (
      <PageContainer title="CoverMyMeds fax verification">
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer title="CoverMyMeds fax verification">
      <FaxVerificationContainer>
        <VerificationInstructions />
        <Hairline />
        <StepContainer>
          <FlexRow $centerAlign>
            <Typography renderedAs="h3" styledAs="bodyLargeSpaceGrotesk" weight={500}>
              Step 1: Confirm your fax numbers
            </Typography>
            <Button variant="tertiary" size="sm" onClick={() => setShowStepOne(!showStepOne)}>
              {showStepOne ? (
                <>
                  Hide <ChevronUp aria-hidden />
                </>
              ) : (
                <>
                  Show <ChevronDown aria-hidden />
                </>
              )}
            </Button>
          </FlexRow>
          <ExpandingContainer visible={showStepOne}>
            <Typography marginBottom="1rem">
              You will receive verification pages by fax to the selected numbers below, for each provider NPI. Please
              confirm these are the correct numbers for your office fax.
              <br />
              <br />
              When ready, click below to initiate each fax to your office. It may take several minutes for the fax to
              come through.
              <br />
              <br />
              If you have any questions or run into issues, please call us at: {SUPPORT_PHONE_NUMBER}
            </Typography>
            <PrescriberList>
              {prescriberData?.prescribers.map((prescriber: PrescriberVerification) => {
                const userNeedsToCallNpiRegistry =
                  prescriber.status === 'no_fax_numbers_available' || prescriber.status === 'no_valid_fax_numbers';

                return (
                  // TODO: consider splitting this out into a separate component so we don't
                  // have to track the selection in state for modals
                  <PrescriberItem key={prescriber.npi}>
                    <PrescriberItemContent>
                      <PrescriberColumn>
                        <Typography>
                          {prescriber.name && (
                            <>
                              {prescriber.name}
                              <br />
                            </>
                          )}
                          NPI: {prescriber.npi}
                        </Typography>
                        {getPrescriberStatusBadge(prescriber.status)}
                      </PrescriberColumn>

                      {!userNeedsToCallNpiRegistry && (
                        <PrescriberColumn>
                          <Typography styledAs="bodyDMSans" weight={500}>
                            Selected fax number
                          </Typography>
                          {prescriber.status === 'pending' ? (
                            getPrescriberFaxOptions(prescriber)
                          ) : (
                            <Typography>{prescriber.fax_number}</Typography>
                          )}
                        </PrescriberColumn>
                      )}
                    </PrescriberItemContent>
                    <ExpandingContainer
                      visible={prescriber.status === 'fax_selected' || userNeedsToCallNpiRegistry}
                      marginTop="16px"
                    >
                      <InfoBox includeIcon={false} noMargin={true}>
                        <Typography>
                          {prescriber.status === 'fax_selected'
                            ? 'Tandem has triggered a verification fax for this provider. Please upload the fax below when you receive it.'
                            : `Please reach out to the NPI Registry at ${NPI_REGISTRY_PHONE_NUMBER} to update the fax numbers associated with your account.`}
                        </Typography>
                      </InfoBox>
                    </ExpandingContainer>
                  </PrescriberItem>
                );
              })}
            </PrescriberList>
          </ExpandingContainer>
        </StepContainer>
        <Hairline />
        <StepContainer>
          <Typography renderedAs="h3" styledAs="bodyLargeSpaceGrotesk" weight={500}>
            Step 2: Submit verification faxes
          </Typography>
          <Typography renderedAs="div">
            Once you have all the fax pages, you can provide them to Tandem by either method below:
            <ul>
              <li>Upload the faxes below</li>
              <li>Forward pages via fax to: {SUPPORT_FAX_NUMBER}</li>
            </ul>
            If you have any questions or run into issues, please call us at: {SUPPORT_PHONE_NUMBER}
          </Typography>
          <Typography styledAs="bodyLargeSpaceGrotesk" weight={500}>
            Uploaded files
          </Typography>
          <InfoBox noMargin>
            These files will have the subject line "Action Required: CoverMyMeds Drug Authorizations - Verify Your
            Prescribers".
            <br />
            <br />
            Please do not upload any other fax documents here.
          </InfoBox>
          {documentsQuery.isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : documentsQuery.isError ? (
            <Typography color="error">Error loading documents. Please try again.</Typography>
          ) : documentsQuery.data?.documents && documentsQuery.data.documents.length > 0 ? (
            <FileRowContainer>
              <UploadedFileRow>
                {documentsQuery.data.documents.map((doc: { document_id: string }) => (
                  <PracticeFile key={doc.document_id} fileId={doc.document_id} />
                ))}
              </UploadedFileRow>
            </FileRowContainer>
          ) : (
            <Typography>No verification documents uploaded yet.</Typography>
          )}
          <ActionBar selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        </StepContainer>

        {isConfirmModalOpen && selectionForModal && (
          <ConfirmFaxModal
            onCloseModal={() => {
              setIsConfirmModalOpen(false);
              setSelectionForModal(null);
            }}
            submitMutation={submitFaxSelection}
            selection={selectionForModal}
          />
        )}
        {isMissingFaxModalOpen && selectionForMissingFax && (
          <ConfirmNoAccessModal
            onCloseModal={() => {
              setIsMissingFaxModalOpen(false);
              setSelectionForMissingFax(null);
            }}
            submitMutation={submitMissingFax}
            selection={selectionForMissingFax}
          />
        )}
      </FaxVerificationContainer>
    </PageContainer>
  );
};

export default FaxVerificationPage;
