import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';

export const FormLabel = styled(Typography).attrs({
  styledAs: 'bodySmallSpaceGrotesk',
  renderedAs: 'label',
  weight: 500,
})`
  margin-bottom: 4px;
`;
