import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { client, NewCommentFile, FormFilesDocumentTypes, TaskComment, TaskEvent, Task } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import React, { useContext, useState, useEffect } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { TaskContext } from './contexts';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import Badge from 'design-system/src/components/Badge/Badge';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
  Hairline,
} from '../tableItemDetailViewComponents/Containers';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import DeclineModal from './DeclineModal';
import TaskActions from './TaskActions';
import CommentAndFileSection from './CommentAndFileSection';
import AppealRequestModal, { AdditionalInput, AppealRequestModalType } from './AppealRequestModal';
import { TASK_SUBTYPE_TO_INSTRUCTIONS } from './instructions';
import styled from 'styled-components';
import { AuthContext } from '../../auth';
import SubmitAppealModal from './SubmitAppealModal';
import NewFollowupTaskModal from './NewFollowupTaskModal';
import SwitchTherapyModal from './SwitchTherapyModal';

type ModalState = {
  show: boolean;
  type: 'decline' | 'initiate' | 'submit_appeal' | 'other_request' | 'switch_therapy';
};

export const InstructionsContainer = styled.div`
  background-color: var(--surface);
  padding: 12px;
  border-radius: var(--border-radius-medium);
  margin-bottom: 16px;
`;

/**
 * Component for rendering a single structured task.
 *
 * TODO: split these out into separate components for each task type
 */
const TaskView: React.FC = () => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // Files that have been uploaded as part of composing a new comment
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);
  const [additionalInput, setAdditionalInput] = useState<AdditionalInput>({
    needs_draft_review: false,
  });
  const [modalState, setModalState] = useState<ModalState>({
    show: false,
    type: 'decline',
  });
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [taskResponseLoading, setTaskResponseLoading] = useState(false);
  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const appeals_v2 = authContext?.user?.feature_flags['appeals_v2'];

  const initiate = useMutation({
    mutationFn: async () => {
      if (isTrainingMode) {
        console.warn(`Training Mode: Mocking "initiate" for task ID: ${taskId}`);

        const newComment: TaskComment = {
          id: `mock-initiate-${Date.now()}`,
          item_type: 'comment',
          direction: 'inbound',
          message: inputValue,
          user: 'You',
          attachment_document_ids: newFilesUploaded.map((file) => file.file.name),
          created_at: new Date().toISOString(),
        };

        setMockComments((prev) => [...prev, newComment]);

        setNewFilesUploaded([]);
        setInputValue('');
        return;
      }

      const formData = new FormData();
      formData.append('task_response', 'initiate');
      formData.append('message', inputRef.current?.value || '');
      formData.append('additional_input', JSON.stringify(additionalInput));

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};
        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });
        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      await client.post(`tasks/${taskId}/respond`, formData);
    },
    onSuccess: () => {
      onTaskUpdate();
      toast.custom(() => <Toast variant="success">Task initiated.</Toast>);
      navigate('/tasks');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const [mockComments, setMockComments] = useState<TaskComment[]>([]);

  const submit = useMutation({
    mutationFn: async () => {
      // We need at least a message or a file to submit a new comment
      if (!inputValue && !newFilesUploaded.length) {
        return;
      }

      if (isTrainingMode) {
        console.warn(`Training Mode: Mocking task comment submission for task ID: ${taskId}`);

        const newComment: TaskComment = {
          id: `mock-submit-${Date.now()}`,
          item_type: 'comment',
          direction: 'inbound',
          message: inputValue,
          user: 'You',
          attachment_document_ids: newFilesUploaded.map((file) => file.file.name),
          created_at: new Date().toISOString(),
        };

        setMockComments((prev) => [...prev, newComment]);

        setNewFilesUploaded([]);
        setInputValue('');

        return Promise.resolve({ success: true });
      }
      const formData = new FormData();
      formData.append('message', inputValue);

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};
        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      const res = await client.post(`tasks/${taskId}/submit`, formData);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
      setNewFilesUploaded([]);
      setInputValue('');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    return () => {
      setMockComments([]); // Reset mock comments on unmount
    };
  }, []);

  const closeModal = () => {
    setModalState({ ...modalState, show: false });
  };

  useEffect(() => {
    const handleFormMessage = (messageEvent: MessageEvent) => {
      if (!messageEvent.data) return;

      try {
        if (!messageEvent.data.type) return;

        const event = messageEvent.data.type.split('embed.form.')[1];

        switch (event) {
          case 'completed':
            onTaskUpdate();
            toast.custom(() => <Toast variant="success">Form signed.</Toast>);
            navigate('/tasks');
            break;
          case 'exception':
            toast.custom(() => <Toast variant="error">Error, please try again.</Toast>);
            break;
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    window.addEventListener('message', handleFormMessage);
    return () => window.removeEventListener('message', handleFormMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let body;
  let modalToShow;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const { drug, patient, prescriber } = taskQuery.data;
    const task = taskQuery.data.task as Task;

    const showSidebar = drug && patient && prescriber;

    const declineModal = (
      <DeclineModal
        closeModal={closeModal}
        patient={patient}
        drug={drug}
        taskType={task.type}
        taskId={taskId}
        onTaskUpdate={onTaskUpdate}
      />
    );

    let appealRequestModalType;
    switch (task.type) {
      case 'PA denied - Select next step':
        appealRequestModalType = 'initiate_with_next_steps';
        break;
      case 'PA denied - Ready to appeal':
        appealRequestModalType = 'revise_with_next_steps';
        break;
      case 'Appeal drafted - Ready for review':
        appealRequestModalType = 'revise_only';
        break;
    }

    const initiateModal = (
      <AppealRequestModal
        closeModal={closeModal}
        initiate={initiate}
        inputRef={inputRef}
        patient={patient}
        drug={drug}
        inputValue={inputValue}
        setInputValue={setInputValue}
        files={newFilesUploaded}
        onFilesChange={setNewFilesUploaded}
        setAdditionalInput={setAdditionalInput}
        additionalInput={additionalInput}
        appealRequestModalType={appealRequestModalType as AppealRequestModalType}
      />
    );

    const submitAppealModal = (
      <SubmitAppealModal
        closeModal={closeModal}
        patient={patient}
        drug={drug}
        taskId={taskId}
        includeEnrollmentNotice={task.type === 'PA denied - Ready to appeal'}
      />
    );

    const newFollowupTaskModal = (
      <NewFollowupTaskModal
        closeModal={closeModal}
        patient={patient}
        drug={drug}
        patientId={patient.id}
        prescriptionId={drug.id}
        taskId={taskId}
      />
    );

    const switchTherapyModal = (
      <SwitchTherapyModal closeModal={closeModal} patient={patient} drug={drug} onTaskUpdate={onTaskUpdate} />
    );

    switch (modalState.type) {
      case 'decline':
        modalToShow = declineModal;
        break;
      case 'initiate':
        modalToShow = initiateModal;
        break;
      case 'submit_appeal':
        modalToShow = submitAppealModal;
        break;
      case 'other_request':
        modalToShow = newFollowupTaskModal;
        break;
      case 'switch_therapy':
        modalToShow = switchTherapyModal;
        break;
    }
    const instructions = TASK_SUBTYPE_TO_INSTRUCTIONS[task.type];

    // Additional check that task.timeline isn't null so that this doesn't result in an error when executed
    const comments = (
      <TimelineItemList>
        <>
          {task.timeline.map((item, index) => {
            let author;
            let timestamp;
            let message;
            let attachedFilesIds;
            if (item.item_type === 'comment') {
              const comment = item as TaskComment;
              timestamp = comment.created_at;
              author = comment.direction === 'inbound' ? comment.user : 'Tandem';
              message = comment.message;
              attachedFilesIds = comment.attachment_document_ids;
            } else {
              const event = item as TaskEvent;
              timestamp = event.event_timestamp;
              author = event.user;
              message = event.label;
              attachedFilesIds = event.attachment_document_ids;
            }
            return (
              <TimelineItem
                key={`task-item-${item.id}`}
                isLastEventInList={index === task.timeline.length - 1}
                author={author}
                timestamp={timestamp}
                message={message}
                attachedFilesIds={attachedFilesIds}
              />
            );
          })}

          {mockComments.map((mockComment, index) => (
            <TimelineItem
              key={`mock-${mockComment.id}`}
              isLastEventInList={index === mockComments.length - 1}
              author={mockComment.user}
              timestamp={mockComment.created_at}
              message={mockComment.message}
              attachedFilesIds={mockComment.attachment_document_ids}
            />
          ))}
        </>
      </TimelineItemList>
    );

    const errors = [];
    if (task.embedded_document_url && newFilesUploaded.length > 0) {
      errors.push(
        'Note: Tandem can only manage enrollments with Tandem-initiated forms. Please do not upload forms initiated by your office.',
      );
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            noSidebar={!showSidebar}
            additionalRightSideContent={task.status === 'completed' && <Badge variant="neutral">Closed</Badge>}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" marginBottom="1rem">
                {task.type}
              </Typography>

              <GeneralInfoTable
                patientName={drug.patient_name}
                prescriberName={drug.prescriber_name}
                dob={drug.dob}
                prescribedOn={drug.prescription_recieved_at}
                drugName={drug.name}
                prescriptionId={drug.id}
              />

              {task.timeline && comments}

              {showCommentInput && (
                <CommentAndFileSection
                  onSubmit={() => submit.mutate()}
                  isSubmitting={updating}
                  inputRef={inputRef}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  files={newFilesUploaded}
                  onFilesChange={setNewFilesUploaded}
                  errors={errors}
                  autoFocus
                />
              )}

              {(task.timeline || showCommentInput) && <Hairline />}

              {task.status !== 'completed' && (
                <>
                  {instructions && appeals_v2 && <InstructionsContainer>{instructions}</InstructionsContainer>}
                  {taskResponseLoading ? (
                    <LoadingSpinner height="200px" />
                  ) : (
                    <TaskActions
                      setShowDeclineModal={() => setModalState({ show: true, type: 'decline' })}
                      task={task}
                      setTaskResponseLoading={setTaskResponseLoading}
                      setShowCommentInput={setShowCommentInput}
                      setShowInitiateModal={() => setModalState({ show: true, type: 'initiate' })}
                      setShowSubmitAppealModal={() => setModalState({ show: true, type: 'submit_appeal' })}
                      setShowNewFollowupTaskModal={() => setModalState({ show: true, type: 'other_request' })}
                      setShowSwitchTherapyModal={() => setModalState({ show: true, type: 'switch_therapy' })}
                      rxId={drug.id}
                      allowRedraftRequest={task.allow_redraft_request}
                    />
                  )}
                </>
              )}
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {showSidebar && !isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} showPharmaRelationshipNotice={task.is_pharma_contract_task} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      {body}

      {modalState.show && modalToShow}
    </>
  );
};

export default TaskView;
