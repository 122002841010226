import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { linkButtonStyles } from '../Button/Button';
import { focusVisibleStyles, standardTransitionStyles } from '../../sharedStyles';

const StyledRenderHtmlString = styled.span`
  white-space: pre-line;

  a {
    ${linkButtonStyles}
    ${standardTransitionStyles}
    ${focusVisibleStyles}
  }
`;

export const parseLinksInString = (str: string): string => {
  const linkRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const replacer = (matched: string) => {
    let withProtocol = matched;

    if (!withProtocol.startsWith('http')) {
      withProtocol = 'http://' + matched;
    }

    return `<a href="${withProtocol}" target="_blank" rel="noopener noreferrer">${matched} <span class="sr-only">Opens in a new tab</span></a>`;
  };

  // Just in case there's anything malicious in the string because
  // this is inserted into an html element using dangerouslySetInnerHTML
  const sanitizedStr = DOMPurify.sanitize(str);

  const linkifiedStr = sanitizedStr.replace(linkRegex, replacer);
  const brokenUpStr = linkifiedStr.replace(/\n/g, '<br/>');

  return brokenUpStr;
};

export type RenderHtmlStringProps = {
  children: string;
  className?: string;
};

/**
 * Parses a string and renders it in an HTML element.
 *
 * The string will be sanitized and urls will be converted into <a> elements that open in a new tab.
 */
export const RenderHtmlString = ({ children, className = '' }: RenderHtmlStringProps) => {
  return (
    <StyledRenderHtmlString dangerouslySetInnerHTML={{ __html: parseLinksInString(children) }} className={className} />
  );
};
