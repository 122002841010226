import styled from 'styled-components';
import { Modal, ModalTitle, ModalContent } from 'design-system/src/components/Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Button from 'design-system/src/components/Button/Button';
import { containerBoxShadow, standardTransitionStyles, focusVisibleStyles } from 'design-system/src/sharedStyles';
import ChevronRight from 'design-system/src/assets/icons/chevronRight.svg?react';
import ChatBubble from 'design-system/src/assets/icons/chatBubble.svg?react';
import Mail from 'design-system/src/assets/icons/mail.svg?react';

// TODO(design-system): convert into ClickableRowCard component
const ClickableRowCard = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--white);
  ${containerBoxShadow}
  ${standardTransitionStyles}

  &:hover {
    background: var(--light-border-gray);
    svg {
      color: var(--medium-dark-purple-1);
    }
  }

  ${focusVisibleStyles}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const IconContainer = styled.div`
  color: var(--purple);
`;

export type PatientContactModalProps = {
  onClose: () => void;
};

/**
 * Modal telling patients how to contact Tandem support.
 */
export const PatientContactModal = ({ onClose }: PatientContactModalProps) => {
  return (
    <Modal onClose={onClose} hideCloseButton>
      <ModalTitle>Contact Tandem</ModalTitle>
      <ModalContent>
        <Typography styledAs="bodyLargeDMSans">Patients may contact us below regarding their prescriptions.</Typography>

        <ClickableRowCard href="sms:+18334635013">
          <FlexRow>
            <IconContainer>
              <ChatBubble aria-hidden={true} />
            </IconContainer>
            <Typography styledAs="bodyLargeDMSans">
              Text support at{' '}
              <Typography styledAs="bodyLargeDMSans" weight={700} renderedAs="span">
                (833) 463-5013
              </Typography>
            </Typography>
          </FlexRow>
          <ChevronRight aria-hidden={true} />
        </ClickableRowCard>

        <ClickableRowCard href="mailto:support@withtandem.com">
          <FlexRow>
            <IconContainer>
              <Mail aria-hidden={true} />
            </IconContainer>
            <Typography styledAs="bodyLargeDMSans">
              Email support at{' '}
              <Typography styledAs="bodyLargeDMSans" weight={700} renderedAs="span">
                support@withtandem.com
              </Typography>
            </Typography>
          </FlexRow>
          <ChevronRight aria-hidden={true} />
        </ClickableRowCard>

        <Button size="lg" width="full" variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default PatientContactModal;
