import styled from 'styled-components';
import { Select, MenuItem } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import ChevronDown from 'design-system/src/assets/icons/chevronDown.svg?react';
import { focusVisibleStylesNoWrapper, standardTransitionStyles } from 'design-system/src/sharedStyles';

export type DropdownChangeEvent = SelectChangeEvent<unknown>;
export const DropdownItem = MenuItem;

// TODO: style text
// TODO: style overlay
export const Dropdown = styled(Select).attrs({
  IconComponent: ChevronDown,
})<{ $hasError?: boolean }>`
  // if the height isn't set, the height will shift by 1px when an item is selected
  height: 44px;

  > [role='combobox'] {
    padding: 10px 12px;
  }

  > fieldset {
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--border-gray) !important;
    ${standardTransitionStyles}
  }

  // stops the empty legend from breaking the focus outline of the input field
  > fieldset > legend {
    width: 0px;
  }

  > svg {
    color: var(--black);
    right: 12px;
    transition: transform 0.2s ease-out;
    top: auto;
  }

  &.Mui-focused > fieldset {
    border: 1px solid var(--border-gray) !important;
    ${focusVisibleStylesNoWrapper}
  }

  &.Mui-disabled {
    background-color: var(--background-gray);

    // targeting everthing because there are several elements with cursor: default
    > * {
      cursor: not-allowed !important;
    }

    > svg {
      color: var(--border-gray);
    }
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    > fieldset {
      border: 1px solid var(--red);
    }
  `}
`;

// TODO: make dropdown component that can accept a label and helper text
// and control the spacing between them
export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
