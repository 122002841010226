import { useMutation, useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage.tsx';
import { FileType, Patient, client } from '../../api.tsx';

import { Controller, useForm } from 'react-hook-form';

import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button/Button.tsx';
import ButtonRow from 'design-system/src/components/ButtonRow/ButtonRow';
import { FormLabel } from '../FormLabel.tsx';
import { TextArea } from '../Input.tsx';
import { Content, Overlay, animationDurationMs } from '../Modal.tsx';
import Typeahead, { TypeaheadOption } from '../Typeahead.tsx';
import { Dropdown, DropdownItem, DropdownContainer } from '../Dropdown.tsx';
import { formatStandardDate } from '../../utils.tsx';
import { AxiosError } from 'axios';
import { InboundTaskType, NewCommentFile, FormFilesDocumentTypes } from '../../api.tsx';
import { FileUploader, UploadedFile, UploadedFileRow } from './Files.tsx';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner.tsx';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable.tsx';

type FormData = {
  text: string;
  patientId: string;
  prescriptionId: string;
  type: InboundTaskType;
};

type RxData = {
  drug: string;
  drug_name: string;
  id: string;
  prescriber_first_name: string;
  prescriber_last_name: string;
  written_date: string;
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 3rem;
`;

const DocumentsArea = styled.div`
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-medium);
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CancelButton = styled(Button)`
  border: 1px solid var(--border-gray);
  background: transparent;
  transition: background-color 0.2s ease-out;
  color: var(--black);

  &:hover {
    background: var(--light-border-gray);
  }
`;

const PrefilledInfoSection = styled.div`
  margin-bottom: 0.5rem;
`;

const LoadingForm = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

// Using <a> instead of NavLink because the Toast exists outside
// of the router system
// TODO(design-system): Replace with Link component
const SuccessLink = styled.a`
  color: var(--dark-green);
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

const createPatientOption = (patient: Patient): TypeaheadOption => {
  const option: TypeaheadOption = {
    value: patient.id,
    description: `${patient.first_name} ${patient.last_name} (${formatStandardDate(patient.date_of_birth)})`,
  };
  return option;
};

const createRxOption = (
  rxData: RxData,
): {
  description: string;
  value: string;
} => {
  const { id, drug, prescriber_first_name, prescriber_last_name, written_date } = rxData;
  const date = formatStandardDate(written_date);

  const option: {
    description: string;
    value: string;
  } = {
    value: id,
    description: `${drug}, ${prescriber_first_name} ${prescriber_last_name} on ${date}`,
  };

  return option;
};

const Form: React.FC<{ onSubmit: (taskId: string) => void }> = (props) => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const submit = useMutation<{ task_id: string }, AxiosError<{ error: string }>, FormData>({
    mutationFn: async (data) => {
      if (isSubmittingTask) {
        return;
      }
      setIsSubmittingTask(true);

      if (isTrainingMode) {
        console.warn('Training Mode: Mocking task creation, no real request sent.');

        // Simulating a successful response without sending the request
        return Promise.resolve({ task_id: 'mock-task-id' });
      }

      const formData = new FormData();
      formData.append('patient_id', data.patientId);
      formData.append('prescription_id', data.prescriptionId);
      formData.append('text', data.text);
      formData.append('type', data.type);
      formData.append('task_source', searchParams.get('source') || '');

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};

        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      return client.post(`tasks`, formData).then((res) => res.data);
    },
    onSuccess: ({ task_id }) => {
      props.onSubmit(task_id);
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      setIsSubmittingTask(false);
      Sentry.captureException(error);
      console.error(error);
    },
  });
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues: {
      patientId: searchParams.get('patientId') || '',
      prescriptionId: params.rxId || '',
    },
  });
  const [isSubmittingTask, setIsSubmittingTask] = useState<boolean>(false);
  const [isFetchingRxList, setIsFetchingRxList] = useState<boolean>(false);
  const [isFetchingPatients, setIsFetchingPatients] = useState<boolean>(true);
  const [rxList, setRxList] = useState<{ value: string; description: string }[]>([]);
  const [patients, setPatients] = useState([]);
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);
  const [duplicateFileNameUploaded, setDuplicateFileNameUploaded] = useState<string | null>(null);
  const [preselectedPatient, setPreselectedPatient] = useState<TypeaheadOption | null>(null);
  const [preselectedRx, setPreselectedRx] = useState<TypeaheadOption | null>(null);
  const navigate = useNavigate();

  // NOTE: this logic is duplicated in TaskView.tsx - please keep in sync
  const onFilesUpload = (filesToUpload: NewCommentFile[]) => {
    setDuplicateFileNameUploaded(null);
    let foundDuplicateFileName = false;

    // Error out if the user happened to choose a file with the same name (path) as one they've
    // already added. Normally it wouldn't matter, but we're using the file names as keys to track
    // the document types when sending them to the server for saving. If they upload another file
    // with the same name, they will both be uploaded with the same document type, which will
    // cause problems downstream.
    filesToUpload.map((fileToUpload) => {
      const fileNameAlreadyUploaded = newFilesUploaded.find((uploadedFile: NewCommentFile) => {
        return fileToUpload.file.name === uploadedFile.file.name;
      });
      if (fileNameAlreadyUploaded) {
        setDuplicateFileNameUploaded(fileToUpload.file.name);
        // We're using a local variable because the state won't update fast enough for us to use
        // that value
        foundDuplicateFileName = true;
        return;
      }
    });

    if (!foundDuplicateFileName) {
      setNewFilesUploaded([...newFilesUploaded, ...filesToUpload]);
    }
  };

  const onFileDelete = (filename: string, document_type: FileType) => {
    const newFilesUploadedCopy = [...newFilesUploaded];

    const fileIndex = newFilesUploadedCopy.findIndex((fileInfo) => {
      return fileInfo.file.name === filename && fileInfo.document_type === document_type;
    });

    if (fileIndex === -1) {
      toast.custom(() => <StandardErrorToast />);
      console.error("Couldn't delete file because we couldn't find it: ", filename);
    } else {
      newFilesUploadedCopy.splice(fileIndex, 1);
    }

    setNewFilesUploaded(newFilesUploadedCopy);
  };

  const query = useQuery({
    queryKey: ['patients'],
    queryFn: () => {
      return client.get(`patients`).then((res) => res.data);
    },
  });

  const getRxList = useMutation<{ rxs: RxData[] }, AxiosError<{ error: string }>, { patientId: string }>({
    mutationFn: async (data) => {
      return client.get(`rx_list/${data.patientId}`).then((res) => res.data);
    },
    onSuccess: async (res_data) => {
      setRxList(res_data.rxs.map((rxData: RxData) => createRxOption(rxData)));
      setIsFetchingRxList(false);
      const preselectedRx = res_data.rxs.find((rxData: RxData) => rxData.id === params.rxId);
      if (preselectedRx) {
        setPreselectedRx(createRxOption(preselectedRx));
      }
    },
    onError: async () => {
      toast.custom(() => <StandardErrorToast />);
      setIsFetchingRxList(false);
    },
  });

  useEffect(() => {
    if (!query.data || !query.data.patients) {
      return;
    }
    setPatients(query.data.patients.map((patient: Patient) => createPatientOption(patient)));
    setIsFetchingPatients(false);
    const preselectedPatient = query.data.patients.find(
      (patient: Patient) => patient.id === searchParams.get('patientId'),
    );
    if (preselectedPatient) {
      setIsFetchingRxList(true);
      setPreselectedPatient(createPatientOption(preselectedPatient));
      getRxList.mutate({ patientId: preselectedPatient.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data, searchParams]);

  const handlePatientChange = (onChange: (patientId: string) => void, patientId: string) => {
    onChange(patientId);
    setIsFetchingRxList(true);
    getRxList.mutate({ patientId: patientId });
  };

  const isLoadingPreselectedRx = params.rxId && isFetchingRxList;
  const isLoadingPreselectedPatient = searchParams.get('patientId') && isFetchingPatients;

  if (isLoadingPreselectedRx || isLoadingPreselectedPatient) {
    return (
      <LoadingForm>
        <LoadingSpinner />
      </LoadingForm>
    );
  }

  const extraInfoData = [];
  if (preselectedPatient) {
    extraInfoData.push({ label: 'Patient', value: preselectedPatient.description });
  }
  if (preselectedRx) {
    extraInfoData.push({ label: 'Prescription', value: preselectedRx.description });
  }

  return (
    <FormContainer onSubmit={handleSubmit((data) => submit.mutate(data))}>
      <h1>New task</h1>
      {!!extraInfoData.length && (
        <PrefilledInfoSection>
          <ExtraInfoTable data={extraInfoData} />
        </PrefilledInfoSection>
      )}
      {!preselectedPatient && (
        <Controller
          name="patientId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Typeahead
              options={patients}
              label={'Patient'}
              onChange={(patientId: string) => {
                handlePatientChange(field.onChange, patientId);
              }}
              value={field.value}
            />
          )}
        />
      )}
      {!preselectedRx && (
        <Controller
          name="prescriptionId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownContainer>
              <FormLabel id="rx-label">Prescription</FormLabel>
              <Dropdown
                labelId="rx-label"
                id="rx"
                value={field.value}
                label="Prescription"
                onChange={field.onChange}
                disabled={!rxList.length || isFetchingRxList}
              >
                {rxList.map((rx) => (
                  <DropdownItem key={rx.value} value={rx.value}>
                    {rx.description}
                  </DropdownItem>
                ))}
              </Dropdown>
            </DropdownContainer>
          )}
        />
      )}
      <Controller
        name="type"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DropdownContainer>
            <FormLabel id="task-type-label">Task type</FormLabel>
            <Dropdown
              labelId="task-type-label"
              id="task-type"
              label="Task type"
              onChange={field.onChange}
              defaultValue=""
            >
              <DropdownItem value={'authorization_update'}>Authorization Update</DropdownItem>
              <DropdownItem value={'pap_bridge_update'}>PAP/Bridge Update</DropdownItem>
              <DropdownItem value={'pharmacy'}>Pharmacy</DropdownItem>
              <DropdownItem value={'pa_renewal'}>PA Renewal</DropdownItem>
              <DropdownItem value={'other'}>Other</DropdownItem>
            </Dropdown>
          </DropdownContainer>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        name="text"
        rules={{ required: true }}
        render={({ field }) => <TextArea label="Task" placeholder="Task" rows={4} {...field} />}
      />
      <div>
        <FormLabel>Documents</FormLabel>
        <DocumentsArea>
          <FileUploader onFilesUpload={onFilesUpload} />
          {!!newFilesUploaded.length && (
            <UploadedFileRow>
              {newFilesUploaded.map((fileInfo) => {
                return (
                  <UploadedFile
                    key={`${fileInfo.file.name}`}
                    onFileDelete={onFileDelete}
                    filename={fileInfo.file.name}
                    fileType={fileInfo.document_type}
                  />
                );
              })}
            </UploadedFileRow>
          )}
        </DocumentsArea>
        <ErrorMessage visible={!!duplicateFileNameUploaded}>
          {`A file with the name ${duplicateFileNameUploaded} has already been added. Please change the file name and try again.`}
        </ErrorMessage>
      </div>
      <ButtonRow>
        <CancelButton type="button" onClick={() => navigate(-1)}>
          Cancel
        </CancelButton>
        <Button isLoading={isSubmittingTask} disabled={!formState.isValid} type="submit">
          Submit
        </Button>
      </ButtonRow>
      <ErrorMessage visible={submit.isError}>{submit.error?.response?.data.error}</ErrorMessage>
    </FormContainer>
  );
};

export const NewTaskForm: React.FC = () => {
  const navigate = useNavigate();
  const [animateOut, setAnimateOut] = useState(false);

  const outletContext: any = useOutletContext();

  return (
    <Overlay
      onClick={() => {
        setAnimateOut(true);
        setTimeout(() => navigate(-1), animationDurationMs);
      }}
    >
      <Content onClick={(e) => e.stopPropagation()} $animateOut={animateOut}>
        <Form
          onSubmit={(taskId: string) => {
            outletContext.reload();
            setAnimateOut(true);
            toast.custom(() => (
              <Toast variant="success">
                Task created. <SuccessLink href={`/tasks/${taskId}`}>View task</SuccessLink>
              </Toast>
            ));
            setTimeout(() => navigate(-1), animationDurationMs);
          }}
        />
      </Content>
    </Overlay>
  );
};

export default NewTaskForm;
