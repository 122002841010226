import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { focusVisibleStyles, standardTransitionStyles } from '../../sharedStyles';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Typography } from '../Typography/Typography';

// The button styles are all exported so they can be used directly
// in rare cases where the Button component cant be inherited from

export const baseButtonStyles = css`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  // for when the button has icon and text
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius-medium);
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

export const disabledButtonStyles = css`
  &:disabled:not(.loading) {
    cursor: not-allowed;
    background: var(--background-gray);
    border-color: var(--background-gray);
    color: var(--disabled-gray);
  }
`;

export const disabledLinkButtonStyles = css`
  &:disabled:not(.loading) {
    cursor: not-allowed;
    color: var(--disabled-gray);
  }
`;

export const primaryButtonStyles = css`
  background: var(--purple);
  border: 1px solid var(--purple);
  color: var(--white);

  &:hover,
  &:focus-visible {
    background: var(--medium-dark-purple-1);
    border-color: var(--medium-dark-purple-1);
  }

  &:active {
    background: var(--medium-dark-purple-2);
    border-color: var(--medium-dark-purple-2);
  }

  // override other states when loading
  &.loading {
    cursor: not-allowed;
    background: var(--purple);
    border: 1px solid var(--purple);
    color: var(--white);
  }
`;

export const secondaryButtonStyles = css`
  background-color: var(--light-border-gray);
  border: 1px solid var(--light-border-gray);
  color: var(--black);

  &:hover,
  &:focus-visible {
    background: var(--border-gray);
    border-color: var(--border-gray);
  }

  &:active {
    background: var(--medium-gray);
    border-color: var(--medium-gray);
  }

  // override other states when loading
  &.loading {
    cursor: not-allowed;
    background: var(--light-border-gray);
    border: 1px solid var(--light-border-gray);
    color: var(--black);
  }
`;

export const tertiaryButtonStyles = css`
  background-color: transparent;
  border: 1px solid var(--border-gray);
  color: var(--black);

  &:hover,
  &:focus-visible {
    background: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }

  &:active {
    background: var(--border-gray);
    border-color: var(--border-gray);
  }

  // override other states when loading
  &.loading {
    cursor: not-allowed;
    background: transparent;
    border: 1px solid var(--border-gray);
    color: var(--black);
  }
`;

export const linkButtonStyles = css`
  background: transparent;
  border: none;
  color: var(--black);
  text-decoration: underline;
  padding: 0;
  height: fit-content;
`;

export const largeButtonStyles = css`
  height: 44px;
  padding: 0 16px;
`;

export const smallButtonStyles = css`
  height: 36px;
  padding: 0 12px;
`;

export const extraSmallButtonStyles = css`
  height: 32px;
  padding: 0 8px;
`;

export const fullWidthButtonStyles = css`
  width: 100%;
`;

export const hugWidthButtonStyles = css`
  width: fit-content;
`;

export const iconButtonStyles = css`
  ${tertiaryButtonStyles}

  // height: 44px;
  // width: 44px;
  border: 1px solid transparent;
  color: var(--dark-gray);

  // override other states when loading
  &.loading {
    cursor: not-allowed;
    border: 1px solid transparent;
    color: var(--dark-gray);
  }
`;

export const largeIconButtonStyles = css`
  height: 44px;
  width: 44px;
`;

export const smallIconButtonStyles = css`
  height: 36px;
  width: 36px;
`;

export const borderButtonStyles = css`
  border: 1px solid var(--border-gray);
`;

export const highlightedButtonStyles = css`
  background: var(--light-purple);
  border: 1px solid var(--light-purple);
  color: var(--black);

  &:hover,
  &:focus-visible,
  &:active,
  &.loading {
    background: var(--light-purple);
    border-color: var(--light-purple);
  }

  &.loading {
    cursor: not-allowed;
  }
`;

// define the props as lists to to make it more efficient to render
// the different combos in the story tests
export const buttonVariants = ['primary', 'secondary', 'tertiary', 'link', 'highlighted'] as const;
export const buttonSizes = ['lg', 'sm', 'xs'] as const;
export const buttonWidths = ['full', 'hug'] as const;

// convert the prop lists to types
type ButtonVariant = (typeof buttonVariants)[number];
type ButtonSize = (typeof buttonSizes)[number];
type ButtonWidth = (typeof buttonWidths)[number];

type StyledButtonProps = {
  $variant: ButtonVariant;
  $size: ButtonSize;
  $width: ButtonWidth;
};

// TODO: make this more robust so it can use all the same styles as the Button component
export const RouterLink = styled(Link)`
  ${linkButtonStyles}
`;

const StyledIconButton = styled.button<{ $size: ButtonSize }>`
  ${baseButtonStyles}
  ${iconButtonStyles}
  ${({ $size }) => $size === 'sm' && smallIconButtonStyles}
  ${({ $size }) => $size === 'lg' && largeIconButtonStyles}
  ${disabledButtonStyles}
`;

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * What element to render the button as.
   * Default is `"button"`.
   */
  renderedAs?: string | React.ElementType;
  className?: string;
  size?: ButtonSize;
  /**
   * Whether to show a loading spinner instead of the `children`.
   */
  isLoading?: boolean;
}

/**
 * Icon version of the button component.
 *
 * This is a separate component instead of a variant because it doesn't have the width prop that the other button variants have.
 */
export const IconButton = ({
  renderedAs = 'button',
  children,
  className = '',
  size = 'lg',
  isLoading = false,
  ...rest
}: ButtonProps) => {
  return (
    <StyledIconButton as={renderedAs} $size={size} className={`${isLoading ? 'loading' : ''} ${className}`} {...rest}>
      {/* TODO: make loading spinner 20x20px in buttons */}
      {isLoading ? <LoadingSpinner height="fit-content" /> : children}
    </StyledIconButton>
  );
};

const StyledButton = styled(Typography)<StyledButtonProps>`
  ${baseButtonStyles}

  ${({ $width }) => $width === 'full' && fullWidthButtonStyles}
  ${({ $width }) => $width === 'hug' && hugWidthButtonStyles}
  ${({ $size }) => $size === 'sm' && smallButtonStyles}
  ${({ $size }) => $size === 'lg' && largeButtonStyles}
  ${({ $size }) => $size === 'xs' && extraSmallButtonStyles}
  // variants have to go after sizes so the link styles can override the sizes
  ${({ $variant }) => $variant === 'primary' && primaryButtonStyles}
  ${({ $variant }) => $variant === 'secondary' && secondaryButtonStyles}
  ${({ $variant }) => $variant === 'tertiary' && tertiaryButtonStyles}
  ${({ $variant }) => $variant === 'link' && linkButtonStyles}
  ${({ $variant }) => $variant === 'highlighted' && highlightedButtonStyles}

  ${({ $variant }) => ($variant === 'link' ? disabledLinkButtonStyles : disabledButtonStyles)}
`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * What element to render the button as.
   * Default is `"button"`.
   */
  renderedAs?: string | React.ElementType;
  className?: string;
  /**
   * The color theme of the button.
   * `"primary"` = purple; `"secondary"` = gray; `"tertiary"` = transparent with outline
   * Default is `"primary"`.
   */
  variant?: ButtonVariant;
  size?: ButtonSize;
  width?: ButtonWidth;
  /**
   * Whether to show a loading spinner instead of the `children`.
   */
  isLoading?: boolean;
}

/**
 * Standard button component, for all your clickity needs.
 */
const Button = ({
  renderedAs = 'button',
  children,
  className = '',
  width = 'hug',
  variant = 'primary',
  size = 'lg',
  isLoading = false,
  disabled = false,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      renderedAs={renderedAs}
      styledAs={'bodySmallSpaceGrotesk'}
      weight={500}
      $width={width}
      $variant={variant}
      $size={size}
      className={`${isLoading ? 'loading' : ''} ${className}`}
      disabled={isLoading || disabled}
      {...rest}
    >
      {/* TODO: make loading spinner 20x20px in buttons */}
      {isLoading ? <LoadingSpinner light={variant === 'primary'} height="fit-content" /> : children}
    </StyledButton>
  );
};

export default Button;
