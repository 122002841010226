import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import InitialsAvatar from 'design-system/src/components/InitialsAvatar/InitialsAvatar';
import Button, {
  baseButtonStyles,
  iconButtonStyles,
  smallButtonStyles,
} from 'design-system/src/components/Button/Button';
import { standardTransitionStyles, focusVisibleStyles } from 'design-system/src/sharedStyles';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import HorizontalMenu from 'design-system/src/assets/icons/horizontalMenu.svg?react';
import { Checkbox } from 'design-system/src/components/Checkbox/Checkbox';

import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from '../PopoverMenu';
import { SearchField } from '../Input';
import { client } from '../../api';
import type { OrgData, OrgMember } from '../../api';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { Table, TableCell, TableContainer } from '../Table';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { noResultsCopy } from 'design-system/src/sharedCopy';
import AddMembersModal from './AddMembersModal/AddMembersModal';
import EditMemberModal from './EditMemberModal/EditMemberModal';
import DeleteMemberModal from './DeleteMemberModal/DeleteMemberModal';

const StyledSearchField = styled(SearchField)`
  width: 300px;
`;

const StyledBulkActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

// TODO: consolidate this with the table styles in NotificationSettings.tsx
const CustomTableContainer = styled(TableContainer)`
  margin-top: 1rem;

  margin-bottom: 1rem;
  // Allow table to take natural width but enable scrolling if needed
  width: fit-content;
  // Screen width - sidebar width - body padding
  max-width: calc(100vw - var(--nav-sidebar-width) - 2.5rem);
  height: auto;
`;

const CustomTableRow = styled.tr`
  border-bottom: 1px solid var(--light-border-gray);
`;

const CustomTableCell = styled(TableCell)`
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  min-width: 5rem;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 272px;
`;

const EmailAndName = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconPopoverMenuButton = styled(PopoverMenuButton)`
  ${baseButtonStyles}
  ${smallButtonStyles}
  ${iconButtonStyles}
  ${standardTransitionStyles}
  ${focusVisibleStyles}
`;

/**
 * Table of all users in an organization. Only visible to admins.
 */
const UsersTable: React.FC = () => {
  const [filteredMembers, setFilteredMembers] = useState<OrgMember[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMembersIds, setSelectedMembersIds] = useState<string[]>([]);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);

  const orgQuery = useQuery<OrgData>({
    queryKey: ['orgMembers'],
    queryFn: () => {
      return client.get(`org-members`).then((res) => res.data);
    },
  });

  useEffect(() => {
    setFilteredMembers(
      orgQuery.data?.members.filter((member) => {
        const nameInSearchQuery = member.name?.toLowerCase().includes(searchQuery.toLowerCase());
        const emailInSearchQuery = member.email_address.toLowerCase().includes(searchQuery.toLowerCase());
        return nameInSearchQuery || emailInSearchQuery;
      }) || [],
    );
  }, [orgQuery.data, searchQuery]);

  const getToggleRowSelectionButton = (memberId: string) => {
    const isChecked = selectedMembersIds.includes(memberId);
    return (
      <Checkbox
        aria-label={isChecked ? 'unselect row' : 'select row'}
        checked={isChecked}
        onClick={() => {
          const indexOfRowInSelectedRows = selectedMembersIds.indexOf(memberId);
          if (indexOfRowInSelectedRows !== -1) {
            const updatedSelectedRowIds = [...selectedMembersIds];
            updatedSelectedRowIds.splice(indexOfRowInSelectedRows, 1);
            setSelectedMembersIds(updatedSelectedRowIds);
          } else {
            const updatedSelectedRowIds = [...selectedMembersIds, memberId];
            setSelectedMembersIds(updatedSelectedRowIds);
          }
        }}
      />
    );
  };

  let body;
  if (orgQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (orgQuery.error) {
    body = <PageLoadError />;
  } else if (!orgQuery.data) {
    body = null;
  } else {
    body = (
      <>
        <ButtonRow align={Alignment.SpaceBetween}>
          <StyledSearchField
            label="Search by name or email"
            placeholder="Search by name or email"
            hideLabel
            value={searchQuery}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
          />

          {!!selectedMembersIds.length && (
            <StyledBulkActionButtonContainer>
              <Button size="lg" variant="tertiary" onClick={() => setShowDeleteMemberModal(true)}>
                Delete
              </Button>
              <Typography styledAs="bodySmallSpaceGrotesk">{selectedMembersIds.length} selected</Typography>
            </StyledBulkActionButtonContainer>
          )}

          <Button size="sm" onClick={() => setShowAddMembersModal(true)}>
            Add users
          </Button>
        </ButtonRow>

        <CustomTableContainer>
          <Table>
            <thead className="sr-only">
              <tr>
                <td>Name and email</td>
                <td>Role</td>
                <td>Actions</td>
                <td>Select rows</td>
              </tr>
            </thead>
            <tbody>
              {filteredMembers.length === 0 ? (
                <tr>
                  <CustomTableCell colSpan={3}>{noResultsCopy}</CustomTableCell>
                </tr>
              ) : (
                filteredMembers.map((member) => {
                  const { name, email_address, is_admin, id, is_current_user } = member;
                  const nameString = is_current_user ? `${name} (You)` : name;

                  return (
                    <CustomTableRow key={id}>
                      <CustomTableCell>
                        <Name>
                          <InitialsAvatar name={name || email_address} />
                          <EmailAndName>
                            <Typography renderedAs="span" styledAs="bodySmallDMSans" weight={600}>
                              {nameString}
                            </Typography>
                            <Typography renderedAs="span" styledAs="bodyExtraSmallDMSans">
                              {email_address}
                            </Typography>
                          </EmailAndName>
                        </Name>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Typography styledAs="bodySmallDMSans">{is_admin ? 'Admin' : 'Non-admin'}</Typography>
                      </CustomTableCell>

                      <CustomTableCell>
                        <PopoverMenu>
                          <IconPopoverMenuButton>
                            <HorizontalMenu title="actions" />
                          </IconPopoverMenuButton>

                          <PopoverMenuItems>
                            <PopoverMenuSection>
                              <PopoverMenuItem>
                                <PopoverMenuItemButton
                                  onClick={() => {
                                    setSelectedMembersIds([member.id]);
                                    setShowEditMemberModal(true);
                                  }}
                                >
                                  Edit
                                </PopoverMenuItemButton>
                              </PopoverMenuItem>

                              {!is_current_user && (
                                <PopoverMenuItem>
                                  <PopoverMenuItemButton
                                    onClick={() => {
                                      setSelectedMembersIds([member.id]);
                                      setShowDeleteMemberModal(true);
                                    }}
                                  >
                                    Delete
                                  </PopoverMenuItemButton>
                                </PopoverMenuItem>
                              )}
                            </PopoverMenuSection>
                          </PopoverMenuItems>
                        </PopoverMenu>
                      </CustomTableCell>

                      <CustomTableCell>{!is_current_user ? getToggleRowSelectionButton(id) : null}</CustomTableCell>
                    </CustomTableRow>
                  );
                })
              )}
            </tbody>
          </Table>
        </CustomTableContainer>

        {showAddMembersModal && (
          <AddMembersModal
            onClose={() => {
              setShowAddMembersModal(false);
            }}
            members={orgQuery.data.members}
          />
        )}

        {showEditMemberModal && selectedMembersIds.length === 1 && (
          <EditMemberModal
            onClose={() => {
              setSelectedMembersIds([]);
              setShowEditMemberModal(false);
            }}
            members={orgQuery.data.members}
            selectedMember={orgQuery.data.members.find((member) => member.id === selectedMembersIds[0])!}
          />
        )}

        {showDeleteMemberModal && (
          <DeleteMemberModal
            onClose={() => {
              setSelectedMembersIds([]);
              setShowDeleteMemberModal(false);
            }}
            selectedMembers={orgQuery.data.members.filter((member) => selectedMembersIds.includes(member.id))}
          />
        )}
      </>
    );
  }

  return body;
};

export default UsersTable;
