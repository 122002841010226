import styled, { keyframes } from 'styled-components';
import Loading from '../../assets/icons/loading.svg?react';

const LoadingSpinnerContainer = styled.div<{ $height?: string; $light?: boolean }>`
  height: ${({ $height }) => $height || '100vh'};
  height: ${({ $height }) => $height || '100dvh'}; // iOS safari
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $light }) => ($light ? 'var(--white)' : 'var(--black)')};
`;

const spin = keyframes`
from {
  transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledLoadingSpinner = styled(Loading)`
  animation: ${spin} 1.5s linear infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

/**
 * An animated spinner for page content loading states. This component will fill the height of the viewport (if no height is provided) and center the spinner.
 */
export const LoadingSpinner = ({
  className,
  height,
  light,
}: {
  className?: string;
  height?: string;
  light?: boolean;
}) => {
  return (
    <LoadingSpinnerContainer className={className} $height={height} $light={light}>
      <StyledLoadingSpinner title="loading" />
    </LoadingSpinnerContainer>
  );
};
