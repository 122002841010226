import axios from 'axios';
import { ReactNode } from 'react';
export const apiHost = import.meta.env.VITE_API_HOST;

export const client = axios.create({
  baseURL: apiHost,
  withCredentials: true,
});

export const url = (path: string) => {
  return `${apiHost}${path}`;
};

export const getRxFileDownloadUrl = (rxId: string, fileId: string) => {
  return `${apiHost}/rxs/${rxId}/file/${fileId}/download`;
};

export const getRxFilePreviewUrl = (rxId: string, fileId: string) => {
  return `${apiHost}/rxs/${rxId}/file/${fileId}/preview`;
};

export const getRxFileFilenameUrl = (rxId: string, fileId: string) => {
  return `${apiHost}/rxs/${rxId}/file/${fileId}/filename`;
};

export interface ErrorResponse {
  error?: string;
}

/** Task Page */

export type TaskStatus = 'open' | 'sent' | 'completed';

export interface TaskTableDrug {
  prescriber_name: string;
  prescription_recieved_at: string;
  drug_name: string;
  drug_category: 'biologic' | 'non_biologic';
}

export interface TaskSummary {
  id: string;
  created_by: string;
  patient_name: string;
  patient_dob: string;
  last_comment_created_at: string;
  last_comment_message: string;
  status: TaskStatus;
  task_type: string;
  drug: TaskTableDrug | null;
  office_name: string | null;
}

export interface TasksResponse {
  tasks: TaskSummary[];
}

export type TaskTimelineItemType = 'comment' | 'event';

export interface TaskComment {
  id: string;
  item_type: TaskTimelineItemType;
  direction: 'inbound' | 'outbound';
  message: string;
  user: string;
  attachment_document_ids: string[];
  created_at: string;
}

export interface TaskEvent {
  id: string;
  item_type: TaskTimelineItemType;
  event_timestamp: string;
  label: string;
  user?: string;
  action?: string;
  attachment_document_ids?: string[];
}

// if adding a new structured task, make sure to update isStructuredTask in TaskOverlay.tsx
export type OutboundTaskType =
  | 'Rx clarification'
  | 'Document upload'
  | 'PA denial'
  | 'PA denied - Select next step'
  | 'PA denied - Ready to appeal'
  | 'Form signature request'
  | 'Affordability issue'
  | 'New Rx request'
  | 'Unresponsive patient'
  | 'PAP/Bridge'
  | 'Additional clinical information'
  | 'PA blocked - Missing information'
  | 'PA review'
  | 'Prescription action required'
  | 'Prescription transfer notification'
  | 'Appeal drafted - Ready for review'
  | 'PA expiring'
  | 'Other';

export type InboundTaskType =
  | 'Authorization update'
  | 'PAP/Bridge update'
  | 'Pharmacy'
  | 'PA renewal'
  | 'Other'
  | 'Help request';

export type TaskType = OutboundTaskType | InboundTaskType;

export type TaskTimelineItem = TaskComment | TaskEvent;

export type Task = {
  id: string;
  created_at: string;
  files: string[];
  type: TaskType;
  timeline: TaskTimelineItem[];
  first_name?: string;
  last_name?: string;
  dob?: string;
  embedded_document_url?: string;
  document_id?: string;
  status: TaskStatus;
  allow_reopening: boolean;
  allow_redraft_request: boolean;
  is_pharma_contract_task: boolean;
  last_comment_direction: 'inbound' | 'outbound';
  related_task?: { id: string; type: string };
};

export type PaInformationRequestTask = {
  id: string;
  type: 'PA blocked - Missing information';
  created_at: string;
  timeline: TaskTimelineItem[];
  status: TaskStatus;
  is_pharma_contract_task: boolean;
  practice_has_ehr: boolean;
};

export interface Address {
  city: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}
export interface Patient {
  id: string;
  address: Address;
  date_of_birth: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface Prescriber {
  name: string;
  npi: string;
  phone_number: string;
  fax_number: string;
  address: Address;
}

export interface Drug {
  id: string;
  name: string;
  quantity: string;
  sig: string;
  ndc: string;
  refills: string;
  prescription_recieved_at: string;
  prescriber_name: string;
  patient_name: string;
  dob: string;
}
export interface TaskResponse {
  task: Task | PaInformationRequestTask;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

export const fileTypes = [
  'Medical Records',
  'Lab Results',
  'Insurance Card Both Sides',
  'PA Denial Letter',
  'PA Approval Letter',
  'Other',
] as const;

export const fileTypeToUILabel: Record<FileType, string> = {
  'Medical Records': 'Medical records',
  'Lab Results': 'Lab results',
  'Insurance Card Both Sides': 'Insurance card',
  'PA Denial Letter': 'PA denial letter',
  'PA Approval Letter': 'PA approval letter',
  Other: 'Other',
} as const;

export type FileType = (typeof fileTypes)[number];

export type NewCommentFile = {
  file: File;
  document_type: FileType;
};

interface FileInfoTag {
  name: string;
  value: FileType;
}

export type FormFilesDocumentTypes = {
  // The key will be a filename (like 'cat1.jpg')
  [key: string]: FileType;
};

export interface FileInfoResponse {
  id: string;
  filename: string;
  success: boolean;
  tags: FileInfoTag[];
}

/** Prior Auth Page */

export type PADecision = 'PENDING' | 'APPROVAL' | 'ALREADY_APPROVED' | 'DENIAL' | 'PARTIAL_APPROVAL' | 'NOT_REQUIRED';

export type PASubmissionMode = 'CMM' | 'FAX' | 'PHONE' | 'OTHER_TOOL';

export interface PriorAuth {
  id: string;
  submitted_on_date: string;
  request_type: string;
  decision: PADecision;
  approval_until: string;
  submission_mode: PASubmissionMode;
  dismissed: boolean;
}

export interface PriorAuthTableResponseRow {
  pa: PriorAuth;
  rx: Drug;
  office_name: string;
  drug_name: string;
}

export interface PriorAuthTableRow {
  formatted_prescription_recieved_at: string;
  formatted_approval_until: string;
  formatted_dob: string;
  office_name: string;
  drug_name: string;
  pa: PriorAuth;
  rx: Drug;
}
export interface PriorAuthResponse {
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  pa: PriorAuth;
}

/** Appeal Review Page */

export type AppealReviewStatus = 'unreviewed' | 'accepted' | 'rejected' | 'redrafted';

// A row in the appeal reviews table
export interface AppealReviewSummary {
  id: string;
  drug_name: string;
  patient_name: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  status: AppealReviewStatus;
  created_at: string;
  office_name: string | null;
}

// All rows in the appeal reviews table
export interface AppealReviewsResponse {
  appeal_reviews: AppealReviewSummary[];
}

export interface AppealReview {
  id: string;
  status: AppealReviewStatus;
  reviewed_by?: string;
  reviewed_at?: string;
  reject_justification?: string;
}

// Info in the appeal review detail view
export interface AppealReviewResponse {
  appeal_review: AppealReview;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

/** Prior Auth Review Page */

export type PriorAuthReviewStatus = 'unreviewed' | 'editing' | 'edited' | 'submitted';

export type PriorAuthReviewFormStatus =
  | 'FORM_STATUS_UNKNOWN'
  | 'FORM_STATUS_NOT_STARTED'
  | 'FORM_STATUS_IN_PROGRESS'
  | 'FORM_STATUS_COMPLETED'
  | 'FORM_STATUS_SUBMITTED';

// A row in the prior auth reviews table
export interface PriorAuthReviewSummary {
  id: string;
  drug_name: string;
  patient_name: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  status: PriorAuthReviewStatus;
  created_at: string;
  office_name: string;
  signature_required: boolean;
}

// All rows in the prior auth reviews table
export interface PriorAuthReviewsResponse {
  prior_auth_reviews: PriorAuthReviewSummary[];
}

export interface PriorAuthReview {
  id: string;
  status: PriorAuthReviewStatus;
  reviewed_by?: string;
  reviewed_at?: string;
  submission_form_tree_state_id: string;
  attestation_submitted: boolean;
  signature_required: boolean;
  embed_document_url?: string;
}

// Info in the appeal review detail view
export interface PriorAuthReviewResponse {
  prior_auth_review: PriorAuthReview;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  icd_code: string;
}

type PriorAuthReviewFormAnswerType =
  | 'ANSWER_TYPE_UNKNOWN'
  | 'ANSWER_TYPE_CHECKBOX'
  | 'ANSWER_TYPE_DATE'
  | 'ANSWER_TYPE_RADIO'
  | 'ANSWER_TYPE_TEXT'
  | 'ANSWER_TYPE_JUSTIFICATION';

type PriorAuthReviewFormQuestion = {
  identifier: string;
  description: string;
  answer_type: PriorAuthReviewFormAnswerType;
  is_answered?: boolean;
};

export type PriorAuthReviewFormAnswer = {
  identifier: string;
  description?: string;
  answer_type: PriorAuthReviewFormAnswerType;
  regex_validations?: string;
  is_selected?: boolean; // if the question is answered + this answer is selected = True
  answer_input_text?: string; // populated for TEXT + DATE types
  answer_choice_text?: string; // this was not in the Retool code
};

export type PriorAuthReviewFormQuestionAnswer = {
  question: PriorAuthReviewFormQuestion;
  answer_options: PriorAuthReviewFormAnswer[];
};

export interface PriorAuthReviewFormData {
  form: {
    ordered_question_answers: PriorAuthReviewFormQuestionAnswer[];
  };
  form_status: PriorAuthReviewFormStatus;
}

type PriorAuthReviewFormAnswerSelection = {
  answer_identifier: string;
  // Passed in if answer_type is text or date
  answer_text?: string;
};

export type PriorAuthReviewFormUpdateData = {
  question_identifier: string;
  // If selected_answers is undefined, skip_question should be true
  selected_answers?: PriorAuthReviewFormAnswerSelection[];
  skip_question?: boolean;
};

/** Rx Page */
export type RxTaskType = 'task' | 'appeal_review' | 'prior_auth_review';

export type RxTaskSubtype = TaskType | 'Appeal drafted - Ready for review' | 'PA review';

export type RxTaskListType = 'all' | 'tasks' | 'reviews' | 'none';

export interface RxTask {
  id: string;
  status: 'open' | 'closed';
  type: RxTaskType;
  sub_type: RxTaskSubtype;
  created_at: string;
}

export interface RxDocument {
  id: string;
  name: string;
  date: string;
  document_type: FileType;
}

export interface LastContactedPatient {
  action: 'texted' | 'called' | null;
  timestamp: string | null;
}

export interface StatusTimelineEvent {
  event: string | ReactNode;
  timestamp: string | null;
  detail?: string | null;
}

export interface Rx {
  name: string;
  task_list_type: RxTaskListType;
  status_timelines: {
    insurance: StatusTimelineEvent[];
    pharmacy: StatusTimelineEvent[];
    bridge: StatusTimelineEvent[];
    pap: StatusTimelineEvent[];
    hub: StatusTimelineEvent[];
  };
  pa_decision: PADecision;
  tasks: RxTask[];
  documents: RxDocument[];
  last_contacted_patient: LastContactedPatient;
}

export interface Pharmacy {
  name: string;
  npi: string;
  phone: string;
  fax: string;
  address: Address;
}

export interface Insurance {
  name: string;
  phone: string;
  fax: string;
}

export interface RxResponse {
  rx: Rx;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  insurance: Insurance;
  pharmacy: Pharmacy;
  prescription_recieved_at: string;
  dob: string;
  drug_name: string;
  prescriber_name: string;
  patient_name: string;
}

export type StatusInfo = {
  status: string;
  file_id: string | null;
};

export interface PharmacyAddress extends Address {
  name: string;
}

export interface RxTableResponseRow extends Drug {
  prescriber_name: string;
  patient_name_and_dob: string;
  dob: string;
  pharmacy_address: PharmacyAddress;
  name: string;
  drug_name: string;
  pa_appeal_status: StatusInfo;
  pap_status: StatusInfo;
  bridge_status: StatusInfo;
  hub_status: StatusInfo;
  pharmacy_status: StatusInfo;
}

/** Settings types */
export type NotificationSettings = {
  email_address?: string;
  notifications?: boolean;
  npis?: string[];
};

export type OrgMemberRole = 'stytch_member' | 'stytch_admin';

export type OrgMember = {
  id: string;
  email_address: string;
  trusted_metadata: NotificationSettings;
  is_admin: boolean;
  name?: string;
  is_current_user: boolean;
};

export type OrgProvider = {
  npi: string;
  name: string | null;
};

export type OrgData = {
  members: OrgMember[];
  providers: OrgProvider[];
  show_users_tab: boolean;
};

export type NotificationData = {
  members: OrgMember[];
  providers: OrgProvider[];
};

export type NotificationUpdateData = {
  [member_id: string]: NotificationSettings;
};

export enum DrugCategory {
  Biologic = 'biologic',
  NonBiologic = 'non_biologic',
  CGRP = 'cgrp',
  ComplexTopical = 'complex_topical',
}

export enum OverallStatusFilter {
  InProgress = 'in_progress',
  Completed = 'completed',
  All = 'all',
}
