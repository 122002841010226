import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { client, NewCommentFile, FormFilesDocumentTypes, TaskComment, TaskEvent, Task } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import React, { useContext, useState, useEffect, ReactNode } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { TaskContext } from './contexts';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import ButtonRow, { Alignment } from 'design-system/src/components/ButtonRow/ButtonRow';
import CommentAndFileSection from './CommentAndFileSection';
import { Tooltip } from 'design-system/src/components/Tooltip/Tooltip';
import Button, { RouterLink } from 'design-system/src/components/Button/Button';
import { ActionContainer } from './TaskActions';
import CloseTaskConfirmationModal from './CloseTaskConfirmationModal/CloseTaskConfirmationModal';

const NO_CLOSE_BUTTON_TASK_TYPES = ['PA denial'];

const ReopenTaskNotice = styled(Typography)`
  color: var(--dark-gray);
`;

const formattedRelatedTask = (relatedTask: { id: string; type: string } | null) => {
  if (!relatedTask) {
    return null;
  }

  return {
    label: 'Related task',
    value: <RouterLink to={`/tasks/${relatedTask.id}`}>{relatedTask.type}</RouterLink>,
  };
};

/**
 * Component for rendering a single unstructured task.
 *
 * If you're adding a new structured task type, please create a different component for it to keep a clear separation of logic.
 */
const UnstructuredTaskView: React.FC = () => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // Files that have been uploaded as part of composing a new comment
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [lastCommentDirection, setLastCommentDirection] = useState<'inbound' | 'outbound' | null>(null);
  const [closeTaskConfirmationModalOpen, setCloseTaskConfirmationModalOpen] = useState(false);

  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);
  const navigate = useNavigate();

  const isTrainingMode = import.meta.env.VITE_TRAINING_MODE === 'true';
  const [mockComments, setMockComments] = useState<TaskComment[]>([]);

  const submit = useMutation({
    mutationFn: async () => {
      // We need at least a message or a file to submit a new comment
      if (!inputValue && !newFilesUploaded.length) {
        return;
      }

      if (isTrainingMode) {
        console.warn(`Training Mode: Mocking task comment submission for task ID: ${taskId}`);

        const newComment: TaskComment = {
          id: `mock-${Date.now()}`,
          item_type: 'comment',
          direction: 'inbound',
          message: inputValue,
          user: 'You',
          attachment_document_ids: newFilesUploaded.map((file) => file.file.name),
          created_at: new Date().toISOString(),
        };

        setMockComments((prev) => [...prev, newComment]);

        setNewFilesUploaded([]);
        setInputValue('');

        return Promise.resolve({ success: true });
      }
      const formData = new FormData();
      formData.append('message', inputValue);

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};
        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      const res = await client.post(`tasks/${taskId}/submit`, formData);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      // Set lastCommentDirection to inbound to hide the closure button while waiting for
      // the task to be updated with the new comment.
      setLastCommentDirection('inbound');
      onTaskUpdate();
      setNewFilesUploaded([]);
      setInputValue('');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const closeTask = useMutation({
    mutationFn: async () => {
      const res = await client.post(`tasks/${taskId}/close`);
      return res.data;
    },
    onSuccess: () => {
      // Set lastCommentDirection to inbound to hide the closure button while waiting for
      // the task to be updated with the new comment.
      setLastCommentDirection('inbound');
      onTaskUpdate();
      toast.custom(() => <Toast variant="success">Request to close task sent.</Toast>);
      navigate('/tasks');
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    return () => {
      setMockComments([]); // Reset mock comments on unmount
    };
  }, []);

  let body;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const { drug, patient, prescriber } = taskQuery.data;
    const task = taskQuery.data.task as Task;

    if (!lastCommentDirection && task.last_comment_direction) {
      setLastCommentDirection(task.last_comment_direction);
    }

    const showSidebar = drug && patient && prescriber;

    const getGeneralInfoTableData = () => {
      let patientName = null;
      let prescriberName = null;
      let dob = null;
      let prescribedOn = null;

      if (drug) {
        patientName = drug.patient_name;
        prescriberName = drug.prescriber_name;
        dob = drug.dob;
        prescribedOn = drug.prescription_recieved_at;
      } else if (task.first_name && task.last_name && task.dob) {
        // Old requests don't have rx data, but they should have these other values
        patientName = `${task.first_name} ${task.last_name}`;
        dob = task.dob || null;
      }

      const extraData = task.related_task ? [formattedRelatedTask(task.related_task)] : [];

      return {
        patientName,
        prescriberName,
        dob,
        prescribedOn,
        drugName: drug?.name || null,
        prescriptionId: drug?.id || null,
        extraData: extraData as { label: string; value: ReactNode }[],
      };
    };

    const comments = (
      <TimelineItemList>
        <>
          {task.timeline.map((item, index) => {
            let author;
            let timestamp;
            let message;
            let attachedFilesIds;
            if (item.item_type === 'comment') {
              const comment = item as TaskComment;
              timestamp = comment.created_at;
              author = comment.direction === 'inbound' ? comment.user : 'Tandem';
              message = comment.message;
              attachedFilesIds = comment.attachment_document_ids;
            } else {
              const event = item as TaskEvent;
              timestamp = event.event_timestamp;
              author = event.user;
              message = event.label;
              attachedFilesIds = event.attachment_document_ids;
            }
            return (
              <TimelineItem
                key={`task-item-${item.id}`}
                isLastEventInList={index === task.timeline.length - 1}
                author={author}
                timestamp={timestamp}
                message={message}
                attachedFilesIds={attachedFilesIds}
              />
            );
          })}

          {mockComments.map((mockComment, index) => (
            <TimelineItem
              key={`mock-${mockComment.id}`}
              isLastEventInList={index === mockComments.length - 1}
              author={mockComment.user}
              timestamp={mockComment.created_at}
              message={mockComment.message}
              attachedFilesIds={mockComment.attachment_document_ids}
            />
          ))}
        </>
      </TimelineItemList>
    );

    let closeButton = null;
    if (task.status === 'completed') {
      closeButton = (
        <ReopenTaskNotice styledAs="bodySmallDMSans">Adding a response will reopen this task.</ReopenTaskNotice>
      );
    } else if (lastCommentDirection === 'inbound' || NO_CLOSE_BUTTON_TASK_TYPES.includes(task.type)) {
      closeButton = (
        <Tooltip
          content={
            NO_CLOSE_BUTTON_TASK_TYPES.includes(task.type)
              ? 'This type of task can only be closed by Tandem'
              : 'Task can only be closed when last response is from Tandem'
          }
          childNotInteractive
        >
          <Button variant="tertiary" size="sm" disabled>
            Close task
          </Button>
        </Tooltip>
      );
    } else {
      closeButton = (
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => setCloseTaskConfirmationModalOpen(true)}
          disabled={submit.isPending || inputValue.length !== 0 || newFilesUploaded.length !== 0}
        >
          Close task
        </Button>
      );
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            noSidebar={!showSidebar}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" marginBottom="1rem">
                {task.type}
              </Typography>

              <GeneralInfoTable {...getGeneralInfoTableData()} />

              {task.timeline && comments}

              <ActionContainer>
                <ButtonRow align={Alignment.Left}>
                  <Button
                    variant={showCommentInput ? 'highlighted' : 'tertiary'}
                    onClick={() => setShowCommentInput(!showCommentInput)}
                    size="sm"
                    disabled={submit.isPending}
                  >
                    Add response
                  </Button>

                  {closeButton}
                </ButtonRow>

                {showCommentInput && (
                  <CommentAndFileSection
                    onSubmit={() => submit.mutate()}
                    isSubmitting={updating}
                    inputRef={inputRef}
                    files={newFilesUploaded}
                    onFilesChange={setNewFilesUploaded}
                    inActionBar={true}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    autoFocus
                  />
                )}
              </ActionContainer>
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {showSidebar && !isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      {body}

      {closeTaskConfirmationModalOpen && (
        <CloseTaskConfirmationModal
          onCloseModal={() => setCloseTaskConfirmationModalOpen(false)}
          closeTaskMutation={closeTask}
        />
      )}
    </>
  );
};

export default UnstructuredTaskView;
